import {
  ChangesApiFactory,
  Configuration,
  ProjectsApiFactory,
  UsersApiFactory,
  ProcessesApiFactory,
  WorkPackagesApiFactory,
  PspPhasesApiFactory,
  ProcessTemplatesApiFactory,
} from '@codefluegel/zeta-change-typescript-client';

import { useState, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export function createAuthorizedApi<T>(
  baseApiFactory: (configuration?: Configuration, basePath?: string) => T,
  token?: string,
): T {
  const config = new Configuration();

  if (token) {
    config.accessToken = token;
  }

  return baseApiFactory(config, process.env.REACT_APP_BASE_URL);
}

function useClientApi<T>(
  apiFactory: (configuration?: Configuration, basePath?: string) => T,
): T {
  const auth = useAuth();

  // remember last token
  const [token, setToken] = useState(auth?.user?.access_token);

  const [api, setApi] = useState(() =>
    createAuthorizedApi(apiFactory, auth?.user?.access_token),
  );

  useEffect(() => {
    if (auth?.user?.access_token !== token) {
      // token has changed (this es relevant for initial rendering)
      setToken(auth?.user?.access_token);
      setApi(createAuthorizedApi(apiFactory, auth?.user?.access_token));
    }
  }, [apiFactory, token, auth?.user?.access_token]);
  return api;
}

export const useChangeApiFactory: typeof ChangesApiFactory = () =>
  useClientApi(ChangesApiFactory);

export const useWorkPackageApiFactory: typeof WorkPackagesApiFactory = () =>
  useClientApi(WorkPackagesApiFactory);

export const useProcessApiFactory: typeof ProcessesApiFactory = () =>
  useClientApi(ProcessesApiFactory);

export const useUserApiFactory: typeof UsersApiFactory = () =>
  useClientApi(UsersApiFactory);

export const useProjectApiFactory: typeof ProjectsApiFactory = () =>
  useClientApi(ProjectsApiFactory);

export const usePspPhaseApiFactory: typeof PspPhasesApiFactory = () =>
  useClientApi(PspPhasesApiFactory);

export const useprocessTemplatesFactory: typeof ProcessTemplatesApiFactory =
  () => useClientApi(ProcessTemplatesApiFactory);
