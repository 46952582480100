/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Avatar,
  Box,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  AddProjectViewerDto,
  GetProjectDto,
  ListUserDto,
  TemporaryUserAssignmentDto,
  UserBaseInfoDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useParams } from 'react-router';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useProjectApiFactory } from '../../ApiClient';

import AddUserListDialog from '../../components/Dialog/AddUserListDialog';
import { ProjectViewerList, UserListTypes } from '../../types/users';
import { useStorex } from '../../store/UIStore';
import { useChangeStore } from '../../store/changeStore';
import { useProjectStore } from '../../store/projectStore';

const ProjectViewerListPage = observer(() => {
  const store = useStorex();

  const { setNotification } = useStorex();
  const { setIsDataLoading } = useChangeStore();

  const projectsApi = useProjectApiFactory();
  const [openUserList, setOpenUserList] = useState(false);
  const [userType, setUserType] = useState(UserListTypes.ChangeManager);
  const [currentUser, setCurrentUser] = useState<ListUserDto>();
  const { project } = useProjectStore();

  const { projectId } = useParams();
  const { t } = useTranslation();

  const currentProject = useLocalObservable<ProjectViewerList>(() => ({
    project: project ?? ({} as GetProjectDto),
    setCurrentProject(p: GetProjectDto) {
      this.project = p;
    },
    viewerList: [] as ListUserDto[],
    addToViewerList: viewer => {
      if (viewer) currentProject.viewerList.push(viewer);
    },
    removeFromViewerList: viewer => {
      if (viewer) {
        currentProject.viewerList = currentProject.viewerList.filter(
          entry => entry.id !== viewer.id,
        );
      }
    },
  }));

  useEffect(() => {
    if (project) currentProject.setCurrentProject(project);
  }, [currentProject, project]);

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);

        const viewerList =
          await projectsApi.projectsControllerListProjectViewers(
            Number(projectId) || 0,
          );

        const { data: viewers } = viewerList;
        currentProject.viewerList = viewers;

        setIsDataLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [currentProject, projectId, projectsApi, setIsDataLoading, store]);

  const deleteUserFromViewerList = async (user: ListUserDto) => {
    try {
      await projectsApi.projectsControllerRemoveProjectViewer(
        Number(projectId),
        user.id,
      );
      currentProject.removeFromViewerList(user);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleUserfromModal = async (
    type: UserListTypes,
    user: ListUserDto | UserBaseInfoDto | undefined,
  ) => {
    if (user && projectId) {
      if (type === UserListTypes.ChangeManager) {
        if (user && projectId) {
          const temporaryChangeManagerAssignment: TemporaryUserAssignmentDto = {
            userId: user.id,
            expiresTimestamp: new Date('2025').toISOString(),
          };

          const temporaryChangeManager =
            await projectsApi.projectsControllerSetTemporaryChangeManager(
              Number(projectId),
              temporaryChangeManagerAssignment,
            );
          const { data: temporaryChangeManagerData } = temporaryChangeManager;
          currentProject.project.temporaryChangeManagerAssignment =
            temporaryChangeManagerData;
        }
      }
      if (type === UserListTypes.Viewer) {
        const addUser: AddProjectViewerDto = {
          userId: user?.id,
        };
        const newUser = await projectsApi.projectsControllerAddProjectViewer(
          Number(projectId),
          addUser,
        );
        const { data: newUserData } = newUser;
        currentProject.addToViewerList(newUserData);
      }
    }
    setOpenUserList(false);
  };

  const openUserModal = (
    type: UserListTypes,
    user: ListUserDto | undefined,
  ) => {
    setUserType(type);
    setCurrentUser(user);
    setOpenUserList(true);
  };

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <Typography variant="h4">{t('viewer_list')}</Typography>
      <Fab
        sx={{ m: 2 }}
        variant="extended"
        size="medium"
        color="primary"
        onClick={() => openUserModal(UserListTypes.Viewer, undefined)}
      >
        <AddIcon sx={{ mr: 1 }} />
        {t('addViewer')}
      </Fab>

      <List
        sx={{
          maxWidth: '50%',
          // hover states
          '& .MuiListItem-root:hover': {
            bgcolor: 'lightgrey',
            cursor: 'pointer',
          },
        }}
        dense
      >
        {currentProject &&
          currentProject.viewerList.map(user => (
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={() => deleteUserFromViewerList(user)}
                  edge="end"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              }
              key={user.id}
            >
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText primary={user.displayName} />
            </ListItem>
          ))}
      </List>

      {openUserList && (
        <AddUserListDialog
          isOpen={openUserList}
          userType={userType}
          currentUser={currentUser}
          currentProject={currentProject.project}
          handleClose={(
            type: UserListTypes,
            user: ListUserDto | UserBaseInfoDto | undefined,
          ) => handleUserfromModal(type, user)}
          setNotification={setNotification}
        />
      )}
    </Box>
  );
});

export default ProjectViewerListPage;
