export type NotificationType = {
  type: 'success' | 'error' | 'info';
  message: string;
};

export enum NotificationTypes {
  success = 'success',
  error = 'error',
  info = 'info',
}
