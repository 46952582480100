/* eslint-disable react/jsx-fragments */

/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  GetChangeDto,
  ProcessProjectForChangeDto,
  ProcessListForChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { ChangeEvent, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { isChangeManager } from '../../utils/permissionHelper';

export type ProjectEditResourcePageProps = {
  isTabDataVisible?: boolean;
  projectProcesses?: ProcessProjectForChangeDto[];
  changeProcesses?: ProcessListForChangeDto[];
  handleCheckbox: (value: string) => void;
  change: GetChangeDto | null;
};

const ProjectEditResourcePage = observer(
  ({
    isTabDataVisible = true,
    projectProcesses,
    changeProcesses,
    handleCheckbox,
    change,
  }: ProjectEditResourcePageProps) => {
    const { t } = useTranslation();

    const isManager = isChangeManager(change);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        handleCheckbox(e.target.value);
      },
      [handleCheckbox],
    );

    return (
      <Grid
        container
        direction="column"
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
          padding: 19px 0px 12px 0px;
        `}
      >
        <Typography
          variant="body1"
          color="primary"
          css={css`
            max-width: 62%;
            padding: 8px;
            margin-bottom: 20px;
            font-size: 12px;
            line-height: 1;
            color: #333333;
            background: #ececec;

            @media (max-width: 1200px) {
              max-width: 100%;
            }
          `}
        >
          {t('ChooseResource')}
        </Typography>

        {isManager ? (
          <Fragment>
            {projectProcesses &&
              projectProcesses
                .slice()
                .sort((a, b) => Number(a.groupNo) - Number(b.groupNo))
                .map(prProcess => {
                  const isChecked = changeProcesses?.find(
                    changeProcess => changeProcess.pId === prProcess.id,
                  );
                  return (
                    <FormGroup key={prProcess.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!isChecked}
                            onChange={onChange}
                            css={css`
                              svg {
                                color: #00223b;
                              }
                            `}
                          />
                        }
                        value={prProcess.groupNo}
                        label={`${prProcess.name} | ${prProcess.owner ?? ''}`}
                        css={css`
                          .MuiTypography-root {
                            font-size: 14px;
                            font-weight: 300;
                            color: #333333;
                          }
                        `}
                      />
                    </FormGroup>
                  );
                })}
          </Fragment>
        ) : (
          <Typography variant="h4">{t('changeManagerAccess')}</Typography>
        )}
      </Grid>
    );
  },
);

export default ProjectEditResourcePage;
