import { ChangeEvent } from 'react';
import {
  ListNotificationDto,
  ProcessProjectForChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';

const currencyFormatDE = (num: number): string =>
  `${num
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`; // use . as a separator

const getLatestNotifications = (
  notificationList: ListNotificationDto[],
): ListNotificationDto[] =>
  notificationList.reduce(
    (accumulator: ListNotificationDto[], notification: ListNotificationDto) => {
      const existingNotification = accumulator.find(
        item => item.changeRequest.id === notification.changeRequest.id,
      );

      if (
        !existingNotification ||
        new Date(notification.createTimestamp) >
          new Date(existingNotification.createTimestamp)
      ) {
        // eslint-disable-next-line no-param-reassign
        accumulator = accumulator.filter(
          item => item.changeRequest.id !== notification.changeRequest.id,
        );
        accumulator.push(notification);
      }

      return accumulator;
    },
    [],
  );

const getDepartmentById = (
  pId: number,
  departments: ProcessProjectForChangeDto[] | undefined,
) => departments?.find(department => department.id === pId);

const getChangeLinkByStatus = (status: string): string => {
  if (
    status === UpdateChangeDtoStatusEnum.InEvaluation ||
    status === UpdateChangeDtoStatusEnum.EvalRework ||
    status === UpdateChangeDtoStatusEnum.EvalComplete
  ) {
    return 'resources';
  }
  if (status === UpdateChangeDtoStatusEnum.EvalAccepted) {
    return 'calculation';
  }
  if (
    status === UpdateChangeDtoStatusEnum.CalcComplete ||
    status === UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved
  ) {
    return 'approval';
  }
  if (
    status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
    status === UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress
  ) {
    return 'implementation';
  }
  return '';
};

// get color from string
export function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name: string) {
  if (!name) return '';

  if (name.includes('@')) {
    return name.charAt(0).toUpperCase();
  }
  const splitName = name.split(' ');
  if (splitName.length > 1) {
    return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`.toUpperCase();
  }

  return name.charAt(0).toUpperCase();
}

export function hasOversizedFile(e: ChangeEvent<HTMLInputElement>): boolean {
  const selectableMaxFileSize = 1024 * 1024 * 15;

  if (e.target.files) {
    const fileToBig = Array.from(e.target.files).some(file =>
      file.size > selectableMaxFileSize
        ? (console.log(`File ${file.name} exceeds the maximum file size.`),
          true)
        : false,
    );
    return fileToBig;
  }
  return false;
}

export {
  currencyFormatDE,
  getLatestNotifications,
  getChangeLinkByStatus,
  getDepartmentById,
};
