/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

import {
  CreateProcessProjectDto,
  ListProcessTemplateDto,
  ListProcessProjectDto,
} from '@codefluegel/zeta-change-typescript-client';
import { LoadingButton } from '@mui/lab';

/**
 * The internal dependencies
 */

import { useTranslation } from 'react-i18next';
import useLoading from '../../utils/useLoading';
import LoadSpinnerSmall from '../LoadSpinner/LoadSpinnerSmall';
import { NotificationType, NotificationTypes } from '../../types/notification';
import NotificationMessages from '../../constants/notification';
import { useProjectApiFactory } from '../../ApiClient';
import { useUserStore } from '../../store/userStore';
import { useProjectStore } from '../../store/projectStore';

type AddProcessModalProps = {
  isOpen: boolean;
  currentProcess: ListProcessProjectDto | undefined;
  handleClose: () => void;
  globalProcesses: ListProcessTemplateDto[] | null;
  setNotification: (data: NotificationType) => void;
};

const AddProcessDialog = observer(
  ({
    isOpen,
    currentProcess,
    handleClose,
    setNotification,
    globalProcesses,
  }: AddProcessModalProps) => {
    const { projectId } = useParams();
    const { isLoading } = useLoading();
    const [isBtnLoading, toggleIsBtnloading] = useState(false);
    const NotificationMessage = NotificationMessages();

    const globalOProcessesGroupNos = globalProcesses?.map(
      process => process.groupNo,
    );

    const { setAssociatedProcesses, associatedProcesses } = useProjectStore();
    const projectsApi = useProjectApiFactory();

    const { users } = useUserStore();

    const { t } = useTranslation();

    const {
      control,
      formState: { isValid },
      handleSubmit,
      reset,
    } = useForm<CreateProcessProjectDto>({
      mode: 'onChange',
    });

    useEffect(() => {
      reset({
        owner: currentProcess?.ownerUserNavigation?.id || '',
        groupNo: currentProcess?.groupNo,
        fullAccess: currentProcess?.fullAccess,
        name: currentProcess?.name,
        hourlyRate: currentProcess?.hourlyRate,
      });
    }, [currentProcess, reset]);

    const deleteProcess = async () => {
      try {
        if (currentProcess) {
          await projectsApi.projectsControllerRemoveProcessProjectAssociationForProject(
            Number(projectId),
            currentProcess.id,
          );

          const updatedProcesses = associatedProcesses.filter(
            item => item.id !== currentProcess.id,
          );

          setAssociatedProcesses(updatedProcesses);
          handleClose();
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.processDelete,
          });
        }
      } catch (e: any) {
        setNotification({
          type: NotificationTypes.error,
          message: e.response.data.message ?? NotificationMessage.error,
        });
        console.log(e);
      }
    };

    const onSubmit = async (data: CreateProcessProjectDto) => {
      if (!currentProcess && globalOProcessesGroupNos?.includes(data.groupNo)) {
        setNotification({
          type: NotificationTypes.error,
          message: NotificationMessage.processUnique,
        });
        return;
      }
      try {
        const requestData = {
          owner: data.owner || '',
          name: data.name || '',
          fullAccess: data.fullAccess,
          groupNo: data.groupNo,
          hourlyRate: Number(data.hourlyRate),
        };

        if (currentProcess) {
          const { data: updatedProcess } =
            await projectsApi.projectsControllerUpdateProcessProjectAssociationForProject(
              Number(projectId),
              currentProcess.id,
              {
                ...requestData,
              },
            );

          const userIndex = associatedProcesses.findIndex(
            item => item.id === updatedProcess.id,
          );
          if (userIndex >= 0) {
            const oldData = associatedProcesses[userIndex];
            associatedProcesses[userIndex] = { ...oldData, ...updatedProcess };

            setAssociatedProcesses(associatedProcesses);
          }
          if (updatedProcess) {
            setNotification({
              type: NotificationTypes.success,
              message: NotificationMessage.processUpate,
            });
          }
        } else {
          const { data: newProcess } =
            await projectsApi.projectsControllerCreateProcessProjectAssociationForProject(
              Number(projectId),
              {
                ...requestData,
              },
            );

          if (newProcess) {
            associatedProcesses.push(newProcess);
            setAssociatedProcesses(associatedProcesses);

            setNotification({
              type: NotificationTypes.success,
              message: NotificationMessage.processCreate,
            });
          }
        }

        handleClose();
      } catch (e) {
        setNotification({
          type: NotificationTypes.error,
          message: NotificationMessage.error,
        });
        toggleIsBtnloading(false);
        console.log(e);
      }
    };

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 652px;
            padding: 20px 24px;
            border: 1px solid #707070;
            background: #ffffff;
          `}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            css={css`
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
            `}
          >
            {currentProcess ? t('editProcess') : t('createProcess')}
          </Typography>

          {isLoading && <LoadSpinnerSmall />}
          {users && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                css={css`
                  padding: 22px 0 0 0;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    gap: 18px;
                    margin-bottom: 3px;
                    justify-content: space-between;
                  `}
                >
                  <Controller
                    control={control}
                    name="groupNo"
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        error={!field.value}
                        css={css`
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInput-input {
                            padding: 4px 0 4px;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        required
                        label={t('groupNo')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        type="string"
                        variant="standard"
                        InputProps={{
                          style: {
                            paddingLeft: '16px',
                            paddingBottom: '6px',
                            fontSize: '14px',
                            fontWeight: '300',
                          },
                          inputProps: {
                            min: 1,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: '20px',
                            fontWeight: 600,
                            color: '#333333',
                          },
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        error={!field.value}
                        css={css`
                          width: 80%;
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInput-input {
                            padding: 4px 0 4px;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        required
                        label={t('proecssName')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        type="string"
                        variant="standard"
                        InputProps={{
                          style: {
                            paddingLeft: '16px',
                            paddingBottom: '6px',
                            fontSize: '14px',
                            fontWeight: '300',
                          },
                          inputProps: {
                            min: 1,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: '20px',
                            fontWeight: 600,
                            color: '#333333',
                          },
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="hourlyRate"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        error={!field.value}
                        required
                        label={t('hourlyRate')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        type="number"
                        variant="standard"
                        InputProps={{
                          style: {
                            paddingLeft: '16px',
                            paddingBottom: '6px',
                            fontSize: '14px',
                            fontWeight: '300',
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: '20px',
                            fontWeight: 600,
                            color: '#333333',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  css={css`
                    display: flex;
                    gap: 20px;

                    margin-bottom: 20px;
                  `}
                >
                  <Controller
                    control={control}
                    name="owner"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        popupIcon={
                          <ExpandMoreIcon
                            color="primary"
                            css={css`
                              color: #80acd4;
                            `}
                          />
                        }
                        css={css`
                          width: 100%;
                          max-width: 72%;

                          icon: {
                            fill: 'red';
                          }
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        getOptionLabel={option => {
                          if (typeof option === 'string') {
                            const curUser = users.find(
                              usr => usr.id === option,
                            );
                            return curUser ? curUser.displayName : '';
                          }
                          return option.displayName;
                        }}
                        // @ts-ignore
                        value={field.value ? field.value : ''}
                        options={users}
                        onChange={(e, value) =>
                          value && field.onChange(value.id)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option !== value
                        }
                        renderOption={({ ...props }, option) => (
                          <li {...props} key={uuidv4()}>
                            {option.isInternal
                              ? option.displayName
                              : `[${t('extern')}] ${option.displayName}`}
                          </li>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            error={!field.value}
                            label={t('owner')}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                paddingLeft: '16px',
                                paddingBottom: '6px',
                                fontSize: '14px',
                                fontWeight: '300',
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="fullAccess"
                    defaultValue={false}
                    render={({ field }) => (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value || false}
                              value={field.value || false}
                              onChange={field.onChange}
                              css={css`
                                svg {
                                  color: #00223b;
                                }
                              `}
                            />
                          }
                          label={t('fullAccess')}
                          css={css`
                            .MuiTypography-root {
                              font-size: 14px;
                              font-weight: 300;
                              color: #333333;
                            }
                          `}
                        />
                      </FormGroup>
                    )}
                  />
                </Box>

                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 20px;
                  `}
                >
                  {currentProcess && (
                    <Button
                      css={css`
                        background: #9b9b9b;
                        color: #00223b;
                        margin-right: 50%;
                        &:hover {
                          background: #9b9b9b;
                        }
                      `}
                      variant="contained"
                      component="span"
                      onClick={deleteProcess}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  <Button
                    css={css`
                      background: #9b9b9b;
                      color: #00223b;

                      &:hover {
                        background: #9b9b9b;
                      }
                    `}
                    variant="contained"
                    component="span"
                    onClick={handleClose}
                  >
                    {t('abort')}
                  </Button>
                  <LoadingButton
                    loading={isBtnLoading}
                    disabled={!isValid}
                    css={css`
                      min-width: 0;
                    `}
                    variant="contained"
                    type="submit"
                  >
                    {t('ok')}
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          )}
        </Box>
      </Modal>
    );
  },
);

export default AddProcessDialog;
