import { useTranslation } from 'react-i18next';
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';

export const DialogTitle = () => {
  const { t } = useTranslation();

  return {
    revision: t('SendChangeRework'),
    reject: t('reject'),
    accept: t('AcceptChange'),
    new: t('createChange'),
    evalComplete: t('finishEval'),
    evalAccepted: t('toSendCalc'),
    calcComplete: t('finish Calc'),
    crWorkpackageInProgress: t('sendImpl'),
    calcRejected: t('resendCalc'),
    evalRework: t('resendKTM'),
    internalApprovalRejected: t('archive'),
    check: t('finishImpl'),
    rejectAll: t('reject'),
    close: t('closed'),
    discard: t('discard'),
    ExternalChangeInternallyApproved: t('ExternalChangeInternallyApproved'),
    CrWorkpackagesInInternalProgress: t('CrWorkpackagesInInternalProgress'),
  };
};

export const DialogText = () => {
  const { t } = useTranslation();
  return {
    revision: t('SendBack'),
    reject: t('RejectChange'),
    accept: t('attentionEval'),
    new: t('ChangeToCreated'),
    evalComplete: t('finishEvalText'),
    evalAccepted: t('CalcSendText'),
    calcComplete: t('calcComplete'),
    crWorkpackageInProgress: t('finalDateSet'),
    CrWorkpackagesInInternalProgress: t('CrWorkpackagesInInternalProgressText'),
    calcRejected: t('RejectChange'),
    internalApprovalRejected: t('archived'),
    evalRework: t('sendBackKTMText'),
    check: t('finishImplText'),
    rejectAll: t('RejectChange'),
    close: t('closeStatusText'),
    discard: t('draftDiscardText'),
    ExternalChangeInternallyApproved: t('ExternalChangeInternallyApprovedText'),
  };
};

export const DialogDateLabel = () => {
  const { t } = useTranslation();
  return {
    accept: t('evalDate'),
    new: t('evalDate'),
    evalComplete: t('evalDate'),
    evalAccepted: t('evalDate'),
    crWorkpackageInProgress: t('implementationDate'),
    CrWorkpackagesInInternalProgress: t('implementationDate'),
  };
};

export const TaskCardLocalizationList = () => {
  const { t } = useTranslation();

  return {
    [UpdateChangeDtoStatusEnum.Draft]: t('draft'),
    [UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress]: t('toBeImplemented'),
    [UpdateChangeDtoStatusEnum.NewRework]: t('rework'),
    [UpdateChangeDtoStatusEnum.NewRejected]: t('rejected'),
    [UpdateChangeDtoStatusEnum.InEvaluation]: t('evaluationInProgress'),
    [UpdateChangeDtoStatusEnum.EvalAccepted]: t('EVAL_ACCEPTED'),
    [UpdateChangeDtoStatusEnum.New]: t('neu'),
    [UpdateChangeDtoStatusEnum.Rejected]: t('rejected'),
    DRAFT_DISCARDED: t('draftdiscarded'),
    EVAL_REWORK: t('EVAL_REWORK'),
    CALC_COMPLETE: t('calcFinished'),
    EVAL_COMPLETE: t('evaluated'),
    INTERNAL_APPROVAL_REJECTED: t('rejected'),
    CHECK: t('check'),
    CLOSED: t('closed'),
    INTERNAL_APPROVAL_ACCEPTED: t('INTERNAL_APPROVAL_ACCEPTED'),
    EVAL_CALC_REJECTED: t('calcReworkTo'),
    [UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved]: t(
      'ExternalChangeInternallyApproved',
    ),
    [UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress]: t(
      'CrWorkpackagesInInternalProgress',
    ),
  };
};
