/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  ListWorkPackageDto,
  ProcessListForChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useTranslation } from 'react-i18next';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ConstructionIcon from '@mui/icons-material/Construction';
import theme from '../../theme';
import Tag from '../Tag/Tag';
import { useChangeStore } from '../../store/changeStore';
/**
 * The internal dependencies
 */

export type ProcessItemInfoProps = {
  workPackages?: ListWorkPackageDto[];
  calculatedHours?: number | null;
  releasedHours?: number | null;
  timeImpact: boolean | null;
  process: ProcessListForChangeDto;
};

const ProcessItemInfo = observer(
  ({
    calculatedHours,
    releasedHours,
    workPackages,
    timeImpact,
    process,
  }: ProcessItemInfoProps) => {
    const isFlightIncluded =
      workPackages && workPackages.some(wP => wP.travelIncluded);

    const { change } = useChangeStore();

    const { t } = useTranslation();

    const sumHours =
      (workPackages &&
        workPackages.reduce((acc, curr) => {
          if (curr.hours) {
            return acc + curr.hours;
          }
          return acc + 0;
        }, 0)) ||
      0;

    const notAffected = process.affected;

    const hasFiles = workPackages && workPackages.some(wP => wP.hasAttachments);

    return (
      <Grid
        css={css`
          display: flex;
          align-items: center;
          gap: 12px;
        `}
      >
        {sumHours > 0 && <Tag label={`${sumHours}h ${t('required')}`} />}

        {timeImpact && (
          <AccessTimeIcon
            css={css`
              color: ${theme.palette.primary.light};
            `}
          />
        )}
        {hasFiles && (
          <AttachFileIcon
            css={css`
              color: ${theme.palette.primary.light};
            `}
          />
        )}
        {isFlightIncluded && (
          <FlightTakeoffIcon
            css={css`
              color: ${theme.palette.primary.light};
            `}
          />
        )}
        {process.plannedMaterial && (
          <ConstructionIcon sx={{ color: theme.palette.primary.light }} />
        )}
        {notAffected && (
          <DoNotDisturbIcon
            css={css`
              color: red;
            `}
          />
        )}
        {process.evaluationStatus === 'APPROVED' && (
          <CheckCircleIcon
            css={css`
              color: red;
            `}
          />
        )}
        <Typography
          css={css`
            font-size: 14px;
            font-weight: 300;
            color: #333333;
          `}
        >
          {(workPackages && workPackages.length) || 0} {t('workpackages')}
        </Typography>
        {calculatedHours &&
          change?.status === UpdateChangeDtoStatusEnum.CalcComplete && (
            <Tag label={`${calculatedHours}h ${t('calculated')}`} />
          )}
        {releasedHours &&
          (change?.status === UpdateChangeDtoStatusEnum.Check ||
            change?.status ===
              UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
            change?.status ===
              UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress ||
            change?.status ===
              UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved) && (
            <Tag label={`${releasedHours}h ${t('released')}`} />
          )}
      </Grid>
    );
  },
);

export default ProcessItemInfo;
