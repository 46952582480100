/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Grid, Paper, Typography, Box, Tooltip } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

/**
 * The internal dependencies
 */
import {
  ListWorkPackageDto,
  ProcessListForChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import theme from '../../theme';
import Tag from '../Tag/Tag';
import { usePspPhaseApiFactory } from '../../ApiClient';
import { useProjectStore } from '../../store/projectStore';

export type WorkPackageProps = {
  workPackage: ListWorkPackageDto;
  processNo?: string;
  handleWorkPackageModal: (workPackageId?: number) => void;
  process: ProcessListForChangeDto;
};

const WorkPackage = ({
  workPackage,
  handleWorkPackageModal,
}: WorkPackageProps): JSX.Element => {
  const { title, description, travelIncluded, id, hours } = workPackage;
  const { pspPhases, pspWorkPackages } = useProjectStore();

  const { hasAttachments } = workPackage;

  const [phase, setPhase] = useState('');
  const pspPhaseApi = usePspPhaseApiFactory();

  useEffect(() => {
    (async () => {
      try {
        const currentPhase = pspPhases.find(
          p => p.id === workPackage.navPhasePspId,
        );
        if (currentPhase) setPhase(currentPhase.name);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [phase, pspPhaseApi, pspPhases, workPackage]);

  const { t } = useTranslation();

  const onClick = () => {
    // if (process.evaluationStatus === 'APPROVED') return;
    handleWorkPackageModal(id);
  };

  const getWorkPackageName = (wp: ListWorkPackageDto): string => {
    const pspWP = pspWorkPackages.find(pspwp => pspwp[wp.navPhasePspId]);
    if (pspWP) {
      const pspPackage = pspWP[wp.navPhasePspId].find(
        elem => elem.id === wp.workPackagePspId,
      );
      return (pspPackage && pspPackage.name) || '';
    }
    return '';
  };

  return (
    <Paper
      onClick={onClick}
      elevation={0}
      variant="outlined"
      square
      css={css`
        padding: 16px;
        background-color: white;
        display: flex;
        width: 424px;
        max-width: 424px;
        min-width: 280px;

        &:hover {
          cursor: pointer;
        }

        @media (max-width: 375px) {
          padding: 12px 12px;
        }

        @media (max-width: 870px) {
          width: 100%;
          max-width: unset;
        }

        @media (max-width: 1120px) {
          max-width: 100%;
        }
      `}
    >
      <Grid
        container
        direction="column"
        css={css`
          gap: 4px;
        `}
      >
        <Grid item>
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="caption">{description}</Typography>
        </Grid>

        <Grid
          item
          css={css`
            display: flex;
            gap: 4px;
            flex-direction: column;
          `}
        >
          <Box
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <Typography
              variant="h6"
              css={css`
                font-weight: 700;
                width: 40%;
                padding-right: 16px;
                min-width: max-content;
              `}
            >
              {t('PSPNo')}
            </Typography>

            <Typography
              variant="h6"
              css={css`
                font-weight: 200;
                color: #333;
                min-width: max-content;
              `}
            >
              {workPackage.workPackageNo}
            </Typography>
          </Box>

          <Box
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <Typography
              variant="h6"
              css={css`
                font-weight: 700;
                width: 40%;
                padding-right: 16px;
                min-width: max-content;
              `}
            >
              {t('PhasePSP')}
            </Typography>

            <Typography
              variant="h6"
              css={css`
                font-weight: 200;
                color: #333;
                min-width: max-content;
              `}
            >
              {`${getWorkPackageName(workPackage)} - ${phase}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        gap={1}
        css={css`
          align-items: flex-end;
        `}
      >
        <Grid
          item
          css={css`
            display: flex;
            align-items: center;
            gap: 18px;
            justify-content: flex-end;
            max-width: fit-content;
          `}
        >
          <Tag label={`${hours}h`} />

          {travelIncluded && (
            <FlightTakeoffIcon
              css={css`
                color: ${theme.palette.primary.light};
              `}
            />
          )}

          {hasAttachments && (
            <AttachFileIcon
              css={css`
                color: ${theme.palette.primary.light};
              `}
            />
          )}
          {workPackage.status === 'IMPLEMENTED' && (
            <Tooltip title={t('implemented') ?? ''}>
              <CheckCircleIcon
                css={css`
                  color: red;
                `}
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WorkPackage;
