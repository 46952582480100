/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Icon, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import {
  GetUserDto,
  ListChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router';
import TaskCardIconMap from '../../utils/changes';

type DataGridProps = {
  changeRequests: GetUserDto;
};

const ChangeDataGrid = observer(({ changeRequests }: DataGridProps) => {
  const { t } = useTranslation();
  const rolePriority = {
    change_as_manager: 1,
    change_as_calc: 2,
    change_as_author: 4,
    change_as_ktm: 3,
  } as const;

  const addParentName = (
    items: ListChangeDto[],
    parentName: keyof typeof rolePriority,
  ) => items.map(item => ({ ...item, role: parentName }));

  const changes = [
    ...addParentName(changeRequests.authorChangeRequests, 'change_as_author'),
    ...addParentName(
      changeRequests.calcResponsibleChangeRequests,
      'change_as_calc',
    ),
    ...addParentName(
      changeRequests.changeManagerChangeRequests,
      'change_as_manager',
    ),
    ...addParentName(
      changeRequests.coreTeamMemberChangeRequests,
      'change_as_ktm',
    ),
  ];

  const uniqueObjects = Array.from(
    changes
      .reduce((acc, current) => {
        const existing = acc.get(current.id);
        if (
          !existing ||
          rolePriority[current.role] < rolePriority[existing.role]
        ) {
          acc.set(current.id, current);
        }
        return acc;
      }, new Map<number, typeof changes[0]>())
      .values(),
  );

  const uniqueObjectsTranslated = uniqueObjects.map(entry => ({
    ...entry,
    statusText: t(entry.status),
  }));

  const navigation = useNavigate();

  const columns: GridColDef[] = [
    { field: 'changeNo', headerName: t('ChangeNo'), width: 150 },
    { field: 'title', headerName: t('title'), width: 200 },
    { field: 'initiator', headerName: t('creator'), width: 150 },
    { field: 'changeNoClient', headerName: t('clientChangeNo'), width: 150 },
    { field: 'statusClient', headerName: t('clientStatus'), width: 100 },
    {
      field: 'createdAt',
      headerName: t('createdat'),
      width: 100,
      renderCell: cellValues => (
        <Typography>
          {new Date(cellValues.row.createdAt || '').toLocaleDateString()}
        </Typography>
      ),
    },
    {
      field: 'statusText',
      headerName: t('Status'),
      sortable: false,
      width: 250,
      renderCell: cellValues => (
        <Tooltip
          title={t(cellValues.row.statusText).toString()}
          placement="right"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon>
              {
                TaskCardIconMap[
                  cellValues.row.status as UpdateChangeDtoStatusEnum
                ]
              }
            </Icon>
            <Typography>{t(cellValues.row.status).toString()}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'role',
      headerName: t('role'),
      width: 200,
      renderCell: cellValues => (
        <Typography>{t(cellValues.row.role).toString()}</Typography>
      ),
    },
    {
      field: 'implementationTargetDate',
      headerName: t('implDate'),
      width: 100,
      renderCell: cellValues => (
        <Typography>
          {cellValues.row.implementationTargetDate
            ? new Date(
                cellValues.row.implementationTargetDate || '',
              ).toLocaleDateString()
            : ''}
        </Typography>
      ),
    },
  ];

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          rows={uniqueObjectsTranslated}
          columns={columns}
          pageSize={100}
          style={{ cursor: 'pointer' }}
          rowsPerPageOptions={[5]}
          onRowClick={params => {
            navigation(`change-request/${params.id}`);
          }}
          components={{
            Toolbar: () => (
              <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarQuickFilter sx={{ margin: 1 }} />
              </GridToolbarContainer>
            ),
          }}
          localeText={{
            toolbarQuickFilterLabel: t('search'),
            toolbarQuickFilterPlaceholder: t('search'),
          }}
        />
      </div>
    </Box>
  );
});

export default ChangeDataGrid;
