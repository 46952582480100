/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

import {
  GetProjectDto,
  ListUserDto,
  TemporaryUserAssignmentDto,
  TemporaryUserAssignmentWithUserInfoDto,
  UserBaseInfoDto,
} from '@codefluegel/zeta-change-typescript-client';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';

/**
 * The internal dependencies
 */

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from 'react-oidc-context';
import { computed } from 'mobx';
import { NotificationType } from '../../types/notification';
import { UserListTypes } from '../../types/users';
import { useUserStore } from '../../store/userStore';
import LocalizationWrapper from '../Date/LocalizationWrapper';

type AddUserListDialogProps = {
  isOpen: boolean;
  handleClose: (
    type: UserListTypes,
    user?: ListUserDto | UserBaseInfoDto | undefined,
    expiry?: string,
  ) => void;
  userType: UserListTypes;
  setNotification: (data: NotificationType) => void;
  currentUser?: ListUserDto | UserBaseInfoDto;
  currentProject?: GetProjectDto;
  temporaryUser?:
    | TemporaryUserAssignmentWithUserInfoDto
    | TemporaryUserAssignmentDto
    | null;
};

const AddUserListDialog = observer(
  ({
    isOpen,
    handleClose,
    currentUser,
    userType,
    currentProject,
    temporaryUser,
  }: AddUserListDialogProps) => {
    const [isDesktopDatePickerOpen, setIsDesktopDatePickerOpen] =
      useState(false);
    const [datePickerValue, setDatePickerValue] = useState<string | null>(
      temporaryUser?.expiresTimestamp || null,
    );
    const { users } = useUserStore();

    const auth = useAuth();

    const filteredUser = computed(() => {
      if (
        (userType === UserListTypes.TemporaryChangeManager ||
          userType === UserListTypes.Viewer) &&
        auth.user?.profile.email?.toLocaleLowerCase() ===
          currentProject?.changeManager.toLocaleLowerCase()
      ) {
        const f = users.filter(
          user =>
            user.id.toLocaleLowerCase() !==
            auth.user?.profile.email?.toLocaleLowerCase(),
        );
        return f;
      }
      return users;
    }).get();

    const { t } = useTranslation();

    const {
      control,
      formState: { isValid },
      handleSubmit,
    } = useForm<ListUserDto>({
      mode: 'onChange',
    });

    const onDesktopDatePickerFocus = (e: any) => {
      setIsDesktopDatePickerOpen(true);
      e.target.blur();
    };

    const onSubmit = (data: ListUserDto) => {
      handleClose(userType, data, datePickerValue ?? undefined);
    };

    return (
      <Modal
        open={isOpen}
        onClose={() => handleClose(userType)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 652px;
            padding: 20px 24px;
            border: 1px solid #707070;
            background: #ffffff;
          `}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            css={css`
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
            `}
          >
            {t('adduser')}
          </Typography>

          {filteredUser && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                css={css`
                  padding: 22px 0 0 0;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    gap: 20px;

                    margin-bottom: 20px;
                  `}
                >
                  <Controller
                    control={control}
                    name="id"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        popupIcon={
                          <ExpandMoreIcon
                            color="primary"
                            css={css`
                              color: #80acd4;
                            `}
                          />
                        }
                        css={css`
                          width: 100%;
                          max-width: 72%;

                          icon: {
                            fill: 'red';
                          }
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        getOptionLabel={option => option.displayName ?? ''}
                        renderOption={({ ...props }, option) => (
                          <li {...props} key={uuidv4()}>
                            {option.isInternal
                              ? option.displayName
                              : `[${t('extern')}] ${option.displayName}`}
                          </li>
                        )}
                        options={filteredUser}
                        onChange={(e, value) =>
                          value && field.onChange(value.id)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option !== value
                        }
                        defaultValue={currentUser ?? undefined}
                        renderInput={params => (
                          <TextField
                            {...params}
                            required
                            error={!field.value}
                            label={t('user')}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                paddingLeft: '16px',
                                paddingBottom: '6px',
                                fontSize: '14px',
                                fontWeight: '300',
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Box>
                {(userType === UserListTypes.TemporaryChangeManager ||
                  userType === UserListTypes.KTM) && (
                  <LocalizationWrapper>
                    <DesktopDatePicker
                      open={isDesktopDatePickerOpen}
                      onOpen={() => setIsDesktopDatePickerOpen(true)}
                      onClose={() => setIsDesktopDatePickerOpen(false)}
                      inputFormat="dd.MM.yyyy"
                      disablePast
                      mask="__.__.____"
                      label={t('until')}
                      value={datePickerValue}
                      onChange={newDate => {
                        setDatePickerValue(newDate);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          css={css`
                            .MuiInput-underline:before {
                              border-bottom: 1px solid #ececec;
                            }

                            .MuiInput-input {
                              padding-left: 16px;
                              padding-bottom: 11px;
                              font-size: 14px;
                            }

                            .MuiButtonBase-root {
                              padding: 0 12px 2px 0;
                              background-color: #ffffff !important;

                              svg {
                                width: 33px;
                                height: 25px;

                                path {
                                  fill: #80acd4;
                                }
                              }
                            }
                          `}
                          InputLabelProps={{
                            style: {
                              paddingLeft: '20px',
                              fontWeight: 600,
                              color: '#333333',
                            },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            onFocus: е => onDesktopDatePickerFocus(е),
                          }}
                        />
                      )}
                    />
                  </LocalizationWrapper>
                )}
                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 20px;
                  `}
                >
                  <Button
                    css={css`
                      background: #9b9b9b;
                      color: #00223b;

                      &:hover {
                        background: #9b9b9b;
                      }
                    `}
                    variant="contained"
                    component="span"
                    onClick={() => handleClose(userType)}
                  >
                    {t('abort')}
                  </Button>
                  <LoadingButton
                    disabled={!isValid}
                    css={css`
                      min-width: 0;
                    `}
                    variant="contained"
                    type="submit"
                  >
                    {t('ok')}
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          )}
        </Box>
      </Modal>
    );
  },
);

export default AddUserListDialog;
