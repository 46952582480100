/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
/**
 * The internal dependencies
 */
import { Outlet } from 'react-router';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { useStorex } from '../store/UIStore';

const AdminLayoutPage = observer(() => {
  const store = useStorex();
  return (
    <Box
      css={css`
        padding: 48px 15px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        width: calc(100% - ${store.adminSidebar ? 236 : 0}px);

        @media (max-width: 900px) {
          width: 100%;
        }

        @media (max-width: 375px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar IsAdminSidebar />
      <Breadcrumbs
        css={css`
          margin-top: -48px;
          font-size: 12px;
          font-weight: 600;
        `}
      >
        <Link underline="hover">User-Management</Link>
      </Breadcrumbs>
      <Outlet />
    </Box>
  );
});

export default AdminLayoutPage;
