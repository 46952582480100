/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { RemoveCircle } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  ListUserDto,
  TemporaryUserAssignmentDto,
  UserBaseInfoDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useParams } from 'react-router';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { endOfDay } from 'date-fns';
import { useStorex } from '../../store/UIStore';
import { useProjectApiFactory } from '../../ApiClient';
import AddProcessDialog from '../../components/Dialog/AddProcessDialog';
import { ActiveProjectProcessType } from '../../types/processes';
import ProcessTransferListDialog from '../../components/Dialog/ProcessTransferListDialog';
import AddUserListDialog from '../../components/Dialog/AddUserListDialog';
import { UserListTypes } from '../../types/users';
import { getRole } from '../../utils/permissionHelper';
import { useProjectStore } from '../../store/projectStore';

const ProjectsProcessesPage = observer(() => {
  const projectsApi = useProjectApiFactory();
  const { processTemplates, associatedProcesses, setAssociatedProcesses } =
    useProjectStore();
  const [open, setOpen] = useState(false);
  const [openUserList, setOpenUserList] = useState(false);
  const { project } = useProjectStore();

  const [openTranferList, setOpenTransferList] = useState(false);
  const { setNotification } = useStorex();
  const { t } = useTranslation();

  const role = getRole(project);

  const { projectId } = useParams();

  const activeProcess = useLocalObservable<ActiveProjectProcessType>(() => ({
    currentProcess: null,
    setCurrentProcess: process => {
      if (process) {
        activeProcess.currentProcess = process;
      } else {
        activeProcess.currentProcess = null;
      }
    },
  }));

  const onClickCell = (data: GridRowParams) => {
    if (role !== 'KTM') {
      activeProcess.setCurrentProcess(data.row);
      setOpen(true);
    }
  };

  const newProcessClicked = () => {
    activeProcess.setCurrentProcess(undefined);
    setOpen(true);
  };

  const handleUserfromModal = async (
    type: UserListTypes,
    user: ListUserDto | UserBaseInfoDto | undefined,
    expiry?: string,
  ) => {
    if (user && activeProcess.currentProcess) {
      let newExpiry: string | undefined = expiry;
      if (expiry) {
        newExpiry = endOfDay(new Date(expiry)).toString();
      }

      const temporaryCTMAssignment: TemporaryUserAssignmentDto = {
        userId: user.id,
        expiresTimestamp: newExpiry,
      };

      const { data: newCTM } =
        await projectsApi.projectsControllerSetTemporaryCoreTeamMember(
          Number(projectId),
          activeProcess.currentProcess?.id,
          temporaryCTMAssignment,
        );

      const processIndex = associatedProcesses.findIndex(
        item => item.id === activeProcess.currentProcess?.id,
      );
      if (processIndex >= 0) {
        associatedProcesses[processIndex].temporaryCoreTeamMember = {
          user: {
            displayName: newCTM.userId,
            id: newCTM.userId,
            isInternal: false,
          },
          expiresTimestamp: newCTM.expiresTimestamp,
        };
      }
      setAssociatedProcesses(associatedProcesses);
    }
    setOpenUserList(false);
  };

  const removeTemporaryKTM = async (processId: number) => {
    try {
      await projectsApi.projectsControllerRemoveTemporaryCoreTeamMember(
        Number(projectId),
        processId,
      );
      const processIndex = associatedProcesses.findIndex(
        item => item.id === activeProcess.currentProcess?.id,
      );
      if (processIndex >= 0) {
        associatedProcesses[processIndex].temporaryCoreTeamMember = null;
      }
      setAssociatedProcesses(associatedProcesses);
    } catch (e) {
      console.log(e);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Prozess ID', width: 100, hide: true },
    {
      field: 'groupNo',
      headerName: t('processGroupNo'),
      width: 120,
      valueGetter: params => params.row.groupNo,
    },
    {
      field: 'name',
      headerName: t('proecssName'),
      width: 220,
      valueGetter: params => params.row.name,
    },
    {
      field: 'ownerUserNavigation',
      headerName: t('owner'),
      width: 220,
      valueGetter: params =>
        params.row.ownerUserNavigation
          ? params.row.ownerUserNavigation.displayName
          : '',
    },
    {
      field: 'fullAccess',
      headerName: t('fullAccess'),
      sortable: false,
      width: 70,
      renderCell: (params: GridRenderCellParams) => (
        <strong style={{ textAlign: 'center' }}>
          {params.row.fullAccess ? (
            <CheckCircleIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </strong>
      ),
    },
    {
      field: 'hourlyRate',
      headerName: t('hourlyRate'),
      sortable: false,
      width: 90,
      valueGetter: params => params.row.hourlyRate,
    },
    {
      field: 'temporaryCoreTeamMember',
      headerName: t('temporaryKTM'),
      sortable: false,
      width: 220,
      valueGetter: params =>
        params.row.temporaryCoreTeamMember &&
        params.row.temporaryCoreTeamMember.user
          ? params.row.temporaryCoreTeamMember.user.displayName
          : '',
    },
    {
      field: 'action',
      headerName: t('Vertreter'),
      sortable: false,
      type: 'actions',
      width: 140,

      renderCell: params => (
        <Box>
          <Tooltip title={t('activate').toString()}>
            <IconButton
              onClick={() => {
                activeProcess.setCurrentProcess(params.row);
                setOpenUserList(true);
              }}
              aria-label="activate"
              color="primary"
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('delete').toString()}>
            <IconButton
              onClick={() => removeTemporaryKTM(params.row.id)}
              aria-label="delete"
              color="primary"
            >
              <RemoveCircle />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <Typography variant="h4">{t('processProj')}</Typography>
      {role !== 'KTM' && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button sx={{ margin: 1 }} onClick={newProcessClicked}>
            {t('newProcess')} +
          </Button>
          <Button sx={{ margin: 1 }} onClick={() => setOpenTransferList(true)}>
            {t('editGlobalProc')}
          </Button>
        </Box>
      )}
      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          rows={toJS(associatedProcesses)}
          columns={columns}
          pageSize={100}
          style={{ cursor: 'pointer' }}
          rowsPerPageOptions={[5]}
          onRowClick={onClickCell}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t('noProcess')}
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {t('noProcess')}
              </Stack>
            ),
          }}
        />
      </div>
      {open && (
        <AddProcessDialog
          isOpen={open}
          handleClose={() => setOpen(false)}
          currentProcess={activeProcess.currentProcess || undefined}
          setNotification={setNotification}
          globalProcesses={processTemplates}
        />
      )}

      {openUserList && (
        <AddUserListDialog
          isOpen={openUserList}
          userType={UserListTypes.KTM}
          temporaryUser={activeProcess.currentProcess?.temporaryCoreTeamMember}
          currentUser={
            activeProcess.currentProcess?.temporaryCoreTeamMember?.user ??
            undefined
          }
          handleClose={(
            type: UserListTypes,
            user: UserBaseInfoDto | undefined,
            expiry?: string,
          ) => handleUserfromModal(type, user, expiry)}
          setNotification={setNotification}
        />
      )}

      {openTranferList && (
        <ProcessTransferListDialog
          isOpen={openTranferList}
          handleClose={() => setOpenTransferList(false)}
          globalProcesses={processTemplates}
          projectProcesses={associatedProcesses}
          setNotification={setNotification}
        />
      )}
    </Box>
  );
});

export default ProjectsProcessesPage;
