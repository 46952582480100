export const STATUSES_FOR_CALC = [
  'EVAL_ACCEPTED',
  'CALC_COMPLETE',
  'CHECK',
  'INTERNAL_APPROVAL_ACCEPTED',
  'CR_WORKPACKAGES_IN_PROGRESS',
  'CLOSED',
  'EVAL_CALC_REJECTED',
  'CR_WORKPACKAGES_IN_PROGRESS',
  'EXTERNAL_CHANGE_INTERNALLY_APPROVED',
  'CR_WORKPACKAGES_IN_INTERNAL_PROGRESS',
  'REJECTED',
];
export const EXCLUDED_STATUSES_FOR_RESOURCES = [
  'DRAFT',
  'NEW',
  'NEW_REWORK',
  'NEW_REJECTED',
];
export const STATUSES_FOR_EXTERN = [
  'CHECK',
  'INTERNAL_APPROVAL_ACCEPTED',
  'CR_WORKPACKAGES_IN_PROGRESS',
  'CLOSED',
  'CALC_COMPLETE',
  'EXTERNAL_CHANGE_INTERNALLY_APPROVED',
  'CR_WORKPACKAGES_IN_INTERNAL_PROGRESS',
];

export const STATUSES_FOR_IMPLEMENTATION = [
  'CHECK',
  'INTERNAL_APPROVAL_ACCEPTED',
  'INTERNAL_APPROVAL_REJECTED',
  'CR_WORKPACKAGES_IN_PROGRESS',
  'CR_WORKPACKAGES_IN_INTERNAL_PROGRESS',
  'CLOSED',
];
