import { useEffect } from 'react';

import { useAuth } from 'react-oidc-context';
import { Box, CircularProgress, Container } from '@mui/material';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const LoginRedirectPage = observer((): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const auth = useAuth();

  useEffect(() => {
    if (!(auth.isLoading || auth.isAuthenticated || auth.error))
      auth.signinRedirect();
  }, [auth, navigate, searchParams]);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ textAlign: 'center', padding: 8 }}>
        <CircularProgress size={50} color="inherit" />
      </Box>
    </Container>
  );
});

export default LoginRedirectPage;
