/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import {
  Button,
  Modal,
  Box,
  Typography,
  Checkbox,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';

import {
  ListProcessTemplateDto,
  ListProcessProjectDto,
} from '@codefluegel/zeta-change-typescript-client';
import { LoadingButton } from '@mui/lab';

/**
 * The internal dependencies
 */

import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { NotificationType, NotificationTypes } from '../../types/notification';
import { useProjectApiFactory } from '../../ApiClient';
import NotificationMessages from '../../constants/notification';
import { useProjectStore } from '../../store/projectStore';

type ProcessModalProps = {
  isOpen: boolean;
  globalProcesses: ListProcessTemplateDto[] | null;
  projectProcesses: ListProcessProjectDto[] | null;
  handleClose: () => void;
  setNotification: (data: NotificationType) => void;
};

function not(
  a: readonly ListProcessTemplateDto[],
  b: readonly ListProcessTemplateDto[],
) {
  return a.filter(value => b.map(v => v.id).indexOf(value.id) === -1);
}

function intersection(
  a: readonly ListProcessTemplateDto[],
  b: readonly ListProcessTemplateDto[],
) {
  return a.filter(value => b.map(v => v.id).indexOf(value.id) !== -1);
}

const ProcessTransferListDialog = observer(
  ({
    isOpen,
    globalProcesses,
    projectProcesses,
    handleClose,
    setNotification,
  }: ProcessModalProps) => {
    const { projectId } = useParams();
    const [isBtnLoading, toggleIsBtnloading] = useState(false);
    const projectsApi = useProjectApiFactory();
    const auth = useAuth();
    const { setAssociatedProcesses, associatedProcesses } = useProjectStore();

    const { t } = useTranslation();
    const NotificationMessage = NotificationMessages();

    const [checked, setChecked] = useState<readonly ListProcessTemplateDto[]>(
      [],
    );
    const groupNos = projectProcesses?.map(
      process => process.navProcess?.groupNo,
    );
    const [left, setLeft] = useState<readonly ListProcessTemplateDto[]>(
      globalProcesses?.filter(
        process => !groupNos?.includes(process.groupNo),
      ) || [],
    );
    const [right, setRight] = useState<readonly ListProcessTemplateDto[]>(
      projectProcesses?.filter(process => process.navProcess) || [],
    );

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const onSubmit = async () => {
      try {
        toggleIsBtnloading(true);

        await Promise.all(
          right.map(async newProcess => {
            if (!groupNos?.includes(newProcess.groupNo)) {
              const requestData = {
                groupNo: newProcess.groupNo,
                name: newProcess.name,
                id: newProcess.id,
                owner: auth.user?.profile.email?.toLocaleLowerCase(),
              };
              const { data: addedProcess } =
                await projectsApi.projectsControllerCreateProcessProjectAssociationForProject(
                  Number(projectId),
                  {
                    ...requestData,
                  },
                );
              associatedProcesses.push(addedProcess);
            }
          }),
        );

        setAssociatedProcesses(associatedProcesses);

        setNotification({
          type: NotificationTypes.success,
          message: NotificationMessage.processAdd,
        });
        toggleIsBtnloading(false);
        handleClose();
      } catch (e) {
        setNotification({
          type: NotificationTypes.error,
          message: NotificationMessage.error,
        });
        toggleIsBtnloading(false);
        console.log(e);
      }
    };

    const handleToggle = (process: ListProcessTemplateDto) => () => {
      const currentIndex = checked.map(p => p.id).indexOf(process.id);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(process);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };

    const handleAllRight = () => {
      setRight(right.concat(left));
      setLeft([]);
    };

    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
      setLeft(left.concat(right));
      setRight([]);
    };

    const customList = (items: readonly ListProcessTemplateDto[]) => (
      <Paper sx={{ width: 300, height: 430, overflow: 'auto' }}>
        <List dense component="div" role="list">
          {items.map((process: ListProcessTemplateDto) => {
            const labelId = `transfer-list-item-${process.id}-label`;

            return (
              <ListItem
                key={process.id}
                role="listitem"
                button
                onClick={handleToggle(process)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(process) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={process.name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 852px;
            padding: 20px 24px;
            border: 1px solid #707070;
            background: #ffffff;
          `}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            css={css`
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
            `}
          >
            {t('editGlobalProc')}
          </Typography>

          <Box sx={{ margin: 2 }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{customList(left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList(right)}</Grid>
            </Grid>
          </Box>
          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: end;
              gap: 20px;
            `}
          >
            <Button
              css={css`
                background: #9b9b9b;
                color: #00223b;

                &:hover {
                  background: #9b9b9b;
                }
              `}
              variant="contained"
              component="span"
              onClick={handleClose}
            >
              {t('abort')}
            </Button>
            <LoadingButton
              loading={isBtnLoading}
              disabled={false}
              css={css`
                min-width: 0;
              `}
              variant="contained"
              type="submit"
              onClick={onSubmit}
            >
              {t('ok')}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    );
  },
);

export default ProcessTransferListDialog;
