/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';

/**
 * The internal dependencies
 */
import { Outlet, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { useStorex } from '../store/UIStore';
import { useProjectStore } from '../store/projectStore';
import {
  useprocessTemplatesFactory,
  useProjectApiFactory,
  useUserApiFactory,
} from '../ApiClient';
import { useUserStore } from '../store/userStore';
import PageNotFoundText from '../components/Error/PageNotFoundText';

const ProjectLayoutPage = observer(() => {
  const { t } = useTranslation();
  const { project, setProject, setAssociatedProcesses } = useProjectStore();
  const projectsApi = useProjectApiFactory();
  const processTemplatesApi = useprocessTemplatesFactory();
  const { setProcessTemplates } = useProjectStore();
  const { setUsers } = useUserStore();
  const userApi = useUserApiFactory();
  const store = useStorex();
  const location = useLocation();
  const [permissionDenied, setPermissionDenied] = useState(false);

  const pageName: string = location.pathname.split('/').pop() ?? '';

  const pageTitles: { [key: string]: string } = {
    processes: t('process_overview'),
    'edit-role': t('deputyCM'),
    'project-viewer': t('viewer_list'),
    dashboard: t('dashboard'),
  };

  const { projectId } = useParams();

  useEffect(
    () =>
      autorun(() => {
        async function getProject() {
          try {
            const { data } = await projectsApi.projectsControllerFindOne(
              Number(projectId),
            );
            setProject(data);
          } catch (e: any) {
            if (e?.response?.data?.statusCode === 404) {
              setPermissionDenied(true);
              console.log('permission denied');
            }
          }
        }
        getProject();
      }),
    [projectId, projectsApi, setProject],
  );

  useEffect(
    () =>
      autorun(() => {
        async function getProcessTemplates() {
          try {
            const { data } =
              await processTemplatesApi.processTemplatesControllerFindAll();
            setProcessTemplates(data);
          } catch (error) {
            console.log({ error });
          }
        }
        getProcessTemplates();
      }),
    [processTemplatesApi, setProcessTemplates],
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: allUsers } = await userApi.usersControllerFindAll();
        if (allUsers) {
          setUsers(allUsers);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [setUsers, userApi]);

  useEffect(() => {
    (async () => {
      try {
        const { data } =
          await projectsApi.projectsControllerFindAllProcessProjectAssociationsForProject(
            Number(projectId) || 0,
          );
        if (data) {
          setAssociatedProcesses(data);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [setAssociatedProcesses, projectsApi, projectId]);

  return (
    <Box
      css={css`
        padding: 48px 15px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        width: calc(100% - ${store.adminSidebar ? 236 : 0}px);

        @media (max-width: 900px) {
          width: 100%;
        }

        @media (max-width: 375px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar isProjectSidebar />
      <Breadcrumbs
        css={css`
          margin-top: -48px;
          font-size: 12px;
          font-weight: 600;
        `}
      >
        <Link href="/projects" underline="hover">
          {t('projects')}
        </Link>
        <Link href={`/projects/${project?.id}`} underline="hover">
          {project?.number}
        </Link>
        <Link
          href={`/projects/${project?.id}/${pageTitles[pageName]}`}
          underline="hover"
        >
          {pageTitles[pageName]}
        </Link>
      </Breadcrumbs>

      {!permissionDenied ? <Outlet /> : <PageNotFoundText />}
    </Box>
  );
});

export default ProjectLayoutPage;
