/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import {
  GetChangeDto,
  UpdateChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import CalculationResultPage from './CalculationResultPage';
import CalculationHoursPage from './CalculationHoursPage';
import { useChangeStore } from '../../store/changeStore';
import { useChangeApiFactory, useProcessApiFactory } from '../../ApiClient';
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import useLoading from '../../utils/useLoading';
import { NotificationTypes } from '../../types/notification';
import NotificationMessages from '../../constants/notification';
import { useStorex } from '../../store/UIStore';
import CalculationAttachmentsTab from './CalculationAttachmentsTab';
import {
  isCalculationPerson,
  isChangeManager,
} from '../../utils/permissionHelper';
import { getSumOfWP } from '../../utils/getTotalTime';
import ChangeStatusDialogWrapper from '../../components/Dialog/ChangeStatusDialogWrapper';
import { DialogType } from '../../types/changes';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import { getDepartmentById } from '../../utils/utils';

const CalculationPage = observer(() => {
  const [tabIndex, setTabIndex] = useState(0);
  const {
    change,
    reducedWorkPackages,
    isDataLoading,
    setChange,
    setUpdatedChange,
    workPackages,
  } = useChangeStore();
  const { setNotification } = useStorex();
  const { changeId } = useParams();
  const { isLoading } = useLoading();
  const NotificationMessage = NotificationMessages();

  const [finalQuotationValue, setFinalQuotationValue] = useState(0);
  const [calculated, setCalculated] = useState(false);
  const [modalType, setModalType] = useState<DialogType | null>(null);

  const { t } = useTranslation();

  const showButtonGroup =
    change?.status === UpdateChangeDtoStatusEnum.EvalAccepted ||
    change?.status === UpdateChangeDtoStatusEnum.EvalCalcRejected;

  const isManager = isChangeManager(change);
  const isCalcResponsible = isCalculationPerson(change);

  const changeApi = useChangeApiFactory();
  const processApi = useProcessApiFactory();

  const showSuccessNotification = useCallback(
    () =>
      setNotification({
        type: NotificationTypes.success,
        message: NotificationMessage.changeStatusUpdate,
      }),
    [NotificationMessage.changeStatusUpdate, setNotification],
  );

  const showErrorNotification = useCallback(
    () =>
      setNotification({
        type: NotificationTypes.error,
        message: NotificationMessage.error,
      }),
    [NotificationMessage.error, setNotification],
  );

  const debounceChangeRequest = useDebouncedCallback(
    async (data: { [key: string]: number }) => {
      try {
        if (changeId && change) {
          const { data: response } = await changeApi.changesControllerUpdate(
            Number(changeId),
            {
              ...data,
              status: change.status as UpdateChangeDtoStatusEnum,
              finalQuotationValue: 1,
            },
          );
          if (response) {
            setChange({
              ...change,
              ...response,
              commonProject: change.commonProject,
            });

            const { data: secondReponse } =
              await changeApi.changesControllerUpdate(Number(changeId), {
                finalQuotationValue:
                  Number(response?.finalQuotationValue) -
                  (response?.projectDiscount ?? 0) +
                  (response?.materialValue ?? 0),
                status: change.status as UpdateChangeDtoStatusEnum,
              });

            setChange({
              ...change,
              ...secondReponse,
              commonProject: change.commonProject,
            });

            showSuccessNotification();
          }
        }
      } catch (e) {
        showErrorNotification();
        console.error(e);
      }
    },
    300,
  );

  const debouncedRequest = useDebouncedCallback(
    async (processId, data: { [key: string]: number }) => {
      try {
        const { data: updatedProcess } =
          await processApi.processesControllerUpdate(processId, {
            ...data,
          });

        if (updatedProcess && change) {
          const updatedProcesses = change?.processes.map(process => {
            if (process.id === updatedProcess.id) {
              const updatedCurrnetProcess = {
                ...process,
                ...updatedProcess,
              };
              return updatedCurrnetProcess;
            }
            return process;
          });

          const newChangeRequest: GetChangeDto | UpdateChangeDto = {
            ...change,
            processes: updatedProcesses,
          };

          setUpdatedChange(newChangeRequest);
        }

        if (updatedProcess) {
          showSuccessNotification();
        }
      } catch (e) {
        showErrorNotification();
        console.error(e);
      }
    },
    1000,
  );

  const affectedProcesses = useMemo(
    () => change?.processes.filter(process => !process.affected) || [],
    [change?.processes],
  );

  useEffect(() => {
    const finalQuotationValueFromProcesses =
      affectedProcesses &&
      affectedProcesses.reduce((acc, process) => {
        const department = getDepartmentById(
          process.pId,
          change?.commonProject?.navProcessToCommonProjectRelations,
        );
        const hourlyRate = process.finalHourlyRate
          ? process.finalHourlyRate
          : department?.hourlyRate;

        if (hourlyRate && process.calculatedHours) {
          return acc + hourlyRate * process?.calculatedHours;
        }
        if (reducedWorkPackages[process.id]) {
          const sum = getSumOfWP(
            reducedWorkPackages[process.id],
            hourlyRate || 0,
          );
          return sum;
        }
        return acc + 0;
      }, 0);

    setCalculated(true);
    setFinalQuotationValue(finalQuotationValueFromProcesses);
  }, [
    affectedProcesses,
    change,
    debounceChangeRequest,
    finalQuotationValue,
    reducedWorkPackages,
  ]);

  const handleSwitchTab = useCallback(
    (event: any, newValue: SetStateAction<number>) => {
      setTabIndex(newValue);
    },
    [],
  );

  const handleChangeOrProcess = useCallback(
    (key: string, value: number, processId?: number) => {
      if (processId) debouncedRequest(processId, { [key]: value });
      else debounceChangeRequest({ [key]: value });
    },
    [debounceChangeRequest, debouncedRequest],
  );

  return (
    <Grid
      container
      direction="row"
      css={css`
        justify-content: space-between;
        margin-top: 13px;
      `}
    >
      {isDataLoading && <LoadSpinner />}

      {!isDataLoading && (
        <Grid item xs={12} sm={11} md={11} lg={7}>
          <Tabs
            css={css`
              .MuiTabs-flexContainer {
                gap: 17px;
              }
            `}
            variant="scrollable"
            value={tabIndex}
            aria-label="Calculation Tabs"
            onChange={handleSwitchTab}
          >
            <Tab
              label={t('result')}
              css={css`
                font-size: 18px;
                font-weight: 500;
                padding: 14px 25px;
                color: #00223b;
              `}
            />
            <Tab
              label={t('hours')}
              css={css`
                font-size: 18px;
                font-weight: 500;
                padding: 14px 25px;
                color: #00223b;
              `}
            />
            <Tab
              label={t('docs')}
              css={css`
                font-size: 18px;
                font-weight: 500;
                padding: 14px 25px;
                color: #00223b;
              `}
            />
          </Tabs>

          {calculated && (
            <CalculationResultPage
              finalQuotationValue={finalQuotationValue}
              handleChangeProcess={handleChangeOrProcess}
              isTabDataVisible={tabIndex === 0}
              editable={isManager || isCalcResponsible}
              change={change || null}
            />
          )}
          <CalculationHoursPage
            isTabDataVisible={tabIndex === 1}
            processes={affectedProcesses}
            workPackages={reducedWorkPackages}
            isLoading={isLoading}
            handleChangeProcess={handleChangeOrProcess}
            change={change || null}
            editable={isManager || isCalcResponsible}
          />

          <CalculationAttachmentsTab
            change={change || null}
            isTabDataVisible={tabIndex === 2}
            editable={isManager || isCalcResponsible}
            showError={() => showErrorNotification}
          />
        </Grid>
      )}
      {!isDataLoading && (
        <Grid>
          {change && showButtonGroup && (
            <ButtonsGroup
              isChangeExist={!!change}
              changeStatus={change?.status || ''}
              workPackages={workPackages}
              change={change}
              onClickBtn={e =>
                setModalType(e.currentTarget.dataset.type as DialogType)
              }
            />
          )}
        </Grid>
      )}
      <ChangeStatusDialogWrapper
        modalType={modalType}
        setModalType={m => setModalType(m)}
      />
    </Grid>
  );
});

export default CalculationPage;
