/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Typography from '@mui/material/Typography';

/**
 * The internal dependencies
 */
import theme from '../../theme';

const Tag = ({ label }: { label: string }): JSX.Element => (
  <Typography
    variant="h5"
    color={theme.palette.primary.light}
    css={css`
      padding: 8px;
      background-color: #f8f8f8;
      width: max-content;
    `}
  >
    {label}
  </Typography>
);

export default Tag;
