/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

type DialogPopoverProps = {
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  contentText: string;
  isDisabledConfirm: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  buttonText?: string;
};

const DialogPopover = observer(
  ({
    children,
    isOpen,
    title,
    contentText,
    isDisabledConfirm,
    isLoading,
    handleClose,
    handleSubmit,
    buttonText,
  }: DialogPopoverProps) => {
    const { t } = useTranslation();
    return (
      <Dialog
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: 0,
            border: '1px solid #707070',
            background: '#fff',
            width: 470,
          },
        }}
      >
        <DialogTitle
          css={css`
            font-size: 16px;
          `}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            css={css`
              white-space: pre-line;
            `}
          >
            {contentText}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions
          css={css`
            padding: 0 24px 20px;
          `}
        >
          <Button
            onClick={handleClose}
            css={css`
              margin-right: 24px;
            `}
          >
            {t('abort')}
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={isDisabledConfirm}
            onClick={handleSubmit}
            css={css`
              font-size: 14px;
              padding: 16px 14px;
              background: #00223b;
              color: #ffffff;

              &.Mui-disabled {
                background: #9b9b9b;
                color: rgba(0, 0, 0, 0.26);
              }
            `}
          >
            {buttonText ? t(buttonText) : t('send')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  },
);

export default DialogPopover;
