/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect } from 'react';

import {
  GetChangeDto,
  ListProjectDto,
} from '@codefluegel/zeta-change-typescript-client';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { runInAction, toJS } from 'mobx';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStorex } from '../../store/UIStore';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { useChangeApiFactory, useProjectApiFactory } from '../../ApiClient';
import { useChangeStore } from '../../store/changeStore';
import { currencyFormatDE } from '../../utils/utils';

export type ProjectChanges = {
  item: ListProjectDto;
  changes: GetChangeDto[];
  processedList: PreprocessedData;
};

interface PreprocessedData {
  id: number;
  projectName: string;
  projectCustomer: string;
  projectManager: string;
  sumHours: number;
  totalSalesValue: number;
  overallTotalQuotationValue: number;
  numberOfChanges: number;
}

const ProjectsDashboard = observer(() => {
  const store = useStorex();
  const { setIsDataLoading } = useChangeStore();
  const changeApi = useChangeApiFactory();
  const projectApi = useProjectApiFactory();
  const navigation = useNavigate();

  const { t } = useTranslation();

  const allChanges = useLocalObservable(() => ({
    projects: [] as PreprocessedData[],
    filteredChanges: [] as PreprocessedData[],
  }));

  const onClickCell = (data: GridCellParams) => {
    const projectData = data.row;
    navigation(
      `/projects/${projectData.id}/dashboard/${projectData.projectName}`,
    );
  };

  const preprocessData = (
    project: ListProjectDto,
    changes: GetChangeDto[],
  ): PreprocessedData => {
    const {
      number: projectName,
      customer: projectCustomer,
      manager: projectManager,
    } = project;

    const sumHours = changes.reduce(
      (accumulator, change) =>
        accumulator +
        change.processes.reduce(
          (acc, process) => acc + (process.calculatedHours || 0),
          0,
        ),
      0,
    );

    const numberOfChanges = changes.length;

    const totalSalesValue = changes.reduce(
      (acc, curr) => acc + (curr.totalSalesValue || 0),
      0,
    );

    const overallTotalQuotationValue = changes.reduce(
      (acc, curr) => acc + (curr.totalQuotationValue || 0),
      0,
    );

    return {
      id: project.id,
      projectName,
      projectCustomer,
      projectManager,
      sumHours,
      totalSalesValue,
      overallTotalQuotationValue,
      numberOfChanges,
    };
  };

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);
        const changes = await changeApi.changesControllerFindAll();
        const projects = await projectApi.projectsControllerFindAll();

        const { data: projectList } = projects;
        const { data } = changes;

        if (changes) {
          const allDetailChanges = await Promise.all(
            data.map(async change => {
              const changeDetail = await changeApi.changesControllerFindOne(
                change.id,
              );
              const { data: changeData } = changeDetail;

              return changeData;
            }),
          );
          const allItems = projectList.reduce(
            (accumulator: PreprocessedData[], project) => {
              const changeData = allDetailChanges.filter(
                change => change.commonProject?.number === project.number,
              );

              return accumulator.concat(preprocessData(project, changeData));
            },
            [],
          );
          runInAction(() => {
            allChanges.projects = allItems;
            allChanges.filteredChanges = allItems;
          });

          console.error(allItems);
        }

        setIsDataLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();

    if (store.sidebar) {
      store.toggleSidebar();
    }
  }, [allChanges, changeApi, projectApi, setIsDataLoading, store]);

  const columns: GridColDef[] = [
    { field: 'projectName', headerName: t('projNo'), width: 200 },
    { field: 'projectCustomer', headerName: t('projClient'), width: 200 },
    { field: 'projectManager', headerName: t('projManager'), width: 200 },
    {
      field: 'costPerHour',
      headerName: t('costPerHours'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.costPerHour || 'TBD'} `,
    },

    {
      field: 'x',
      headerName: t('materialValue'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.x || 'TBD'} `,
    },
    {
      field: 'overallTotalQuotationValue',
      headerName: t('quotationValue'),
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) =>
        currencyFormatDE(params.value),
    },
    {
      field: 'totalSalesValue',
      headerName: t('salesValue'),
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) =>
        currencyFormatDE(params.value),
    },
    {
      field: 'numberOfChanges',
      headerName: t('numberOfChanges'),
      width: 200,
    },
  ];

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar isProjectSidebar />

      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          rows={toJS(allChanges.filteredChanges)}
          columns={columns}
          pageSize={100}
          style={{ cursor: 'pointer' }}
          rowsPerPageOptions={[5]}
          onCellClick={onClickCell}
          components={{
            Toolbar: () => (
              <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <GridToolbarExport sx={{ margin: 1 }} />
                <GridToolbarQuickFilter sx={{ margin: 1 }} />
              </GridToolbarContainer>
            ),
          }}
        />
      </div>
    </Box>
  );
});

export default ProjectsDashboard;
