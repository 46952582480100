/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { CircularProgress } from '@mui/material';

const LoadSpinnerSmall = () => (
  <CircularProgress
    color="inherit"
    css={css`
      width: 20px !important;
      height: 20px !important;
      position: absolute;
      right: 20px;
      top: 20px;
    `}
  />
);

export default LoadSpinnerSmall;
