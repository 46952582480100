import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { GetChangeDto } from '@codefluegel/zeta-change-typescript-client';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getDepartmentById } from '../../utils/utils';

const CostDialog = observer(
  ({
    onClose,
    open,
    change,
    project,
  }: {
    onClose: () => void;
    open: boolean;
    change: GetChangeDto | undefined;
    project: string;
  }) => (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="xl"
    >
      <DialogTitle
        sx={{ fontSize: 14 }}
      >{`Projekt ${project} - Stunden/KOSTEN`}</DialogTitle>

      <IconButton
        sx={{ position: 'absolute', right: 5, top: 5 }}
        color="primary"
        component="span"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      {change ? (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />

                <TableCell align="right">Stunden</TableCell>
                <TableCell align="right">Kosten</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {change.processes.map(row => {
                const department = getDepartmentById(
                  row.pId,
                  change.commonProject?.navProcessToCommonProjectRelations,
                );
                const hourlyRate = row.finalHourlyRate
                  ? row.finalHourlyRate
                  : department?.hourlyRate;
                return (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">{department?.name}</TableCell>
                    <TableCell align="right">{row.releasedHours}</TableCell>
                    <TableCell align="right">
                      {hourlyRate && row.releasedHours
                        ? row.releasedHours * hourlyRate
                        : 0}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p>no data</p>
      )}
    </Dialog>
  ),
);

export default CostDialog;
