/* eslint-disable @typescript-eslint/no-shadow */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { FocusEvent, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import DialogPopover from '../DialogPopover/DialogPopover';
import { DialogText, DialogTitle } from '../../constants/changes';
import { DialogType } from '../../types/changes';
import { useChangeStore } from '../../store/changeStore';
import LocalizationWrapper from '../Date/LocalizationWrapper';

type ConfirmChangeDialogProps = {
  isOpen: boolean;
  modalType: DialogType;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: (
    comment: string,
    reasonToChange: string,
    processIdList: number[],
  ) => void;
};

const ReworkEvaluationDialog = observer(
  ({
    isOpen,
    modalType,
    isLoading,
    handleClose,
    handleSubmit,
  }: ConfirmChangeDialogProps): JSX.Element => {
    const [reasonToChange, setReasonToChange] = useState('');
    const [value, setValue] = useState<string | null>(null);
    const [isDesktopDatePickerOpen, setIsDesktopDatePickerOpen] =
      useState(false);

    const [processList, setProcessList] = useState<number[]>([]);

    const { t } = useTranslation();
    const titles = DialogTitle();
    const texts = DialogText();

    const { change } = useChangeStore();

    const onChangeHandler = (newVal: string | null) => {
      setValue(newVal);
    };

    const onSubmitHandler = () => {
      if (value) handleSubmit(value, reasonToChange, processList);
    };

    const onDesktopDatePickerFocus = (
      e: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
    ) => {
      setIsDesktopDatePickerOpen(true);
      e.target.blur();
    };

    const addOrRemoveCheckbox = (processId: number) => {
      if (!processList.includes(processId))
        setProcessList(processList => [...processList, processId]);
      else {
        setProcessList(() =>
          processList.filter(process => process !== processId),
        );
      }
    };

    const addedProcesses =
      change?.commonProject?.navProcessToCommonProjectRelations.filter(
        process => change.processes.some(p => p.pId === process.id),
      );

    return (
      <DialogPopover
        isOpen={isOpen}
        isDisabledConfirm={!value}
        title={titles[modalType]}
        contentText={texts[modalType]}
        handleClose={handleClose}
        handleSubmit={onSubmitHandler}
        isLoading={isLoading}
      >
        <TextField
          error={!(reasonToChange ? !!reasonToChange.length : undefined)}
          css={css`
            .MuiInput-underline:before {
              border-bottom: 1px solid #ececec;
            }

            .MuiInputLabel-asterisk {
              color: #ff0000;
            }
          `}
          required
          autoFocus
          margin="dense"
          id="name"
          label={t('ReasonToChange')}
          type="text"
          fullWidth
          multiline
          value={reasonToChange}
          variant="standard"
          onChange={e => setReasonToChange(e.target.value.toString())}
        />

        <Typography variant="h5" sx={{ fontSize: 14, marginTop: 2 }}>
          {t('sendBackToKTM')}
        </Typography>
        {addedProcesses &&
          addedProcesses
            .slice()
            .sort((a, b) => Number(a.groupNo) - Number(b.groupNo))
            .map(prProcess => {
              const processID =
                change?.processes.find(process => process.pId === prProcess.id)
                  ?.id ?? 0;
              return (
                <FormGroup key={prProcess.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={processList.includes(processID)}
                        onChange={() => addOrRemoveCheckbox(processID)}
                        css={css`
                          svg {
                            color: #00223b;
                          }
                        `}
                      />
                    }
                    value={prProcess.groupNo}
                    label={`${prProcess.groupNo} | ${prProcess.owner}`}
                    css={css`
                      .MuiTypography-root {
                        font-size: 14px;
                        font-weight: 300;
                        color: #333333;
                      }
                    `}
                  />
                </FormGroup>
              );
            })}

        <LocalizationWrapper>
          <DesktopDatePicker
            open={isDesktopDatePickerOpen}
            onOpen={() => setIsDesktopDatePickerOpen(true)}
            onClose={() => setIsDesktopDatePickerOpen(false)}
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            label={t('reworkEval')}
            value={value}
            onChange={onChangeHandler}
            renderInput={params => (
              <TextField
                {...params}
                error={!value}
                autoComplete="off"
                variant="standard"
                id="date"
                css={css`
                  width: 100%;
                  margin-top: 8px;

                  .MuiInput-underline:before {
                    border-bottom: 1px solid #ececec;
                  }

                  .MuiInput-input {
                    padding-left: 16px;
                    padding-bottom: 11px;
                    font-size: 14px;
                  }

                  .MuiButtonBase-root {
                    padding: 0 12px 2px 0;
                    background-color: #ffffff !important;

                    svg {
                      width: 33px;
                      height: 25px;

                      path {
                        fill: #80acd4;
                      }
                    }
                  }
                `}
                InputLabelProps={{
                  style: {
                    paddingLeft: '20px',
                    fontWeight: 600,
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  onFocus: е => onDesktopDatePickerFocus(е),
                }}
              />
            )}
          />
        </LocalizationWrapper>
      </DialogPopover>
    );
  },
);

export default ReworkEvaluationDialog;
