/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography } from '@mui/material';

/**
 * The internal dependencies
 */
import {
  GetChangeDto,
  ProcessListForChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReducedWorkPackagesType } from '../../types/workPackages';
import ProcessTable from '../../components/ProcessCard/ProcessTable';

export type CalculationHoursPageProps = {
  isTabDataVisible: boolean;
  processes: ProcessListForChangeDto[];
  workPackages: ReducedWorkPackagesType;
  isLoading: boolean;
  handleChangeProcess: (fieldName: string, value: number, id: number) => void;
  change: GetChangeDto | null;
  editable: boolean;
};

const CalculationHoursPage = observer(
  ({
    isTabDataVisible,
    processes,
    workPackages,
    isLoading,
    handleChangeProcess,
    change,
    editable,
  }: CalculationHoursPageProps) => {
    const [activeProcess, setActiveProcess] = useState<number | null>(null);

    const { t } = useTranslation();

    const handleActiveProcess = useCallback(
      (id: number) => {
        setActiveProcess(id);
      },
      [setActiveProcess],
    );

    return (
      <Grid
        container
        direction="column"
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
          padding: 19px 0px 12px 0px;
        `}
      >
        <Typography
          variant="body1"
          color="primary"
          css={css`
            width: fit-content;
            padding: 8px;
            margin-bottom: 16px;
            background: #ececec;
            color: #333333;
            font-size: 12px;
          `}
        >
          {t('PleaseAddHours')}
        </Typography>

        <Grid
          container
          css={css`
            gap: 20px;
            width: 65vw;
          `}
        >
          {processes.map(process => (
            <ProcessTable
              change={change}
              key={process.id}
              process={process}
              workPackages={workPackages[process.id] || []}
              handleChangeProcess={handleChangeProcess}
              handleActiveProcess={handleActiveProcess}
              editable={editable}
              isActive={activeProcess ? activeProcess === process.id : false}
              isLoading={isLoading}
            />
          ))}
        </Grid>
      </Grid>
    );
  },
);

export default CalculationHoursPage;
