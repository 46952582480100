import { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  css,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { action, autorun, toJS } from 'mobx';
import {
  ListUserDto,
  ListUserWithAdminDto,
} from '@codefluegel/zeta-change-typescript-client';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  deDE,
  enUS,
} from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';

import { useUserApiFactory } from '../../ApiClient';
import i18n from '../../utils/i18n';
import AddExternalUserModal from '../../components/Modals/AddExternalUserModal';
import { useStorex } from '../../store/UIStore';
import { NotificationTypes } from '../../types/notification';
import { stringAvatar, stringToColor } from '../../utils/utils';

const ExternalUserPage = observer(() => {
  const isDataLoading = false;
  const { t } = useTranslation();

  const userApi = useUserApiFactory();
  const [selectedUser, setSelectedUser] = useState<ListUserDto | null>(null);

  const { setNotification } = useStorex();

  const [userModalState, setUserModalState] = useState(false);

  const userList = useLocalObservable(() => ({
    all: [] as ListUserWithAdminDto[],
    searchInput: '',

    get filtered() {
      if (this.searchInput) {
        const lowerCase = this.searchInput.toLowerCase();

        const filtered = this.all.filter(entry =>
          Object.values(entry).some(
            val =>
              typeof val === 'string' && val.toLowerCase().includes(lowerCase),
          ),
        );

        return filtered;
      }
      return this.all;
    },
  }));

  const handleUserModal = (
    message: string | undefined,
    newUser?: ListUserDto | undefined,
    deleteIndex?: string,
  ) => {
    setUserModalState(false);
    if (newUser) {
      const userIndex = userList.all.findIndex(item => item.id === newUser.id);
      if (userIndex >= 0) {
        const oldData = userList.all[userIndex];
        userList.all[userIndex] = { ...oldData, ...newUser };
      } else {
        userList.all.push({ ...{ isAdmin: false }, ...newUser });
      }
    }
    if (deleteIndex) {
      userList.all = userList.all.filter(item => item.id !== deleteIndex);
    }
    if (message) {
      setNotification({
        type: NotificationTypes.success,
        message,
      });
    }
  };
  const tableLocaleText =
    i18n.language === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText;

  useEffect(
    () =>
      autorun(() => {
        async function getAllUsers() {
          try {
            const { data } = await userApi.usersControllerFindAll(false);
            userList.all = data;
          } catch (error) {
            console.log({ error });
          }
        }
        getAllUsers();
      }),
    [userApi, userList],
  );

  const inputHandler = action((searchString: string) => {
    userList.searchInput = searchString;
  });

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: t('user'),
      sortable: false,

      flex: 1,
      cellClassName: 'noLeftBorder',
      headerAlign: 'center',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Box
          width={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
          }}
        >
          <Avatar
            sx={{
              bgcolor: stringToColor(params.row.displayName),
              marginLeft: 3,
            }}
          >
            {stringAvatar(params.row.displayName)}
          </Avatar>
          <Box marginLeft={2}>
            <Typography
              style={{
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                fontWeight: 600,
              }}
              variant="body2"
            >
              {params.row.displayName}
            </Typography>
            <Typography
              style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}
              variant="body2"
              color="text.secondary"
            >
              {params.row.id}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'isActive',
      headerName: t('user_status'),
      sortable: false,

      flex: 1,
      cellClassName: 'noLeftBorder',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography
            style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}
            variant="body2"
            color="text.secondary"
          >
            {params.row.isActive ? 'Active' : 'Inactive'}
          </Typography>
        </Box>
      ),
    },
    // added edit button to the end of the row
    {
      field: 'edit',
      headerName: t('edit'),
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: () => (
        <Box>
          <IconButton onClick={() => setUserModalState(true)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar IsAdminSidebar />
      <Grid
        container
        css={css`
          justify-content: space-between;
          flex-wrap: nowrap;
          @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: center;
          }
        `}
      >
        {isDataLoading && <LoadSpinner />}

        <Box
          width={1}
          p={2}
          marginTop={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h4" component="h4">
              {t('user_management')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: '24px',
              }}
            >
              <Button
                onClick={() => {
                  setSelectedUser(null);
                  setUserModalState(true);
                }}
                variant="contained"
              >
                {`+ ${t('add_user')}`}
              </Button>
              {userModalState && (
                <AddExternalUserModal
                  user={selectedUser}
                  open={userModalState}
                  handleClose={(
                    message?: string,
                    newUser?: ListUserDto | undefined,
                    deleteIndex?: string,
                  ) => handleUserModal(message, newUser, deleteIndex)}
                />
              )}
            </Box>
            <TextField
              id="outlined-basic"
              label={t('search')}
              variant="outlined"
              value={userList.searchInput}
              onChange={e => inputHandler(e.target.value)}
            />
          </Box>
        </Box>
        <Grid item xs={12}>
          <Box width={1} paddingX={2}>
            {userList.all && (
              <DataGrid
                autoHeight
                localeText={tableLocaleText}
                rows={toJS(userList.filtered) || []}
                columns={columns}
                onRowClick={params => {
                  setSelectedUser(params.row);
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ExternalUserPage;
