import { FieldError } from 'react-hook-form';
import {
  GetChangeDto,
  GetProjectDto,
  ListProjectDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';

export enum TaskStatus {
  Draft,
  ToBeImplemnted,
  Reworked,
  Rejected,
  Finished,
  Accepted,
  New,
}

export enum StatusEventTypes {
  revision = 'revision',
  reject = 'reject',
  accept = 'accept',
  discard = 'discard',
  new = 'new',
  evalComplete = 'evalComplete',
  evalAccepted = 'evalAccepted',
  calcComplete = 'calcComplete',
  evalCalcComplete = 'evalCalcComplete',
  crWorkpackageInProgress = 'crWorkpackageInProgress',
  calcRejected = 'calcRejected',
  evalRework = 'evalRework',
  internalApprovalRejected = 'internalApprovalRejected',
  check = 'check',
  close = 'close',
  rejectAll = 'rejectAll',
  reworkImplemenation = 'reworkImplemenation',
  ExternalChangeInternallyApproved = 'ExternalChangeInternallyApproved',
  CrWorkpackagesInInternalProgress = 'CrWorkpackagesInInternalProgress',
}

export type IconMap = {
  [key in UpdateChangeDtoStatusEnum]: React.ReactNode;
};

export type ChangesAllProjectsType = {
  all: GetProjectDto[];
  selected: GetProjectDto | undefined;
  setSelectedProject: (projectId: string | number) => void;
  setAllProjects: (projects: ListProjectDto[]) => void;
};

export interface IErrorForm {
  title?: FieldError | undefined;
}

export interface ExtendedChangeRequest extends GetChangeDto {
  setProjectId: (id: string) => void;
  projectId: number | undefined;
}

export type ChangesDialogType = {
  revision: string;
  reject: string;
  accept: string;
  discard: string;
  new: string;
  evalComplete: string;
  evalAccepted: string;
  calcComplete: string;
  crWorkpackageInProgress: string;
  CrWorkpackagesInInternalProgress: string;
  calcRejected: string;
  check: string;
  evalRework: string;
  internalApprovalRejected: string;
  rejectAll: string;
  close: string;
  ExternalChangeInternallyApproved: string;
};

export type ChangesDialogTypeLanguage = {
  de: Record<string, keyof ChangesDialogType>;
  en: Record<string, keyof ChangesDialogType>;
};

export type DialogType = keyof ChangesDialogType;

export const CONFIRM_CHANGE_MODAL_BTN_TYPES = [
  'reject',
  'revision',
  'evalAccepted',
  'crWorkpackageInProgress',
  'CrWorkpackagesInInternalProgress',
  'calcRejected',
  'internalApprovalRejected',
  'rejectAll',
  'accept',
];

export const DISCARD_CHANGE_MODAL_BTN_TYPES = ['discard'];

export const CONFIRM_MODAL_BTN_TYPES = [
  'new',
  'evalComplete',
  'calcComplete',
  'check',
  'close',
  'ExternalChangeInternallyApproved',
];

export const REWORK_MODALTYPES = ['evalRework'];
export const REWORKIMPLEMENTATION_MODALTYPES = ['reworkImplemenation'];
