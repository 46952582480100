import { useState } from 'react';

const useLoading = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleIsLoading = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setIsLoading(isLoading => !isLoading);
  };

  return { isLoading, toggleIsLoading };
};

export default useLoading;
