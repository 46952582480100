import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  css,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ListUserDto } from '@codefluegel/zeta-change-typescript-client';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { useUserApiFactory } from '../../ApiClient';
import { NewUser } from '../../types/users';
import NotificationMessages from '../../constants/notification';

interface Props {
  open: boolean;
  handleClose: (
    message?: string,
    newUser?: ListUserDto,
    deleteIndex?: string,
  ) => void;
  user: ListUserDto | null;
}

const AddExternalUserModal: FC<Props> = observer(props => {
  const { open, handleClose, user } = props;

  const userApi = useUserApiFactory();
  const [isBtnLoading, toggleIsBtnloading] = useState(false);
  const resetPasswordText = NotificationMessages().passwordReset;
  const userUpdateText = NotificationMessages().userUpdate;
  const userAddedText = NotificationMessages().userAdded;
  const userDeleteText = NotificationMessages().userDelete;
  // hooks
  const { t } = useTranslation();

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<NewUser>({
    mode: 'onChange',
  });

  useEffect(() => {
    const firstName = user?.displayName.split(' ')[0] ?? '';
    const lastName = user?.displayName.split(' ')[1] ?? '';

    reset({
      firstName: firstName || '',
      lastName: lastName || '',
      id: user?.id || '',
      isActive: user?.isActive || false,
    });
  }, [reset, user]);

  const onSubmit = async (data: NewUser) => {
    toggleIsBtnloading(true);

    try {
      if (user) {
        const requestData = {
          firstName: data.firstName?.trim() || '',
          lastName: data.lastName?.trim() || '',
          isActive: data.isActive,
        };
        const { data: newUser } = await userApi.usersControllerUpdate(
          user.id,
          requestData,
        );
        handleClose(userUpdateText, newUser);
      } else {
        const requestData = {
          firstName: data.firstName?.trim() || '',
          lastName: data.lastName?.trim() || '',
          id: data.id ?? '',
        };
        const { data: newUser } = await userApi.usersControllerCreate(
          requestData,
        );
        handleClose(userAddedText, newUser);
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      toggleIsBtnloading(false);
    }
  };

  const resetPasswordLink = async () => {
    if (user) {
      try {
        await userApi.usersControllerSendPasswordResetEmail(user?.id);
        handleClose(resetPasswordText, undefined);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteUser = async () => {
    try {
      if (user && user.id) {
        await userApi.usersControllerRemove(user?.id);
        handleClose(userDeleteText, undefined, user?.id);
      }
    } catch (e: any) {
      handleClose(e.response.data.message);
    }
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle
        sx={{ m: 0, p: 2, fontSize: 16 }}
        id="customized-dialog-title"
      >
        {user ? t('edit_entry') : t('add_user')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ width: 400 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Controller
              control={control}
              name="firstName"
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  error={!field.value}
                  css={css`
                    width: 80%;
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  required
                  label={t('firstName')}
                  value={field.value || ''}
                  onChange={field.onChange}
                  type="string"
                  variant="standard"
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },
                    inputProps: {
                      min: 1,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="lastName"
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  error={!field.value}
                  css={css`
                    width: 80%;
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  required
                  label={t('lastName')}
                  value={field.value || ''}
                  onChange={field.onChange}
                  type="string"
                  variant="standard"
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },
                    inputProps: {
                      min: 1,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="id"
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  disabled={!!user?.id}
                  error={!field.value}
                  css={css`
                    margin-top: 16px;
                    width: 80%;
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  required
                  label={t('email')}
                  value={field.value || ''}
                  onChange={field.onChange}
                  type="email"
                  variant="standard"
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },
                    inputProps: {
                      min: 1,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
          </Box>
          {user && (
            <Box
              css={css`
                display: flex;
                gap: 18px;
                margin-bottom: 3px;
                justify-content: space-between;
              `}
            >
              <Controller
                control={control}
                name="isActive"
                render={({ field }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value || false}
                          value={field.value || false}
                          onChange={field.onChange}
                          css={css`
                            svg {
                              color: #00223b;
                            }
                          `}
                        />
                      }
                      label={t('isactive')}
                      css={css`
                        .MuiTypography-root {
                          font-size: 14px;
                          font-weight: 300;
                          color: #333333;
                        }
                      `}
                    />
                  </FormGroup>
                )}
              />
            </Box>
          )}
          {user && (
            <Typography
              sx={{
                marginTop: 2,
                marginBottom: 2,
                color: 'grey',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              variant="subtitle1"
              onClick={resetPasswordLink}
            >
              {t('reset_password')}
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {user && (
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  mr: 2,
                  bgcolor: 'white',
                  color: '#00223B',
                  outline: 'solid 1px #00223B',
                  '&:hover': {
                    bgcolor: '#00223B',
                    color: 'white',
                  },
                }}
                onClick={deleteUser}
              >
                {t('delete')}
              </Button>
            )}
            <LoadingButton
              loading={isBtnLoading}
              disabled={!isValid}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
              type="submit"
            >
              {t('save')}
            </LoadingButton>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
});

export default AddExternalUserModal;
