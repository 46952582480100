/* eslint-disable react/jsx-fragments */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Fragment, MouseEvent } from 'react';
import {
  GetChangeDto,
  ListWorkPackageDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { Grid, Button } from '@mui/material';

/**
 * The internal dependencies
 */

import { useTranslation } from 'react-i18next';
import { StatusEventTypes } from '../../types/changes';
import {
  isCalculationPerson,
  isChangeManager,
  userIsProjectViewer,
} from '../../utils/permissionHelper';

type ButtonsProps = {
  changeStatus: string;
  isChangeExist: boolean;
  onClickBtn: (e: MouseEvent<HTMLButtonElement>) => void;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  workPackages?: ListWorkPackageDto[];
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  change?: GetChangeDto;
};

const GeneralButtons = ({
  changeStatus,
  isChangeExist,
  onClickBtn,
  change,
  workPackages,
}: ButtonsProps) => {
  const isEvalStatus = changeStatus === UpdateChangeDtoStatusEnum.EvalComplete;
  const isCalcCompleteStatus =
    changeStatus === UpdateChangeDtoStatusEnum.CalcComplete;
  const isCheckStatus = changeStatus === UpdateChangeDtoStatusEnum.Check;
  const isNewStatus = changeStatus === UpdateChangeDtoStatusEnum.New;
  const isEvalRework = changeStatus === UpdateChangeDtoStatusEnum.EvalRework;
  const isInEvaluation =
    changeStatus === UpdateChangeDtoStatusEnum.InEvaluation;
  const inImplementation =
    changeStatus === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
    changeStatus === UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress;
  const isReworkCalc =
    changeStatus === UpdateChangeDtoStatusEnum.EvalCalcRejected;
  const isEvalAccepted =
    changeStatus === UpdateChangeDtoStatusEnum.EvalAccepted;

  const isDraftOrDraftDiscarded =
    changeStatus === UpdateChangeDtoStatusEnum.Draft ||
    changeStatus === UpdateChangeDtoStatusEnum.DraftDiscarded ||
    changeStatus === UpdateChangeDtoStatusEnum.NewRework;

  const isApprovedInternallyStatus =
    changeStatus === UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved;

  const isAllWorkPackagesCompleted =
    workPackages &&
    workPackages.every(wP => wP.status.toLocaleLowerCase() === 'implemented');

  const { t } = useTranslation();

  const isManager = isChangeManager(change);
  const isCalcPerson = isCalculationPerson(change);

  const isOnlyProjectViewer = userIsProjectViewer(change);

  const isInternal = change?.preSelectInternalChange;

  const affectedProcessesIds =
    change?.processes &&
    change?.processes
      .filter(process => !process.affected)
      .map(process => process.id);

  const workPackageProcessIds =
    workPackages && workPackages.map(wP => wP.processId);

  const checkWorkPackageAffectedAndEmpty =
    affectedProcessesIds &&
    affectedProcessesIds.every(
      val => workPackageProcessIds && workPackageProcessIds.indexOf(val) >= 0,
    );

  const processesFinished = change?.processes.every(
    process => process.evaluationStatus === 'APPROVED',
  );

  return (
    <Fragment>
      {isOnlyProjectViewer ? (
        <Fragment />
      ) : (
        <Fragment>
          {isDraftOrDraftDiscarded && (
            <Button onClick={onClickBtn} data-type={StatusEventTypes.discard}>
              {t('discard')}
            </Button>
          )}
          {isDraftOrDraftDiscarded && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.new}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('sendToCM')}
            </Button>
          )}
          {isChangeExist && isNewStatus && isManager && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.revision}
              disabled={!isManager}
            >
              {t('toRework')}
            </Button>
          )}
          {isChangeExist && isNewStatus && isManager && (
            <Button onClick={onClickBtn} data-type={StatusEventTypes.reject}>
              {t('reject')}
            </Button>
          )}
          {isChangeExist && isNewStatus && isManager && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.accept}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('approve')}
            </Button>
          )}
          {isEvalStatus && isManager && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.evalRework}
            >
              {t('reworkEval')}
            </Button>
          )}

          {isEvalStatus && isManager && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.evalAccepted}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('toCalc')}
            </Button>
          )}

          {/* KTM BTN */}
          {(isInEvaluation || isEvalRework) && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.evalComplete}
              disabled={!checkWorkPackageAffectedAndEmpty || !processesFinished}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('finishEval')}
            </Button>
          )}
          {isCalcCompleteStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              data-type={StatusEventTypes.evalRework}
              disabled={!isManager}
            >
              {t('reworkEval')}
            </Button>
          )}
          {isCalcCompleteStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              data-type={StatusEventTypes.calcRejected}
              disabled={!isManager}
            >
              {t('calcReworkTo')}
            </Button>
          )}
          {isCalcCompleteStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              data-type={StatusEventTypes.rejectAll}
              disabled={!isManager}
            >
              {t('reject')}
            </Button>
          )}
          {isInternal && isCalcCompleteStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              data-type={StatusEventTypes.crWorkpackageInProgress}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('ApproveInternallyAndImplement')}
            </Button>
          )}
          {!isInternal && isCalcCompleteStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              data-type={StatusEventTypes.ExternalChangeInternallyApproved}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('ApproveInternally')}
            </Button>
          )}

          {isApprovedInternallyStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              data-type={StatusEventTypes.CrWorkpackagesInInternalProgress}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('ImplementWithoutApproval')}
            </Button>
          )}
          {isApprovedInternallyStatus && isManager && (
            <Button
              sx={{ height: 40 }}
              onClick={onClickBtn}
              disabled={
                change?.statusClient !== 'Approved' ||
                !change?.approvalDateClient
              }
              data-type={StatusEventTypes.crWorkpackageInProgress}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('toImplement')}
            </Button>
          )}
          {isApprovedInternallyStatus && isManager && (
            <Button
              onClick={onClickBtn}
              disabled={
                change?.statusClient !== 'Rejected' ||
                !change?.approvalDateClient
              }
              data-type={StatusEventTypes.rejectAll}
            >
              {t('reject')}
            </Button>
          )}
          {isCheckStatus && isManager && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.reworkImplemenation}
            >
              {t('reworkImplementation')}
            </Button>
          )}
          {isCheckStatus && isManager && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.close}
              disabled={
                !change?.preSelectInternalChange
                  ? !change?.totalQuotationValue ||
                    change?.statusClient !== 'Approved' ||
                    !change?.approvalDateClient ||
                    !change?.externalApprovalCustomerDate
                  : false
              }
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('finalize Change')}
            </Button>
          )}
          {(isEvalAccepted || isReworkCalc) && (isManager || isCalcPerson) && (
            <Button
              onClick={onClickBtn}
              data-type={StatusEventTypes.calcComplete}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('finish Calc')}
            </Button>
          )}

          {(isInEvaluation ||
            isEvalRework ||
            isEvalStatus ||
            isEvalAccepted ||
            isReworkCalc) &&
            isManager && (
              <Button
                onClick={onClickBtn}
                data-type={StatusEventTypes.rejectAll}
              >
                {t('reject')}
              </Button>
            )}
          {isChangeExist && isManager && inImplementation && (
            <Button
              disabled={!isAllWorkPackagesCompleted}
              onClick={onClickBtn}
              data-type={StatusEventTypes.check}
              css={css`
                font-size: 14px;
                padding: 17px 15px;
                background: #00223b;
                color: #ffffff;

                &.Mui-disabled {
                  background: #9b9b9b;
                  color: rgba(0, 0, 0, 0.26);
                }
              `}
            >
              {t('finishImpl')}
            </Button>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

type ButtonsGroupProps = {
  isChangeExist: boolean;
  changeStatus: string;
  workPackages: ListWorkPackageDto[];
  onClickBtn: (e: MouseEvent<HTMLButtonElement>) => void;
  change: GetChangeDto;
};

const ButtonsGroup = observer(
  ({
    isChangeExist,
    changeStatus,
    workPackages,
    change,
    onClickBtn,
  }: ButtonsGroupProps) => (
    <Grid
      item
      xs={12}
      sm={6}
      lg={6}
      css={css`
        display: flex;
        gap: 20px;
        justify-content: end;
        align-items: end;
        max-width: 100% !important;
        @media (max-width: 480px) {
          justify-content: center;
          gap: 10px;
        }
      `}
    >
      <GeneralButtons
        changeStatus={changeStatus}
        isChangeExist={isChangeExist}
        onClickBtn={onClickBtn}
        change={change}
        workPackages={workPackages}
      />
    </Grid>
  ),
);

export default ButtonsGroup;
