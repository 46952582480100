/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Box,
  InputAdornment,
} from '@mui/material';
import { useState, Fragment, ChangeEvent, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import {
  CreateChangeDto,
  GetChangeDto,
  GetChangeRequestAttachmentDto,
  UpdateChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import LaunchIcon from '@mui/icons-material/Launch';
import { v4 as uuidv4 } from 'uuid';
import { useChangeApiFactory } from '../../ApiClient';
import { checkIfRejected, disableInput } from '../../utils/permissionHelper';
import { hasOversizedFile } from '../../utils/utils';

/**
 * The internal dependencies
 */

export const Input = styled('input')({
  display: 'none',
});

export type ProjectEditAttachmentPageProps = {
  isTabDataVisible: boolean;
  change: GetChangeDto | null;
  control: Control<CreateChangeDto & UpdateChangeDto, any>;
  onError: (message?: string) => void;
};

const ProjectEditAttachmentPage = observer(
  ({
    isTabDataVisible,
    change,
    control,
    onError,
  }: ProjectEditAttachmentPageProps) => {
    const [filesData, setFilesData] = useState(change?.attachments || []);

    const changeApi = useChangeApiFactory();
    const { t } = useTranslation();
    const permissionToEdit = disableInput(change);

    const isRejected = checkIfRejected(change);

    useEffect(() => {
      if (change) {
        setFilesData(change?.attachments);
      }
    }, [change]);

    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const isOverSizedFile = hasOversizedFile(e);

        if (isOverSizedFile) {
          onError(t('fileTooBig'));
          return;
        }

        if (change?.id && e.target.files) {
          const newChangeRequest =
            await changeApi.changesControllerAddAttachments(
              change.id,
              Array.from(e.target.files),
              false,
            );
          setFilesData(newChangeRequest.data.attachments);
        } else {
          onError();
        }
      } catch (err) {
        console.log(err);
      }
    };

    const saveData = (buffer: any, name: string) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    };

    const downloadFile = async (item: GetChangeRequestAttachmentDto) => {
      if (change?.id) {
        const file = await changeApi.changesControllerDownloadAttachment(
          change.id,
          item.id,
          {
            responseType: 'arraybuffer',
          },
        );
        saveData(file.data, item.fileName || '');
      }
    };

    const handleRemoveFile = async (
      item: GetChangeRequestAttachmentDto,
      fileIndex: number,
    ) => {
      try {
        if (change?.id) {
          await changeApi.changesControllerDeleteAttachment(change.id, item.id);
          setFilesData(prevFiles =>
            prevFiles.filter((_, index) => index !== fileIndex),
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    const openLink = () => {
      if (change?.sharepointLink) {
        let url = change?.sharepointLink;
        if (!url.match(/^https?:\/\//i)) {
          url = `https://${url}`;
        }
        return window.open(url, '_blank');
      }
      return window.open(change?.sharepointLink ?? '', '_blank');
    };

    return (
      <Box
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
        `}
      >
        <Grid
          container
          direction="column"
          css={css`
            padding: 19px 0px 12px 0px;
          `}
        >
          {!isRejected && (
            <label htmlFor="contained-button-file">
              <Input
                accept=".pdf, .xls, .xlsx, .doc, .docx"
                id="contained-button-file"
                multiple
                type="file"
                disabled={permissionToEdit}
                onChange={handleFileInputChange}
              />
              <Button
                variant="contained"
                component="span"
                disabled={permissionToEdit}
              >
                {`+ ${t('AddCalc')}`}
              </Button>
            </label>
          )}
          <List
            css={css`
              display: ${filesData.length > 0 ? 'block' : 'none'};
              margin-top: 25px;
              margin-bottom: 7px;
              padding: 5px 15px;
              border: 1px solid #f5f5f5;
            `}
          >
            {filesData.map((item, idx) => (
              <Fragment key={uuidv4()}>
                <ListItem
                  sx={{ cursor: 'pointer' }}
                  secondaryAction={
                    <Box>
                      {!isRejected && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveFile(item, idx)}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Box>
                  }
                >
                  <ListItemText
                    onClick={() => downloadFile(item)}
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '300',
                    }}
                    primary={item.fileName}
                  />
                </ListItem>

                {filesData.length - 1 !== idx && <Divider light />}
              </Fragment>
            ))}
          </List>

          <Controller
            control={control}
            name="sharepointLink"
            defaultValue=""
            render={({ field }) => (
              <TextField
                error={!field.value?.length}
                css={css`
                  .MuiInput-underline:before {
                    border-bottom: 1px solid #ececec;
                  }
                  .MuiInputLabel-asterisk {
                    color: #ff0000;
                  }
                  margin-top: 16px;
                `}
                multiline
                id="sharepointLink"
                label="Sharepoint Link"
                value={field.value}
                disabled={permissionToEdit || isRejected}
                onChange={field.onChange}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={openLink}
                        edge="end"
                      >
                        <LaunchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    paddingLeft: '16px',
                    paddingBottom: '6px',
                    marginBottom: '16px',
                    fontSize: '14px',
                  },
                }}
                InputLabelProps={{
                  style: {
                    paddingLeft: '20px',
                    fontWeight: 600,
                    color: '#333333',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Box>
    );
  },
);

export default ProjectEditAttachmentPage;
