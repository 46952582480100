/* eslint-disable react/jsx-fragments */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { observer } from 'mobx-react-lite';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, ListItemButton, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { useStorex } from '../../store/UIStore';

const drawerWidth = 236;

const AdminSidebarDrawer = observer(() => {
  const store = useStorex();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { t } = useTranslation();

  return (
    <Fragment>
      <IconButton
        sx={{
          transform: 'translateX(-272px)',
        }}
        size="small"
        css={css`
          position: absolute;
          top: 72px;
          left: 224px;
          z-index: 1201;
          padding: 0 !important;
          background: #ffffff;

          box-shadow: 0px 1px 3px #00000029;
          width: max-content;
          margin-left: auto;

          transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        `}
        onClick={store.toggleAdminSidebar}
      >
        <ChevronLeftIcon color="primary" />
      </IconButton>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Drawer
          title="Admin Frontend"
          PaperProps={{
            elevation: 3,
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              top: '45px',
              height: 'calc(100% - 45px)',
              backgroundColor: 'white',
              border: 'none',
            },
            flexGrow: 1,
            bgcolor: 'white',
            p: 3,
          }}
          variant="persistent"
          anchor="left"
          open={store.adminSidebar}
        >
          <Toolbar
            css={css`
              padding-right: 0px !important;
            `}
          >
            <Box
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding-top: 8px;
              `}
            >
              <Typography variant="h4">{t('admin_area')}</Typography>

              <Typography variant="body2">{`${t('role')}: ${t(
                'admin',
              )}`}</Typography>
            </Box>
          </Toolbar>

          <List
            css={css`
              display: flex;
              flex-direction: column;
              gap: 16px;
              padding-top: 32px;
              font-size: 12px;
              & .MuiListItemButton-root {
                padding-left: 24px;
              }
            `}
          >
            <ListItemButton
              css={css`
                background-color: ${pathname.includes('external')
                  ? 'rgba(0, 0, 0, 0.05)'
                  : 'none'};
              `}
              onClick={() =>
                !pathname.includes('external') &&
                navigate(`/admin/user-management/external`)
              }
            >
              {t('externalUser')}
            </ListItemButton>
            <ListItemButton
              css={css`
                background-color: ${pathname.includes('internal')
                  ? 'rgba(0, 0, 0, 0.05)'
                  : 'none'};
              `}
              onClick={() =>
                !pathname.includes('internal') &&
                navigate(`/admin/user-management/internal`)
              }
            >
              {t('internalUser')}
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
    </Fragment>
  );
});

export default AdminSidebarDrawer;
