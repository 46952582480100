/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { Language } from '../../store/UIStore';

export type NotificationPanelProps = {
  children: React.ReactNode;
  onRead?: () => void;
  isNavigationBar?: boolean;
};

export const NotificationPanelLocalization = {
  [Language.DE]: {
    Title: 'Benachrichtungen',
  },
  [Language.EN]: {
    Title: 'Notifications',
  },
};

const NotificationPanel = observer(
  ({ children, onRead, isNavigationBar }: NotificationPanelProps) => {
    const { t } = useTranslation();

    const markRead = () => {
      if (onRead) onRead();
    };

    return (
      <Box
        css={css`
          padding: 24px 16px 24px 24px;
          background: #ececec5c;
          margin-top: 20px;
          @media (max-width: 600px) {
            padding-left: 0px;
            padding-right: 0px;
          }
        `}
      >
        <Typography
          variant="h4"
          component="h4"
          css={css`
            padding-bottom: 24px;
            padding-left: 13px;
            padding-top: 15px;
          `}
        >
          {t('notifications')}
        </Typography>
        {isNavigationBar && (
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              style={{
                marginBottom: 8,
                textDecoration: 'underline',
                border: 'none',
                fontSize: 12,
              }}
              onClick={markRead}
              variant="outlined"
            >
              {t('markRead')}
            </Button>
          </Box>
        )}
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

export default NotificationPanel;
