import { ListWorkPackageDto } from '@codefluegel/zeta-change-typescript-client';

const getTotalTime = (workPackages: ListWorkPackageDto[]) =>
  workPackages.reduce(
    // eslint-disable-next-line no-return-assign, no-param-reassign
    (acc: number, item: ListWorkPackageDto) => (acc += item.hours || 0),
    0,
  );

const displayHours = (
  workPackage: ListWorkPackageDto,
  hourlyRate: number,
): string => {
  if (workPackage.hours && hourlyRate)
    return new Intl.NumberFormat(localStorage.getItem('i18nextLng') || 'de', {
      minimumFractionDigits: 2,
    }).format(workPackage.hours * hourlyRate);
  return '0';
};

const formatNumber = (number: number) =>
  new Intl.NumberFormat(localStorage.getItem('i18nextLng') || 'de', {
    minimumFractionDigits: 2,
  }).format(number);

const getSumOfWP = (
  workPackages: ListWorkPackageDto[],
  hourlyRate: number,
): number =>
  workPackages.reduce(
    (acc: number, item: ListWorkPackageDto) =>
      acc + (item.hours || 0) * hourlyRate,
    0,
  );

export { getTotalTime, displayHours, getSumOfWP, formatNumber };
