/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

/**
 * The internal dependencies
 */
import { Outlet, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import { useStorex } from '../store/UIStore';
import { useChangeStore } from '../store/changeStore';
import {
  useChangeApiFactory,
  usePspPhaseApiFactory,
  useUserApiFactory,
  useWorkPackageApiFactory,
} from '../ApiClient';
import { useUserStore } from '../store/userStore';
import { useProjectStore } from '../store/projectStore';
import PageNotFoundText from '../components/Error/PageNotFoundText';
import useMedia from '../hooks/useMedia';

const ChangeLayoutPage = observer(() => {
  const { t } = useTranslation();
  const pageTitles: { [key: string]: string } = {
    edit: t('Details'),
    resources: t('res'),
    calculation: t('calc'),
    external: t('extern'),
  };

  const store = useStorex();
  const { change, setChange, setWorkPackages, setIsDataLoading } =
    useChangeStore();
  const { setUsers } = useUserStore();
  const { setPsPPhases, setPsPWorkPackages } = useProjectStore();
  const { changeId } = useParams();
  const location = useLocation();

  const isMobile = useMedia('sm');

  const changeApi = useChangeApiFactory();
  const userApi = useUserApiFactory();
  const pspPhaseApi = usePspPhaseApiFactory();
  const useWorkPackageApi = useWorkPackageApiFactory();
  const [permissionDenied, setPermissionDenied] = useState(false);

  const pageName: string = location.pathname.split('/').pop() ?? '';

  useEffect(() => {
    if (changeId) {
      (async () => {
        try {
          setIsDataLoading(true);

          const { data: newChange } = await changeApi.changesControllerFindOne(
            Number(changeId),
          );

          if (newChange) {
            setIsDataLoading(false);
            setChange(newChange);
          }
        } catch (e: any) {
          setIsDataLoading(false);
          if (e?.response?.data?.statusCode === 404) {
            setPermissionDenied(true);
            console.log('permission denied');
          }
          console.log(e);
        }

        try {
          setIsDataLoading(true);
          const { data: workPackages } =
            await useWorkPackageApi.workPackagesControllerFindAll(undefined, [
              Number(changeId),
            ]);

          if (workPackages) {
            setIsDataLoading(false);
            setWorkPackages(workPackages);
          }
        } catch (e) {
          setIsDataLoading(false);
          console.log(e);
        }
      })();
    }
  }, [
    changeApi,
    changeId,
    setChange,
    setIsDataLoading,
    setWorkPackages,
    useWorkPackageApi,
  ]);

  useEffect(() => {
    (async () => {
      try {
        const { data: allUsers } = await userApi.usersControllerFindAll();
        if (allUsers) {
          setUsers(allUsers);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [setUsers, userApi]);

  useEffect(() => {
    (async () => {
      try {
        const { data: pspWp } = await pspPhaseApi.pspPhasesControllerFindAll();
        setPsPPhases(pspWp);

        const allWorkPackages = await Promise.all(
          pspWp.map(async pspPhase => {
            const { data: workpackage } =
              await pspPhaseApi.pspPhasesControllerFindAllPspWorkPackages(
                pspPhase.id,
              );
            return { [pspPhase.id]: workpackage };
          }),
        );
        setPsPWorkPackages(allWorkPackages);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [pspPhaseApi, setPsPPhases, setPsPWorkPackages]);

  return (
    <Box
      css={css`
        padding: 48px 15px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        width: calc(100% - ${store.sidebar ? 236 : 0}px);

        @media (max-width: 900px) {
          width: 100%;
        }

        @media (max-width: 375px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar HasSidebar />
      {!isMobile && (
        <Breadcrumbs
          css={css`
            margin-top: -48px;
            font-size: 12px;
            font-weight: 600;
          `}
        >
          <Link href="/projects" underline="hover">
            {t('projects')}
          </Link>
          {change && change.commonProject ? (
            <Link
              href={`/projects/${change.commonProject.id}`}
              underline="hover"
            >
              {change.commonProject.number}
            </Link>
          ) : null}
          {change && change.id ? (
            <Link href={`/change-request/${change.id}`} underline="hover">
              {pageTitles[pageName] || t('overview')}
            </Link>
          ) : null}
          <Link href={`/change-request/${change?.id}`} underline="hover">
            {change?.title}
          </Link>
        </Breadcrumbs>
      )}

      {!permissionDenied ? <Outlet /> : <PageNotFoundText />}
    </Box>
  );
});

export default ChangeLayoutPage;
