/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, Button, Typography, Icon, Tooltip } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import {
  GetChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { runInAction, toJS } from 'mobx';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStorex } from '../../store/UIStore';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { useChangeApiFactory } from '../../ApiClient';
import { useChangeStore } from '../../store/changeStore';
import DateViewDialog from '../../components/Admin/DateViewDialog';
import CostDialog from '../../components/Admin/CostDialog';
import { currencyFormatDE } from '../../utils/utils';
import PieChartDialog from '../../components/Admin/PieChartDialog';
import TaskCardIconMap from '../../utils/changes';

const ChangeDashboardPage = observer(() => {
  const store = useStorex();
  const { setIsDataLoading } = useChangeStore();
  const changeApi = useChangeApiFactory();
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openPieChart, setOpenPieChartModal] = useState(false);
  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [currentChange, setCurrentChange] = useState<GetChangeDto | undefined>(
    undefined,
  );

  const { t } = useTranslation();
  const { projectId, projectNumber } = useParams();

  const allChanges = useLocalObservable(() => ({
    changes: [] as GetChangeDto[],
    filteredChanges: [] as GetChangeDto[],
  }));

  const openAppointments = (cellValues: GridCellParams) => {
    const c = allChanges.changes.find(
      change => change.id === cellValues.row.id,
    );
    setCurrentChange(c);
    setOpenDateModal(true);
  };

  const openHoursDialog = (cellValues: GridCellParams) => {
    const c = allChanges.changes.find(
      change => change.id === cellValues.row.id,
    );
    setCurrentChange(c);
    setOpenHoursModal(true);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);
        const changes = await changeApi.changesControllerFindAll();

        const { data } = changes;

        if (changes) {
          const allDetailChanges = await Promise.all(
            data
              .filter(change => change.projectId === Number(projectId))
              .map(async change => {
                const changeDetail = await changeApi.changesControllerFindOne(
                  change.id,
                );
                const { data: changeData } = changeDetail;

                return changeData;
              }),
          );

          runInAction(() => {
            allChanges.changes = allDetailChanges;
            allChanges.filteredChanges = allDetailChanges;
          });
        }
        setIsDataLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();

    if (store.sidebar) {
      store.toggleSidebar();
    }
  }, [allChanges, changeApi, projectId, setIsDataLoading, store]);

  const columns: GridColDef[] = [
    { field: 'changeNo', headerName: t('ChangeNo'), width: 200 },
    { field: 'changeNoClient', headerName: t('clientChangeNo'), width: 200 },

    {
      field: '',
      headerName: t('costPerHours'),
      width: 200,
      renderCell: cellValues => (
        <Typography onClick={() => openHoursDialog(cellValues)}>...</Typography>
      ),
    },
    {
      field: 'salesMaterialValue',
      headerName: t('MaterialKosten'),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.salesMaterialValue || ''} `,
    },
    {
      field: 'finalSalesValue',
      headerName: t('salesValue'),
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) =>
        currencyFormatDE(params.value || 0),
    },
    {
      field: 'finalQuotationValue',
      headerName: t('quotationValue'),
      type: 'number',
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) =>
        currencyFormatDE(params.value || 0),
    },
    {
      field: 'webToolVersion',
      headerName: t('WebTool Import'),
      sortable: false,
      width: 200,
    },
    {
      field: 'Termine',
      headerName: t('dates'),
      sortable: false,
      width: 200,
      renderCell: cellValues => (
        <Typography onClick={() => openAppointments(cellValues)}>
          ...
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: t('Status'),
      sortable: false,
      width: 200,
      renderCell: cellValues => (
        <Tooltip title={t(cellValues.row.status).toString()} placement="right">
          <Icon>
            {
              TaskCardIconMap[
                cellValues.row.status as UpdateChangeDtoStatusEnum
              ]
            }
          </Icon>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar />
      <Typography variant="h4">{`Liste der Changes von Projekt ${projectNumber}`}</Typography>

      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          rows={toJS(allChanges.filteredChanges)}
          columns={columns}
          pageSize={100}
          style={{ cursor: 'pointer' }}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: () => (
              <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <Button onClick={() => setOpenPieChartModal(true)}>
                  Übersicht Changes{' '}
                </Button>
                <GridToolbarExport sx={{ margin: 1 }} />
                <GridToolbarQuickFilter sx={{ margin: 1 }} />
              </GridToolbarContainer>
            ),
          }}
        />
      </div>
      <DateViewDialog
        change={currentChange}
        open={openDateModal}
        project={currentChange?.commonProject?.number || ''}
        onClose={() => setOpenDateModal(false)}
      />
      <CostDialog
        change={currentChange}
        open={openHoursModal}
        project={currentChange?.commonProject?.number || ''}
        onClose={() => setOpenHoursModal(false)}
      />
      <PieChartDialog
        changes={allChanges.changes}
        project={projectNumber || ''}
        onClose={() => setOpenPieChartModal(false)}
        open={openPieChart}
      />
    </Box>
  );
});

export default ChangeDashboardPage;
