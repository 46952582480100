import List from '@mui/material/List';
import Modal from '@mui/material/Modal';
import { observer } from 'mobx-react-lite';
import {
  ListNotificationDto,
  ListProjectDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { useAuth } from 'react-oidc-context';
import NotificationPanel from '../NotificationPanel/NotificationPanel';
import NotificationCard from '../NotificationPanel/NotificationCard/NotificationCard';
import { useUserApiFactory } from '../../ApiClient';

const style = {
  position: 'relative',
  top: '5%',
  left: '70%',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  maxHeight: '100%',
  overflow: 'auto',
  '@media screen and (max-width: 480px)': {
    left: '0',
  },
};

const NotificationModal = observer(
  ({
    notifications,
    onClose,
    open,
    projects,
  }: {
    notifications: ListNotificationDto[];
    onClose: (url: string) => void;
    open: boolean;
    projects: ListProjectDto[];
  }) => {
    const userApi = useUserApiFactory();
    const auth = useAuth();
    const user = auth.user?.profile.email?.toLocaleLowerCase();

    const markRead = async () => {
      try {
        if (user) await userApi.usersControllerMarkAllNotificationsRead(user);
        onClose('');
      } catch (e) {
        console.log(e);
      }
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <List sx={style}>
          <NotificationPanel onRead={markRead} isNavigationBar>
            {notifications.map(notification => (
              <NotificationCard
                key={notification.id}
                read={notification.read}
                Title={notification.changeRequest.title || ''}
                ChangeNoClient={notification.changeRequest.changeNoClient || ''}
                ChangeNo={notification.changeRequest.changeNo || ''}
                NotificationId={notification.id}
                ProjectId={
                  projects.find(
                    project =>
                      project.id === notification.changeRequest.projectId,
                  )?.name || ''
                }
                navigateToChange={url => onClose(url)}
                ChangeId={notification.changeRequest.id.toString()}
                Status={
                  (notification.changeRequest
                    .status as UpdateChangeDtoStatusEnum) || ''
                }
              />
            ))}
          </NotificationPanel>
        </List>
      </Modal>
    );
  },
);

export default NotificationModal;
