/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ReplayIcon from '@mui/icons-material/Replay';
import AdjustIcon from '@mui/icons-material/Adjust';
import CalculateIcon from '@mui/icons-material/Calculate';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';
import inImplemenation from '../images/inImplementation.svg';
/**
 * The internal dependencies
 */
import { IconMap } from '../types/changes';

const TaskCardIconMap: IconMap = {
  [UpdateChangeDtoStatusEnum.Draft]: (
    <RadioButtonUncheckedIcon
      css={css`
        color: #9dbfde;
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.NewRework]: (
    <TimelapseIcon
      css={css`
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.EvalRework]: (
    <ReplayIcon
      css={css`
        color: #97d480;
        transform: rotate(-115deg);
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.NewRejected]: (
    <CancelIcon
      css={css`
        color: #d48080;
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.Rejected]: (
    <CancelIcon
      css={css`
        color: #d48080;
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.InEvaluation]: (
    <FindReplaceOutlinedIcon
      css={css`
        color: #97d480;
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.CalcComplete]: (
    <CheckCircleIcon
      css={css`
        color: #ff8400;
        width: 20px;
      `}
    />
  ),
  [UpdateChangeDtoStatusEnum.New]: (
    <AdjustIcon
      css={css`
        width: 20px;
      `}
    />
  ),
  CHECK: (
    <CheckCircleIcon
      css={css`
        width: 20px;
      `}
    />
  ),
  EVAL_ACCEPTED: (
    <CalculateIcon
      css={css`
        width: 20px;
      `}
    />
  ),
  EVAL_COMPLETE: (
    <CheckCircleIcon
      css={css`
        color: #97d480;
        width: 20px;
      `}
    />
  ),
  INTERNAL_APPROVAL_REJECTED: (
    <CancelIcon
      css={css`
        width: 20px;
        color: red;
      `}
    />
  ),
  CLOSED: (
    <VerifiedOutlinedIcon
      css={css`
        width: 20px;
        color: green;
      `}
    />
  ),
  CR_WORKPACKAGES_IN_PROGRESS: (
    <img
      style={{ width: '20px' }}
      src={inImplemenation}
      alt="In Implementation"
    />
  ),
  EVAL_CALC_REJECTED: (
    <ReplayIcon
      css={css`
        color: #ff8400;
        transform: rotate(-115deg);
        width: 20px;
      `}
    />
  ),
  DRAFT_DISCARDED: (
    <CancelIcon
      css={css`
        color: #d48080;
        width: 20px;
      `}
    />
  ),
  EXTERNAL_CHANGE_INTERNALLY_APPROVED: (
    <CheckCircleIcon
      css={css`
        color: #97d480;
        width: 20px;
      `}
    />
  ),
  CR_WORKPACKAGES_IN_INTERNAL_PROGRESS: (
    <img
      style={{
        width: '20px',
        filter:
          'invert(60%) sepia(6%) saturate(2536%) hue-rotate(314deg) brightness(96%) contrast(99%)',
      }}
      src={inImplemenation}
      alt="In Implementation"
    />
  ),
  INTERNAL_APPROVAL_ACCEPTED: undefined,
};

export default TaskCardIconMap;
