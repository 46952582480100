/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import {
  UpdateChangeDtoStatusEnum,
  ListChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { observer, useLocalObservable } from 'mobx-react-lite';
import Section from './Section';
import TaskCard from '../TaskCard/TaskCard';

type UserSectionProps = {
  changeRequests: ListChangeDto[];
  openChange: (changeId: number, status: string) => void;
  title: string;
};

const UserSection = observer(
  ({ changeRequests, openChange, title }: UserSectionProps): JSX.Element => {
    const allChanges = useLocalObservable(() => ({
      changes: changeRequests,
      filteredChanges: changeRequests,
    }));

    const [filteredStatuses, setFilteredStatuses] = useState<string[]>([]);

    const inputHandler = (searchString: string, statuses?: string[]) => {
      const lowerCase = searchString.toLowerCase();

      const filtered = allChanges.changes.filter(entry =>
        Object.values(entry).some(
          val =>
            typeof val === 'string' && val.toLowerCase().includes(lowerCase),
        ),
      );

      allChanges.filteredChanges =
        filtered.length && statuses && statuses.length
          ? filtered.filter(item => statuses.includes(item.status))
          : filtered;
    };

    const allPossibleStatus = Array.from(
      new Set(changeRequests.map(cr => cr.status)),
    );

    return (
      <Grid item>
        <Section
          search
          onSearch={(searchString: string, statuses) => {
            if (statuses) setFilteredStatuses(statuses);
            inputHandler(searchString, statuses);
          }}
          Title={title}
          filteredStatuses={filteredStatuses}
          allPossibleStatus={allPossibleStatus}
        >
          <Grid
            container
            css={css`
              display: flex;
              align-items: center;
              gap: 16px;
              margin-top: 10px;
            `}
          >
            {allChanges.filteredChanges &&
              allChanges.filteredChanges.map(changeRequest => (
                <Grid item key={changeRequest.id}>
                  <TaskCard
                    Title={changeRequest.title || ''}
                    Status={changeRequest.status as UpdateChangeDtoStatusEnum}
                    ChangeNoClient={changeRequest.changeNoClient || ''}
                    ChangeNo={changeRequest.changeNo || ''}
                    ChangeId={changeRequest.id}
                    CompanySection={changeRequest.statusClient || ''}
                    DueDate={new Date(changeRequest.createdAt) || new Date()}
                    Author={changeRequest.initiator || ''}
                    onClick={(changeId: number) =>
                      openChange(changeId, changeRequest.status)
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </Section>
      </Grid>
    );
  },
);

export default UserSection;
