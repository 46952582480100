/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, TextField } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ListProjectDto } from '@codefluegel/zeta-change-typescript-client';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { toJS } from 'mobx';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStorex } from '../../store/UIStore';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { useProjectApiFactory } from '../../ApiClient';
import { useChangeStore } from '../../store/changeStore';
import { useProjectStore } from '../../store/projectStore';

const ProjectsOverviewPage = observer(() => {
  const store = useStorex();
  const { setIsDataLoading } = useChangeStore();
  const { setProject } = useProjectStore();
  const projectsApi = useProjectApiFactory();
  const navigation = useNavigate();

  const { t } = useTranslation();

  const allProjects = useLocalObservable(() => ({
    projects: [] as ListProjectDto[],
    filteredProjects: [] as ListProjectDto[],
  }));

  const onClickCell = (data: GridCellParams) => {
    const projectData: ListProjectDto = data.row;
    setProject(data.row);
    navigation(`/projects/${projectData.id}`);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);
        const projects = await projectsApi.projectsControllerFindAll();
        const { data } = projects;
        if (projects) {
          allProjects.projects = data;
          allProjects.filteredProjects = data;
        }
        setIsDataLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();

    if (store.sidebar) {
      store.toggleSidebar();
    }
  }, [allProjects, projectsApi, setIsDataLoading, store]);

  const columns: GridColDef[] = [
    { field: 'number', headerName: t('projNo'), width: 200 },
    { field: 'code', headerName: t('projCode'), width: 200 },
    { field: 'name', headerName: t('projName'), width: 200 },
    { field: 'customer', headerName: t('projClient'), width: 200 },
    {
      field: 'manager',
      headerName: t('projManager'),
      type: 'number',
      width: 200,
    },
    {
      field: 'changeManager',
      headerName: t('projChangeManager'),
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
    },
  ];
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lowerCase = e.target.value.toLowerCase();

    const filtered = allProjects.projects.filter(entry =>
      Object.values(entry).some(
        val => typeof val === 'string' && val.toLowerCase().includes(lowerCase),
      ),
    );
    allProjects.filteredProjects = filtered;
  };

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TextField
          onChange={inputHandler}
          id="outlined-basic"
          variant="outlined"
          fullWidth
          label={t('search')}
          sx={{ width: 300, marginBottom: 1 }}
        />
      </div>
      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          rows={toJS(allProjects.filteredProjects)}
          columns={columns}
          pageSize={100}
          style={{ cursor: 'pointer' }}
          rowsPerPageOptions={[5]}
          onCellClick={onClickCell}
        />
      </div>
    </Box>
  );
});

export default ProjectsOverviewPage;
