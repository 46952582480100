import { makeAutoObservable } from 'mobx';
import React, { createContext, useContext } from 'react';
import {
  ListProcessProjectDto,
  ListProcessTemplateDto,
  ListProjectDto,
  ListPspPhasesDto,
  ListPspWorkPackagesDto,
} from '@codefluegel/zeta-change-typescript-client';

export interface IProjectStore {
  project: ListProjectDto | null;
  setProject: (project: ListProjectDto) => void;
  projects: ListProjectDto[];
  associatedProcesses: ListProcessProjectDto[];
  setAssociatedProcesses: (project: ListProcessProjectDto[]) => void;
  setProjects: (project: ListProjectDto[]) => void;
  processTemplates: ListProcessTemplateDto[];
  setProcessTemplates: (processTemplates: ListProcessTemplateDto[]) => void;
  pspPhases: ListPspPhasesDto[];
  setPsPPhases: (project: ListPspPhasesDto[]) => void;
  pspWorkPackages: Record<string, ListPspWorkPackagesDto[]>[];
  setPsPWorkPackages: (
    project: Record<string, ListPspWorkPackagesDto[]>[],
  ) => void;
}

export class ProjectStore implements IProjectStore {
  constructor() {
    makeAutoObservable(this);
  }

  project: ListProjectDto | null = null;

  setProject = (project: ListProjectDto | null) => {
    this.project = project;
  };

  projects: ListProjectDto[] = [];

  setProjects = (projects: ListProjectDto[]) => {
    this.projects = projects;
  };

  processTemplates: ListProcessTemplateDto[] = [];

  setProcessTemplates = (processTemplates: ListProcessTemplateDto[]) => {
    this.processTemplates = processTemplates;
  };

  pspPhases: ListPspPhasesDto[] = [];

  setPsPPhases = (pspPhases: ListPspPhasesDto[]) => {
    this.pspPhases = pspPhases;
  };

  pspWorkPackages: Record<string, ListPspWorkPackagesDto[]>[] = [];

  setPsPWorkPackages = (
    pspWorkPackages: Record<string, ListPspWorkPackagesDto[]>[],
  ) => {
    this.pspWorkPackages = pspWorkPackages;
  };

  associatedProcesses: ListProcessProjectDto[] = [];

  setAssociatedProcesses = (associatedProcesses: ListProcessProjectDto[]) => {
    this.associatedProcesses = associatedProcesses;
  };
}

const initialChangeStore = new ProjectStore();

export const StoreContext = createContext<IProjectStore>(initialChangeStore);

export type StoreProviderProps = {
  children: React.ReactNode;
};

export const useProjectStore = (): IProjectStore => useContext(StoreContext);
