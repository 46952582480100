/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Box, CircularProgress } from '@mui/material';

const LoadSpinner = () => (
  <Box
    css={css`
      min-height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    `}
  >
    <CircularProgress color="inherit" />
  </Box>
);

export default LoadSpinner;
