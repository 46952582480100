/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';

/**
 * The internal dependencies
 */

import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import ConfirmChangeDialog from './ConfirmChangeDialog';
import {
  ChangesDialogType,
  CONFIRM_CHANGE_MODAL_BTN_TYPES,
  CONFIRM_MODAL_BTN_TYPES,
  DISCARD_CHANGE_MODAL_BTN_TYPES,
  REWORK_MODALTYPES,
  REWORKIMPLEMENTATION_MODALTYPES,
} from '../../types/changes';
import ConfirmAcceptanceDialog from './ConfirmAcceptanceDialog';
import ReworkEvaluationDialog from './ReworkEvaluationDialog';
import ReworkImplemetationDialog from './ReworkImplemetationDialog';
import useLoading from '../../utils/useLoading';
import { useStorex } from '../../store/UIStore';
import { NotificationTypes } from '../../types/notification';
import NotificationMessages from '../../constants/notification';
import { useButtonServices } from '../../hooks/useButtonServices';
import DiscardChangeDialog from './DiscardChangeDialog';

type AddProcessModalProps = {
  modalType: keyof ChangesDialogType | null;
  setModalType: (modalType: keyof ChangesDialogType | null) => void;
};

const ChangeStatusDialogWrapper = observer(
  ({ modalType, setModalType }: AddProcessModalProps) => {
    const { t } = useTranslation();
    const NotificationMessage = NotificationMessages();

    const { isLoading, toggleIsLoading } = useLoading();
    const { setNotification } = useStorex();

    const navigate = useNavigate();
    const { useSendToReworkImplementation, useSendToRework, submitButtons } =
      useButtonServices();

    const sendToReworkImplementation = useCallback(
      async (
        value: string,
        reasonToChange: string,
        processIdList: number[],
      ) => {
        try {
          toggleIsLoading();
          await useSendToReworkImplementation(
            value,
            reasonToChange,
            processIdList,
          );
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.changeStatusUpdate,
          });
          toggleIsLoading();
          navigate('/');
          setModalType(null);
        } catch (e: any) {
          setNotification({
            type: NotificationTypes.error,
            message: `${NotificationMessage.error} ${
              e?.response?.data?.message ?? ''
            }`,
          });
          toggleIsLoading();
          console.log(e);
        }
      },
      [
        toggleIsLoading,
        useSendToReworkImplementation,
        setNotification,
        NotificationMessage.changeStatusUpdate,
        NotificationMessage.error,
        navigate,
        setModalType,
      ],
    );

    const sendToRework = useCallback(
      async (
        value: string,
        reasonToChange: string,
        processIdList: number[],
      ) => {
        try {
          toggleIsLoading();

          await useSendToRework(value, reasonToChange, processIdList);
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.changeStatusUpdate,
          });
          toggleIsLoading();
          navigate('/');
          setModalType(null);
        } catch (e: any) {
          setNotification({
            type: NotificationTypes.error,
            message: `${NotificationMessage.error} ${e.response.data.message}`,
          });
          toggleIsLoading();
          console.log(e);
        }
      },
      [
        toggleIsLoading,
        useSendToRework,
        setNotification,
        NotificationMessage.changeStatusUpdate,
        NotificationMessage.error,
        navigate,
        setModalType,
      ],
    );

    const onSubmitDialog = useCallback(
      async (value?: string) => {
        try {
          toggleIsLoading();
          await submitButtons(value, modalType);
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.changeStatusUpdate,
          });
          navigate('/');
          setModalType(null);
        } catch (e: any) {
          if (
            e?.response?.data?.message ===
            'A calculation document must have been uploaded when calculation created!'
          ) {
            setNotification({
              type: NotificationTypes.error,
              message: t('calcDocMissing'),
            });
          } else {
            setNotification({
              type: NotificationTypes.error,
              message: `${NotificationMessage.error} ${e.response.data.message}`,
            });
            console.log(e);
          }
        } finally {
          setModalType(null);
          toggleIsLoading();
        }
      },
      [
        toggleIsLoading,
        submitButtons,
        modalType,
        setNotification,
        NotificationMessage.changeStatusUpdate,
        NotificationMessage.error,
        navigate,
        setModalType,
        t,
      ],
    );

    const onCloseDialog = useCallback(() => {
      setModalType(null);
    }, [setModalType]);

    return (
      <Box>
        {modalType && DISCARD_CHANGE_MODAL_BTN_TYPES.includes(modalType) && (
          <DiscardChangeDialog
            isOpen={!!modalType}
            handleClose={onCloseDialog}
            handleSubmit={onSubmitDialog}
            modalType={modalType}
            isLoading={isLoading}
          />
        )}
        {modalType && CONFIRM_CHANGE_MODAL_BTN_TYPES.includes(modalType) && (
          <ConfirmChangeDialog
            isOpen={!!modalType}
            handleClose={onCloseDialog}
            handleSubmit={onSubmitDialog}
            modalType={modalType}
            isLoading={isLoading}
          />
        )}
        {modalType && CONFIRM_MODAL_BTN_TYPES.includes(modalType) && (
          <ConfirmAcceptanceDialog
            isOpen={!!modalType}
            handleClose={onCloseDialog}
            handleSubmit={onSubmitDialog}
            modalType={modalType}
            isLoading={isLoading}
          />
        )}
        {modalType && REWORK_MODALTYPES.includes(modalType) && (
          <ReworkEvaluationDialog
            isOpen={!!modalType}
            handleClose={onCloseDialog}
            handleSubmit={sendToRework}
            modalType={modalType}
            isLoading={isLoading}
          />
        )}
        {modalType && REWORKIMPLEMENTATION_MODALTYPES.includes(modalType) && (
          <ReworkImplemetationDialog
            isOpen={!!modalType}
            handleClose={onCloseDialog}
            handleSubmit={sendToReworkImplementation}
            modalType={modalType}
            isLoading={isLoading}
          />
        )}
      </Box>
    );
  },
);

export default ChangeStatusDialogWrapper;
