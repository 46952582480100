import {
  GetProjectDto,
  ListUserDto,
} from '@codefluegel/zeta-change-typescript-client';

export enum UserListTypes {
  ChangeManager,
  TemporaryChangeManager,
  KTM,
  Viewer,
}

export type NewUser = {
  firstName: string;
  lastName: string;
  id?: string;
  isActive?: boolean;
};

export type ReturnUser = {
  user: ListUserDto;
};

export type ProjectViewerList = {
  project: GetProjectDto;
  setCurrentProject: (p: GetProjectDto) => void;
  viewerList: ListUserDto[];
  addToViewerList: (viewer?: ListUserDto) => void;
  removeFromViewerList: (viewer?: ListUserDto) => void;
};
