/**
 * The external dependencies
 */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * The internal dependencies
 */
import { ChangeEvent } from 'react';
import {
  ListWorkPackageDto,
  ProcessListForChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { useChangeStore } from '../../store/changeStore';
import { isChangeManager, isKTM } from '../../utils/permissionHelper';

export type ProcessAccordionFormProps = {
  process: ProcessListForChangeDto;
  handleChange: (fieldName: string, fieldValue: string | boolean) => void;
  workPackages: ListWorkPackageDto[];
};

const ProcessAccordionForm = ({
  process,
  handleChange,
  workPackages,
}: ProcessAccordionFormProps): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const value =
      target.type === 'text' || target.type === 'textarea'
        ? target.value
        : target.checked;

    handleChange(target.name, value);
  };
  const onBlur = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { target } = e;
    const { value } = target;

    handleChange(target.name, value);
  };

  const { t } = useTranslation();

  const { change } = useChangeStore();

  const isKTMorCM = isKTM(change, process.pId) || isChangeManager(change);

  const workPackagesAvailable = workPackages.length > 0;

  return (
    <Grid
      item
      xs={12}
      sm={5}
      lg={6}
      css={css`
        padding: 0px 24px 0px 30px;
      `}
    >
      <Grid container direction="column">
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={process.affected}
                  name="affected"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  disabled={
                    process.evaluationStatus === 'APPROVED' ||
                    !isKTMorCM ||
                    workPackagesAvailable ||
                    process.plannedMaterial ||
                    process.timeImpact ||
                    process.affectedDescription
                  }
                  onChange={onChange}
                />
              }
              label={t('affectedBy')}
            />
          </FormGroup>
        </Grid>
        <TextField
          name="affectedDescription"
          disabled={
            process.evaluationStatus === 'APPROVED' ||
            !process.affected ||
            !isKTMorCM
          }
          defaultValue={process.affectedDescription || ''}
          onBlur={onBlur}
          css={css`
            width: 100%;

            .MuiInput-underline:before {
              border-bottom: 1px solid #ececec;
            }

            .Mui-disabled {
              cursor: not-allowed;
            }
          `}
          label={t('affectedDesc')}
          variant="standard"
          InputProps={{
            style: {
              marginBottom: 20,
              paddingLeft: '16px',
              fontSize: '14px',
            },
          }}
          InputLabelProps={{
            style: {
              paddingLeft: '20px',
              fontWeight: 600,
              color: '#333333',
            },
          }}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  process.evaluationStatus === 'APPROVED' ||
                  !isKTMorCM ||
                  process.affected
                }
                checked={process.timeImpact}
                name="timeImpact"
                onChange={onChange}
              />
            }
            label={t('timeMaterial')}
          />
        </FormGroup>

        <Grid
          item
          css={css`
            display: flex;
            margin-bottom: 16px;
          `}
        >
          <TextField
            name="timeImpactDescription"
            disabled={
              process.evaluationStatus === 'APPROVED' ||
              !process.timeImpact ||
              process.affected ||
              !isKTMorCM
            }
            multiline
            defaultValue={process.timeImpactDescription || ''}
            onBlur={onBlur}
            css={css`
              width: 100%;
              .MuiInput-underline:before {
                border-bottom: 1px solid #ececec;
              }

              .Mui-disabled {
                cursor: not-allowed;
              }
            `}
            label={t('DescTime')}
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon
                    css={css`
                      color: ${theme.palette.primary.light};
                      width: 20px;
                    `}
                  />
                </InputAdornment>
              ),

              style: {
                paddingLeft: '16px',
                fontSize: '14px',
              },
            }}
            InputLabelProps={{
              style: {
                paddingLeft: '20px',
                fontWeight: 600,
                color: '#333333',
              },
            }}
          />
        </Grid>

        <Grid
          item
          css={css`
            display: flex;
          `}
        >
          <TextField
            name="plannedMaterial"
            multiline
            disabled={
              process.evaluationStatus === 'APPROVED' ||
              !isKTMorCM ||
              process.affected
            }
            minRows={2}
            defaultValue={process.plannedMaterial || ''}
            onBlur={onBlur}
            css={css`
              width: 100%;

              .MuiInput-underline:before {
                border-bottom: 1px solid #ececec;
              }
            `}
            label={t('plannedMats')}
            variant="standard"
            InputProps={{
              style: {
                paddingLeft: '16px',
                fontSize: '14px',
              },
            }}
            InputLabelProps={{
              style: {
                paddingLeft: '20px',
                fontWeight: 600,
                color: '#333333',
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProcessAccordionForm;
