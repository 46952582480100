import React, { createContext, useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { NotificationType } from '../types/notification';

export enum Language {
  DE = 'DE',
  EN = 'EN',
}

export interface IStore {
  sidebar: boolean;
  adminSidebar: boolean;
  notification: NotificationType | null;
  language: Language;
  toggleSidebar: () => void;
  toggleAdminSidebar: () => void;
  setNotification: (data: NotificationType | null) => void;
  setLanguage: (language: Language) => void;
}

export class Store implements IStore {
  sidebar = true;

  adminSidebar = true;

  language = Language.DE;

  notification: NotificationType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  toggleSidebar = () => {
    this.sidebar = !this.sidebar;
  };

  toggleAdminSidebar = () => {
    this.adminSidebar = !this.adminSidebar;
  };

  setLanguage = (language: Language) => {
    this.language = language;
  };

  setNotification = (data: NotificationType | null) => {
    this.notification = data;

    setTimeout(() => {
      this.notification = null;
    }, 5000);
  };
}

const initialStore = new Store();

export const StoreContext = createContext<IStore>(initialStore);

export type StoreProviderProps = {
  children: React.ReactNode;
};

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const store = initialStore ?? new Store();

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStorex = (): IStore => useContext(StoreContext);
