import { createTheme } from '@mui/material/styles';
import '@fontsource/source-sans-pro';

const theme = createTheme({
  typography: {
    fontFamily: ['Source Sans Pro'].join(','),
    allVariants: {
      color: '#00223B',
      fontFamily: 'Source Sans Pro',
    },
    h4: {
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: '700',
      letterSpacing: '-0.6px',
      lineHeight: '30px',
    },
    h5: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '10px',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    caption: {
      fontWeight: 200,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#00223B',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&. Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: '#000000b3 !important',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: '#000000b3 !important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: '#000000b3 !important',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: 'black',
            WebkitTextFillColor: '#000000b3 !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '120px',
          minHeight: '34px',
          letterSpacing: '1.2px',
        },
        text: {
          boxShadow: '0px 0px 3px 2px #9b9b9b',
          backgroundColor: '#9b9b9b',
          width: 'max-content',
          padding: '0px 15px',
          height: '40px',
          fontWeight: 'bold',
          color: '#00223B',
          '&:hover': {
            backgroundColor: '#707070',
            boxShadow: '0px 0px 3px 2px #707070',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          wordBreak: 'break-all',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#A3AFB8',
          '&.Mui-checked': {
            color: '#A3AFB8',
          },
        },
      },
    },
  },
  palette: {
    action: {
      // disabled: '#000000b3',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#ECECEC',
      default: '#fff',
    },
    primary: {
      main: '#00223B',
      light: '#80ACD4',
    },
    secondary: {
      main: '#ECECEC',
    },
  },
});
export default theme;
