import { useCallback } from 'react';
import {
  UpdateChangeDtoStatusEnum,
  UpdateProcessDtoEvaluationStatusEnum,
  UpdateWorkPackageDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import {
  useChangeApiFactory,
  useProcessApiFactory,
  useWorkPackageApiFactory,
} from '../ApiClient';
import { DialogType, StatusEventTypes } from '../types/changes';
import { getSumOfWP, getTotalTime } from '../utils/getTotalTime';
import { useChangeStore } from '../store/changeStore';
import { getDepartmentById } from '../utils/utils';

export const useButtonServices = (): any => {
  const changeApi = useChangeApiFactory();
  const { change, reducedWorkPackages } = useChangeStore();
  const processApi = useProcessApiFactory();
  const workPackageApi = useWorkPackageApiFactory();

  const updateCalculatedHoursofProcesses = useCallback(async () => {
    const sumOfAllProcesses = (
      await Promise.all(
        change?.processes?.map(async process => {
          if (
            reducedWorkPackages[process.id] &&
            reducedWorkPackages[process.id].length > 0
          ) {
            const currentHours = getTotalTime(reducedWorkPackages[process.id]);

            await processApi.processesControllerUpdate(Number(process.id), {
              calculatedHours: currentHours,
              affected: process?.affected,
              timeImpact: process?.timeImpact,
              timeImpactDescription: process?.timeImpactDescription || '',
              plannedMaterial: process?.plannedMaterial || '',
            });
            return currentHours;
          }
          return 0;
        }) ?? [],
      )
    ).reduce((partialSum, a) => partialSum + a, 0);
    return sumOfAllProcesses;
  }, [change?.processes, processApi, reducedWorkPackages]);

  const updateReleasedHoursofProcesses = useCallback(async () => {
    const sumOfAllProcesses = (
      await Promise.all(
        change?.processes?.map(async process => {
          if (
            reducedWorkPackages[process.id] &&
            reducedWorkPackages[process.id].length > 0
          ) {
            const currentHours = getTotalTime(reducedWorkPackages[process.id]);

            await processApi.processesControllerUpdate(Number(process.id), {
              releasedHours: process.calculatedHours,
              affected: process?.affected,
              timeImpact: process?.timeImpact,
              timeImpactDescription: process?.timeImpactDescription || '',
              plannedMaterial: process?.plannedMaterial || '',
            });
            return currentHours;
          }
          return 0;
        }) ?? [],
      )
    ).reduce((partialSum, a) => partialSum + a, 0);
    return sumOfAllProcesses;
  }, [change?.processes, processApi, reducedWorkPackages]);

  const getAllProcessHours = useCallback(
    isReleaseHours =>
      change?.processes.reduce((acc, process) => {
        const department = getDepartmentById(
          process.pId,
          change?.commonProject?.navProcessToCommonProjectRelations,
        );
        if (process && department?.hourlyRate && process.calculatedHours) {
          if (isReleaseHours && process?.releasedHours) {
            return acc + department?.hourlyRate * process?.releasedHours;
          }
          return acc + department?.hourlyRate * process?.calculatedHours;
        }
        if (reducedWorkPackages[process.id]) {
          const sum = getSumOfWP(
            reducedWorkPackages[process.id],
            department?.hourlyRate || 0,
          );
          return sum;
        }
        return acc + 0;
      }, 0),
    [
      change?.commonProject?.navProcessToCommonProjectRelations,
      change?.processes,
      reducedWorkPackages,
    ],
  );

  const useSendToReworkImplementation = useCallback(
    async (value: string, reasonToChange: string, processIdList: number[]) => {
      const changeId = change?.id;

      await changeApi.changesControllerUpdate(Number(changeId), {
        status: UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress,
        rejectionComment: reasonToChange,
        evaluationTargetDate: value,
      });

      await Promise.all(
        processIdList.map(processId =>
          reducedWorkPackages[Number(processId)]?.map(async workPackage =>
            workPackageApi.workPackagesControllerUpdate(workPackage.id, {
              status: UpdateWorkPackageDtoStatusEnum.Open,
            }),
          ),
        ),
      );
    },
    [change?.id, changeApi, reducedWorkPackages, workPackageApi],
  );

  const useSendToRework = useCallback(
    async (value: string, reasonToChange: string, processIdList: number[]) => {
      const changeId = change?.id;

      await changeApi.changesControllerUpdate(Number(changeId), {
        status: UpdateChangeDtoStatusEnum.EvalRework,
        rejectionComment: reasonToChange,
        evaluationTargetDate: value,
      });

      await Promise.all(
        processIdList.map(async pId => {
          await processApi.processesControllerUpdate(pId, {
            evaluationStatus:
              UpdateProcessDtoEvaluationStatusEnum.OpenAfterRework,
          });
        }),
      );
    },
    [change?.id, changeApi, processApi],
  );

  const submitButtons = useCallback(
    async (value?: string, modalType?: DialogType) => {
      const changeId = change?.id;

      switch (modalType) {
        case StatusEventTypes.revision:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.NewRework,
            rejectionComment: value,
          });
          break;
        case StatusEventTypes.accept:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.InEvaluation,
            evaluationTargetDate: value,
            preSelectInternalChange: change?.preSelectInternalChange,
          });
          break;
        case StatusEventTypes.reject:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.NewRejected,
            rejectionComment: value,
          });
          break;
        case StatusEventTypes.new:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.New,
          });
          break;
        case StatusEventTypes.discard:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.DraftDiscarded,
          });
          break;
        case StatusEventTypes.calcComplete:
          {
            const sumHours = getAllProcessHours(false) ?? 0;

            await changeApi.changesControllerUpdate(Number(changeId), {
              status: UpdateChangeDtoStatusEnum.CalcComplete,
              finalQuotationValue: sumHours,
              totalQuotationValue:
                sumHours -
                (change?.projectDiscount ?? 0) +
                (change?.materialValue ?? 0),
            });
            await updateReleasedHoursofProcesses();
          }
          break;
        case StatusEventTypes.ExternalChangeInternallyApproved:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved,
          });
          break;
        case StatusEventTypes.CrWorkpackagesInInternalProgress:
          {
            const releasedHoursSum = getAllProcessHours(true) ?? 0;
            await changeApi.changesControllerUpdate(Number(changeId), {
              status:
                UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress,
              finalSalesValue: releasedHoursSum,
              totalSalesValue:
                releasedHoursSum -
                (change?.salesValueDiscount ?? 0) +
                (change?.salesMaterialValue ?? 0),
              implementationTargetDate: value,
            });
          }
          break;
        case StatusEventTypes.crWorkpackageInProgress:
          {
            const releasedHoursSum = getAllProcessHours(true) ?? 0;
            await changeApi.changesControllerUpdate(Number(changeId), {
              status: UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress,
              finalSalesValue: releasedHoursSum,
              totalSalesValue:
                releasedHoursSum -
                (change?.salesValueDiscount ?? 0) +
                (change?.salesMaterialValue ?? 0),
              implementationTargetDate: value,
            });
          }
          break;
        case StatusEventTypes.calcRejected:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.EvalCalcRejected,
            rejectionComment: value,
          });
          break;
        case StatusEventTypes.evalComplete:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.EvalComplete,
            alternativProposals: change && change.alternativProposals,
            solutionProposal: change && change.solutionProposal,
          });
          break;
        case StatusEventTypes.evalRework:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.EvalRework,
            rejectionComment: value,
          });
          break;
        case StatusEventTypes.evalAccepted:
          {
            await changeApi.changesControllerUpdate(Number(changeId), {
              status: UpdateChangeDtoStatusEnum.EvalAccepted,
              calcResponsible: value,
            });
            const sumHours = await updateCalculatedHoursofProcesses();
            await changeApi.changesControllerUpdate(Number(changeId), {
              status: UpdateChangeDtoStatusEnum.EvalAccepted,
              finalQuotationValue: sumHours,
            });
          }
          break;
        case StatusEventTypes.internalApprovalRejected:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.InternalApprovalRejected,
            rejectionComment: value,
          });
          break;
        case StatusEventTypes.check:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.Check,
          });
          break;
        case StatusEventTypes.close:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.Closed,
          });
          break;
        case StatusEventTypes.rejectAll:
          await changeApi.changesControllerUpdate(Number(changeId), {
            status: UpdateChangeDtoStatusEnum.Rejected,
            rejectionComment: value,
          });
          break;
        default:
          break;
      }
    },
    [
      change,
      changeApi,
      getAllProcessHours,
      updateCalculatedHoursofProcesses,
      updateReleasedHoursofProcesses,
    ],
  );

  return {
    submitButtons,
    useSendToReworkImplementation,
    useSendToRework,
  };
};

export default useButtonServices;
