/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  UpdateChangeDtoStatusEnum,
  UpdateWorkPackageDtoStatusEnum,
  GetChangeDto,
  UpdateChangeDto,
} from '@codefluegel/zeta-change-typescript-client';

/**
 * The internal dependencies
 */
import { useNavigate, useParams } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import {
  useChangeApiFactory,
  useProcessApiFactory,
  useWorkPackageApiFactory,
} from '../ApiClient';
import ProcessAccordionItem from '../components/ProcessItems/ProcessAccordionItem';
import { ActiveProcessType } from '../types/processes';
import WorkPackageModal from '../components/Dialog/WorkPackageModal';
import { useChangeStore } from '../store/changeStore';
import WorkPackageViewModal from '../components/Dialog/WorkPackageViewModal';
import LoadSpinner from '../components/LoadSpinner/LoadSpinner';
import useLoading from '../utils/useLoading';
import { NotificationTypes } from '../types/notification';
import NotificationMessages from '../constants/notification';
import { useStorex } from '../store/UIStore';
import {
  isChangeManager,
  useIsViewerChangeManager,
  userIsProjectViewer,
} from '../utils/permissionHelper';
import ButtonsGroup from '../components/ButtonsGroup/ButtonsGroup';
import { DialogType } from '../types/changes';
import ChangeStatusDialogWrapper from '../components/Dialog/ChangeStatusDialogWrapper';

export type ProcessFormType = {
  affected: boolean;
  timeImpact: boolean;
  timeImpactDescription: string;
  plannedMaterial: string;
};

const ProjectEditResourcesGroupPage = observer(() => {
  const {
    change,
    reducedWorkPackages,
    isDataLoading,
    setUpdatedChange,
    pushNewWorkPackage,
    replaceUpdatedWorkPackage,
    removeWorkPackage,
    workPackages,
    setIsDataLoading,
    setWorkPackages,
  } = useChangeStore();
  const { changeId, processId } = useParams();
  const navigate = useNavigate();
  const { setNotification } = useStorex();
  const NotificationMessage = NotificationMessages();

  const auth = useAuth();

  const changeApi = useChangeApiFactory();
  const processApi = useProcessApiFactory();
  const workPackageApi = useWorkPackageApiFactory();
  const { isLoading, toggleIsLoading } = useLoading();
  const { t } = useTranslation();
  const [modalType, setModalType] = useState<DialogType | null>(null);

  const isManager = isChangeManager(change);
  let processes = change?.processes || [];

  const isOnlyProjectViewer = userIsProjectViewer(change);
  const isViewerManager = useIsViewerChangeManager(change);

  const showButtonGroup =
    change?.status === UpdateChangeDtoStatusEnum.InEvaluation ||
    change?.status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
    change?.status ===
      UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress ||
    change?.status === UpdateChangeDtoStatusEnum.EvalComplete ||
    change?.status === UpdateChangeDtoStatusEnum.EvalRework;

  const findCurrentProcess = useCallback(
    (pId: number) => change?.processes.find(process => process.id === pId),
    [change],
  );

  const activeProcess = useLocalObservable<ActiveProcessType>(() => ({
    process: null,
    currentWorkPackage: null,
    setProcess: currentProcess => {
      if (currentProcess) {
        activeProcess.process = currentProcess;
        if (window.location.href.includes('resources'))
          navigate(
            `/change-request/${changeId}/resources/${currentProcess.id}`,
          );
        else {
          navigate(
            `/change-request/${changeId}/implementation/${currentProcess.id}`,
          );
        }
      }
    },
    resetProcess: () => {
      activeProcess.process = null;
      if (window.location.href.includes('resources'))
        navigate(`/change-request/${changeId}/resources`);
      else {
        navigate(`/change-request/${changeId}/implementation`);
      }
    },
    setCurrentWorkPackage: workPackage => {
      if (workPackage) {
        activeProcess.currentWorkPackage = workPackage;
      } else {
        activeProcess.currentWorkPackage = null;
      }
    },
  }));

  const [isWorpackageModalVisible, toggleWorpackageModalVisible] =
    useState(false);
  const [isWorpackageViewModalVisible, toggleWorpackageViewModalVisible] =
    useState(false);

  // TODO Add temporary CTM and projektviewer
  const userHasFullAccess =
    change?.commonProject?.navProcessToCommonProjectRelations.some(
      commonProcess =>
        commonProcess.fullAccess &&
        (commonProcess.owner?.toLocaleLowerCase() ===
          auth.user?.profile.email?.toLocaleLowerCase() ||
          commonProcess.temporaryCoreTeamMember?.user.id.toLocaleLowerCase() ===
            auth.user?.profile.email?.toLocaleLowerCase()),
    ) ||
    isManager ||
    isOnlyProjectViewer ||
    isViewerManager;

  if (!userHasFullAccess) {
    processes =
      processes?.filter(process =>
        change?.commonProject?.navProcessToCommonProjectRelations.some(
          commonProcess =>
            commonProcess.id === process.pId &&
            (commonProcess.owner?.toLocaleLowerCase() ===
              auth.user?.profile.email?.toLocaleLowerCase() ||
              commonProcess.temporaryCoreTeamMember?.user.id.toLocaleLowerCase() ===
                auth.user?.profile.email?.toLocaleLowerCase()),
        ),
      ) ?? null;
  }

  const isCrWorkpackagesInProgress = !!(
    change &&
    (change.status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
      change.status === UpdateChangeDtoStatusEnum.Check ||
      change.status === UpdateChangeDtoStatusEnum.Closed ||
      change.status === UpdateChangeDtoStatusEnum.Rejected ||
      change.status ===
        UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress)
  );
  const isImplementedBtnVisible = isCrWorkpackagesInProgress;

  const debouncedRequest = useDebouncedCallback(
    async (data: { [key: string]: string | boolean }) => {
      try {
        if (processId && activeProcess) {
          toggleIsLoading();

          const { data: updatedProcess } =
            await processApi.processesControllerUpdate(Number(processId), {
              affected: activeProcess.process?.affected,
              timeImpact: activeProcess.process?.timeImpact,
              timeImpactDescription:
                activeProcess.process?.timeImpactDescription || '',
              plannedMaterial: activeProcess.process?.plannedMaterial || '',
              ...data,
            });

          if (updatedProcess && change) {
            const updatedProcesses = change?.processes.map(process => {
              if (process.id === updatedProcess.id) {
                const updatedCurrnetProcess = {
                  ...process,
                  ...updatedProcess,
                };

                activeProcess.setProcess(updatedCurrnetProcess);
                return updatedCurrnetProcess;
              }
              return process;
            });

            const newChangeRequest: GetChangeDto | UpdateChangeDto = {
              ...change,
              processes: updatedProcesses,
            };

            setNotification({
              type: NotificationTypes.success,
              message: NotificationMessage.processUpdate,
            });
            setUpdatedChange(newChangeRequest);

            const allProcessesFinished = newChangeRequest?.processes.every(
              prcs => prcs.evaluationStatus === 'APPROVED',
            );

            if (allProcessesFinished) {
              await changeApi.changesControllerUpdate(change.id, {
                ...change,
                status: UpdateChangeDtoStatusEnum.EvalComplete,
              });

              toggleIsLoading();
              navigate('/');
            }

            toggleIsLoading();
          }
        }
      } catch (e) {
        setNotification({
          type: NotificationTypes.error,
          message: NotificationMessage.error,
        });
        toggleIsLoading();
        console.error(e);
      }
    },
    300,
  );

  useEffect(() => {
    (async () => {
      if (changeId && change && processId) {
        const currentProcess = findCurrentProcess(Number(processId));
        if (currentProcess) activeProcess.setProcess(currentProcess);
      }
    })();
  }, [changeId, change, activeProcess, processId, findCurrentProcess]);

  const handleChange = useCallback(
    (key: string, value: string | boolean) => {
      debouncedRequest({ [key]: value });
    },
    [debouncedRequest],
  );

  const handleWorkPackageModal = useCallback(
    async (workPackageId?: number) => {
      try {
        if (workPackageId && !Number.isNaN(workPackageId)) {
          const { data: currentWorkPackage } =
            await workPackageApi.workPackagesControllerFindOne(workPackageId);
          if (currentWorkPackage)
            activeProcess.setCurrentWorkPackage(currentWorkPackage);
        } else {
          activeProcess.setCurrentWorkPackage();
        }
      } catch (e) {
        console.warn(e);
      }

      if (isImplementedBtnVisible) {
        toggleWorpackageViewModalVisible(!isWorpackageViewModalVisible);
      } else {
        toggleWorpackageModalVisible(!isWorpackageModalVisible);
      }

      // check if workpackage complete
      if (
        change?.status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
        change?.status ===
          UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress
      ) {
        const { data: newWorkPackages } =
          await workPackageApi.workPackagesControllerFindAll(undefined, [
            Number(changeId),
          ]);

        const allWorkPackagesCompleted = newWorkPackages.every(
          wp => wp.status === 'IMPLEMENTED',
        );

        if (allWorkPackagesCompleted) {
          await changeApi.changesControllerUpdate(Number(changeId), {
            ...change,
            status: UpdateChangeDtoStatusEnum.Check,
          });

          toggleIsLoading();
          navigate('/');
        }
      }
    },
    [
      isImplementedBtnVisible,
      workPackageApi,
      changeId,
      activeProcess,
      isWorpackageViewModalVisible,
      isWorpackageModalVisible,
      changeApi,
      change,
      toggleIsLoading,
      navigate,
    ],
  );

  const handleProcessExpand = useCallback(
    (pId: number) => {
      if (pId === activeProcess.process?.id) {
        activeProcess.resetProcess();
      } else {
        const currentProcess = findCurrentProcess(Number(pId));
        if (currentProcess) activeProcess.setProcess(currentProcess);
      }
    },
    [activeProcess, findCurrentProcess],
  );

  const handleUpdateAllWorkPackages = useCallback(async () => {
    try {
      toggleIsLoading();
      const responses = reducedWorkPackages[Number(processId)].map(
        async workPackage => {
          const { data: updatedWorkPackage } =
            await workPackageApi.workPackagesControllerUpdate(workPackage.id, {
              status: UpdateWorkPackageDtoStatusEnum.Implemented,
            });
          replaceUpdatedWorkPackage({
            ...updatedWorkPackage,
            hasAttachments: workPackage.hasAttachments,
          });
        },
      );

      if (!!responses.length && changeId) {
        const arrResponses = await Promise.all(responses);
        if (arrResponses) {
          try {
            setIsDataLoading(true);
            const { data: newWorkPackages } =
              await workPackageApi.workPackagesControllerFindAll(undefined, [
                Number(changeId),
              ]);

            const allWorkPackagesCompleted = newWorkPackages.every(
              wp => wp.status === 'IMPLEMENTED',
            );

            if (allWorkPackagesCompleted) {
              await changeApi.changesControllerUpdate(Number(changeId), {
                ...change,
                status: UpdateChangeDtoStatusEnum.Check,
              });

              toggleIsLoading();
              navigate('/');
            }

            if (workPackages) {
              setIsDataLoading(false);
              setWorkPackages(workPackages);
            }
          } catch (e) {
            setIsDataLoading(false);
            console.error(e);
          }

          toggleIsLoading();
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.workPackageAllStatusesUpdate,
          });
        }
      }
    } catch (e) {
      setNotification({
        type: NotificationTypes.error,
        message: NotificationMessage.error,
      });
      toggleIsLoading();
      console.error(e);
    }
  }, [
    toggleIsLoading,
    reducedWorkPackages,
    processId,
    changeId,
    workPackageApi,
    replaceUpdatedWorkPackage,
    setNotification,
    NotificationMessage.workPackageAllStatusesUpdate,
    NotificationMessage.error,
    setIsDataLoading,
    workPackages,
    changeApi,
    change,
    navigate,
    setWorkPackages,
  ]);

  return (
    <Grid
      container
      direction="column"
      css={css`
        display: flex;
        padding: 19px 0px 12px 0px;
      `}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 3,
        }}
      >
        <Typography
          variant="body1"
          color="primary"
          css={css`
            background: #ececec;
            color: #333333;
            padding: 8px;
            font-size: 12px;
            max-width: 308px;
          `}
        >
          {t('EvalRes', {
            date: new Date(
              change?.evaluationTargetDate || '',
            ).toLocaleDateString(),
          })}
        </Typography>
        {change && showButtonGroup && (
          <ButtonsGroup
            isChangeExist={!!change}
            changeStatus={change?.status || ''}
            workPackages={workPackages}
            change={change}
            onClickBtn={e =>
              setModalType(e.currentTarget.dataset.type as DialogType)
            }
          />
        )}
      </Box>

      {isDataLoading && <LoadSpinner />}

      {!isDataLoading &&
        processes &&
        processes.map(process => (
          <ProcessAccordionItem
            change={change}
            changeId={changeId}
            key={process.id}
            process={process}
            isExpanded={process.id.toString() === processId}
            workPackages={reducedWorkPackages[process.id] || []}
            isImplementedBtnVisible={isImplementedBtnVisible}
            isLoading={isLoading}
            handleWorkPackageModal={handleWorkPackageModal}
            handleChange={handleChange}
            handleProcessExpand={handleProcessExpand}
            handleUpdateAllWorkPackages={handleUpdateAllWorkPackages}
          />
        ))}

      {isWorpackageModalVisible && (
        <WorkPackageModal
          change={change}
          currentProcess={activeProcess.process}
          currentWorkPackage={activeProcess.currentWorkPackage}
          isOpen={isWorpackageModalVisible}
          handleClose={handleWorkPackageModal}
          pushNewWorkPackage={pushNewWorkPackage}
          replaceUpdatedWorkPackage={replaceUpdatedWorkPackage}
          removeWorkPackage={removeWorkPackage}
          setNotification={setNotification}
        />
      )}
      <ChangeStatusDialogWrapper
        modalType={modalType}
        setModalType={m => setModalType(m)}
      />

      {isWorpackageViewModalVisible && activeProcess.currentWorkPackage && (
        <WorkPackageViewModal
          currentWorkPackage={activeProcess.currentWorkPackage}
          currentProcess={activeProcess.process}
          isOpen={isWorpackageViewModalVisible}
          handleClose={handleWorkPackageModal}
          replaceUpdatedWorkPackage={replaceUpdatedWorkPackage}
          setNotification={setNotification}
          change={change}
        />
      )}
    </Grid>
  );
});

export default ProjectEditResourcesGroupPage;
