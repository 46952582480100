/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Avatar, Box, CardHeader, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import {
  ListWorkPackageDto,
  ProcessListForChangeDto,
  ProcessProjectForChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import ProcessItemInfo from './ProcessItemInfo';
import { stringAvatar, stringToColor } from '../../utils/utils';
import { useUserApiFactory } from '../../ApiClient';

export type ProcessItemProps = {
  workPackages: ListWorkPackageDto[];
  process: ProcessListForChangeDto;
  department: ProcessProjectForChangeDto | undefined;
  onProcessClick: (processId: number) => void;
};

const ProcessItem = observer(
  ({ workPackages, process, onProcessClick, department }: ProcessItemProps) => {
    const { calculatedHours, timeImpact } = process;

    const userApi = useUserApiFactory();

    const [userName, setuserName] = useState('');

    const handleClick = useCallback(() => {
      onProcessClick(process.id);
    }, [process, onProcessClick]);

    useEffect(() => {
      (async () => {
        try {
          let user = null;

          if (department?.temporaryCoreTeamMember) {
            user = department?.temporaryCoreTeamMember.user.displayName;
            setuserName(user);
          } else {
            user = department?.owner;

            const { data: currentUser } = await userApi.usersControllerFindOne(
              user ?? '',
            );
            setuserName(currentUser.displayName);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }, [
      department?.owner,
      department?.temporaryCoreTeamMember,
      process,
      userApi,
    ]);

    return (
      <Box
        onClick={handleClick}
        css={css`
          display: flex;
          flex-direction: row;
          padding: 16px 24px;
          border: 1px solid #ececec;
          box-shadow: 0px 3px 6px #ececec;
          margin-bottom: 24px;
          align-items: center;
          justify-content: space-between;
          &:hover {
            cursor: pointer;
          }
        `}
      >
        <Box>
          <CardHeader
            css={css`
              display: flex;
              padding-bottom: 16px;
              text-transform: uppercase;
              width: max-content;
              margin-right: auto;
              padding-top: 0px;
              padding-left: 0px;

              max-width: 100%;
            `}
            title={department?.name || ''}
            titleTypographyProps={{
              variant: 'h4',
              component: 'h4',
            }}
          />
          <ProcessItemInfo
            workPackages={workPackages || []}
            calculatedHours={calculatedHours}
            timeImpact={timeImpact}
            process={process}
          />
        </Box>
        <Tooltip title={userName}>
          <Avatar
            sx={{
              width: '30px',
              height: '30px',
              bgcolor: stringToColor(userName),
              marginLeft: 3,
            }}
          >
            {stringAvatar(userName)}
          </Avatar>
        </Tooltip>
      </Box>
    );
  },
);

export default ProcessItem;
