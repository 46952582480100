/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Button,
  Box,
  Popover,
  Typography,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Theme, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { TaskCardLocalizationList } from '../../constants/changes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const enumToArray = (enumme: { [key: string]: string }) =>
  Object.keys(enumme).map(key => enumme[key]);

const getStyles = (
  name: string,
  personName: readonly string[],
  theme: Theme,
) => ({
  fontWeight:
    personName.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

type ChangeFilterProps = {
  filteredStatuses: string[];
  setFilteredStatuses: (statuses: string[]) => void;
  possibleStatusList?: string[] | undefined;
};

const ChangeFilter = observer(
  ({
    filteredStatuses,
    setFilteredStatuses,
    possibleStatusList,
  }: ChangeFilterProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const TaskCardLocalization = TaskCardLocalizationList();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    // const [filteredStatuses, setFilteredStatuses] = useState<string[]>([]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const statuses =
      possibleStatusList || enumToArray(UpdateChangeDtoStatusEnum);

    const handleChange = (
      event: SelectChangeEvent<typeof filteredStatuses>,
    ) => {
      const {
        target: { value },
      } = event;

      setFilteredStatuses(typeof value === 'string' ? value.split(',') : value);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box>
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          css={css`
            margin-top: auto;
          `}
        >
          {t('filter')}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          css={css`
            margin-top: 8px;
          `}
        >
          <Typography component="span" sx={{ p: 2 }}>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Filtern</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={filteredStatuses}
                onChange={handleChange}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Filtern" />
                }
                renderValue={selected => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                    }}
                  >
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={
                          TaskCardLocalization[
                            value as UpdateChangeDtoStatusEnum
                          ]
                        }
                        color="primary"
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {statuses.map(status => (
                  <MenuItem
                    key={status}
                    value={status}
                    style={getStyles(
                      status,
                      statuses.map(st => st),
                      theme,
                    )}
                  >
                    {TaskCardLocalization[status as UpdateChangeDtoStatusEnum]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
        </Popover>
      </Box>
    );
  },
);

export default ChangeFilter;
