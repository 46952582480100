/* eslint-disable react/require-default-props */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Box,
} from '@mui/material';
import ChangeFilter from '../ChangeFilter/ChangeFilter';

type SectionProps = {
  Title: string;
  Controls?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  search: boolean;
  allPossibleStatus?: string[];
  filteredStatuses?: string[];
  onSearch?: (search: string, statuses?: string[]) => void;
};

const Section = observer(
  ({
    Title,
    children,
    Controls,
    onClick,
    search,
    onSearch,
    allPossibleStatus,
    filteredStatuses,
    ...props
  }: SectionProps): JSX.Element => {
    const { t } = useTranslation();

    const [searchString, setSearchString] = useState('');

    return (
      <Card
        elevation={4}
        css={css`
          padding: 24px;
          background-color: white;
          position: relative;
          border-radius: 0px;

          @media (max-width: 810px) {
            max-width: 100%;
          }
        `}
        onClick={onClick}
        {...props}
      >
        <CardHeader
          css={css`
            display: flex;
            padding-bottom: 16px;
            text-transform: uppercase;
            width: max-content;
            margin-right: auto;
            padding-top: 0px;
            padding-left: 0px;

            max-width: 100%;
          `}
          title={Title}
          titleTypographyProps={{
            variant: 'h4',
            component: 'h4',
          }}
        />

        <CardContent sx={{ padding: '0px !important' }}>
          <Grid container>
            <Grid
              item
              css={css`
                position: absolute;
                top: 24px;
                right: 24px;
              `}
            >
              {Controls}
              <Box sx={{ display: 'flex' }}>
                {search && onSearch && (
                  <TextField
                    onChange={e => {
                      setSearchString(e.target.value);
                      onSearch(e.target.value);
                    }}
                    label={t('search')}
                    id="outlined-basic"
                    variant="standard"
                  />
                )}
                {onSearch && (
                  <ChangeFilter
                    possibleStatusList={allPossibleStatus}
                    filteredStatuses={filteredStatuses || []}
                    setFilteredStatuses={statuses =>
                      onSearch(searchString, statuses)
                    }
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              css={css`
                width: 100%;
              `}
            >
              {children}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  },
);

export default Section;
