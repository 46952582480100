/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  GetProjectDto,
  ListUserDto,
  TemporaryUserAssignmentDto,
  UpdateProjectDto,
  UserBaseInfoDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useParams } from 'react-router';

import EditButton from '@mui/icons-material/ModeEdit';

import { RemoveCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { endOfDay } from 'date-fns';
import { useStorex } from '../../store/UIStore';
import { useProjectApiFactory } from '../../ApiClient';

import AddUserListDialog from '../../components/Dialog/AddUserListDialog';
import { UserListTypes } from '../../types/users';
import { isAdmin } from '../../utils/permissionHelper';
import { useProjectStore } from '../../store/projectStore';

const ProjectsEditRolePage = observer(() => {
  const store = useStorex();
  const projectsApi = useProjectApiFactory();
  const [openUserList, setOpenUserList] = useState(false);
  const [userType, setUserType] = useState(UserListTypes.ChangeManager);
  const [currentUser, setCurrentUser] = useState<ListUserDto>();
  const { t } = useTranslation();

  const auth = useAuth();
  const userName = auth.user?.profile.email?.toLocaleLowerCase();

  const { setNotification } = useStorex();

  const { projectId } = useParams();
  const { project } = useProjectStore();

  const currentProject = useLocalObservable(() => ({
    project: project ?? ({} as GetProjectDto),
    get temporaryChangeManager() {
      return this.project.temporaryChangeManagerAssignment;
    },
    setCurrentProject(p: GetProjectDto) {
      this.project = p;
    },
    setTemporaryChangeManager(assignment: TemporaryUserAssignmentDto | null) {
      this.project.temporaryChangeManagerAssignment = assignment;
    },
  }));

  useEffect(() => {
    if (store.sidebar) {
      store.toggleSidebar();
    }
  }, [store]);

  useEffect(() => {
    if (project) currentProject.setCurrentProject(project);
  }, [currentProject, project]);

  const handleUserfromModal = async (
    type: UserListTypes,
    user: ListUserDto | UserBaseInfoDto | undefined,
    expiry?: string,
  ) => {
    if (user && projectId) {
      if (type === UserListTypes.TemporaryChangeManager) {
        if (user && projectId) {
          let newExpiry: string | undefined = expiry;
          if (expiry) {
            newExpiry = endOfDay(new Date(expiry)).toString();
          }
          const temporaryChangeManagerAssignment: TemporaryUserAssignmentDto = {
            userId: user.id,
            expiresTimestamp: newExpiry,
          };

          const temporaryChangeManager =
            await projectsApi.projectsControllerSetTemporaryChangeManager(
              Number(projectId),
              temporaryChangeManagerAssignment,
            );
          const { data: temporaryChangeManagerData } = temporaryChangeManager;
          currentProject.setTemporaryChangeManager(temporaryChangeManagerData);
        }
      }
      if (type === UserListTypes.ChangeManager) {
        const newCMData: UpdateProjectDto = {
          changeManager: user.id,
        };
        const { data: newChangeManager } =
          await projectsApi.projectsControllerUpdate(
            Number(projectId),
            newCMData,
          );
        currentProject.project.changeManager = newChangeManager.changeManager;
      }
    }
    setOpenUserList(false);
  };

  const removeTemporaryChangeManager = async () => {
    await projectsApi.projectsControllerRemoveTemporaryChangeManager(
      Number(projectId),
    );
    currentProject.setTemporaryChangeManager(null);
  };

  const openUserModal = (
    type: UserListTypes,
    user: ListUserDto | undefined,
  ) => {
    setUserType(type);
    setCurrentUser(user);
    setOpenUserList(true);
  };

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <Typography variant="h4">{t('role_overview')}</Typography>

      {currentProject.project && (
        <Box>
          <FormControl sx={{ m: 1, width: '75ch' }} variant="outlined">
            <FormLabel sx={{ fontWeight: 'bold' }}>Change Manager</FormLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={currentProject.project.changeManager}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title={t('edit').toString()}>
                    <IconButton
                      disabled={!isAdmin()}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        openUserModal(UserListTypes.ChangeManager, {
                          id: currentProject.project.changeManager ?? '',
                          displayName:
                            currentProject.project.changeManager ?? '',
                          isInternal: false,
                          isActive: false,
                        })
                      }
                      edge="end"
                    >
                      <EditButton />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              label="Change Manager"
            />
          </FormControl>

          <br />
          <Grid container>
            <Grid item>
              <FormControl sx={{ m: 1, width: '75ch' }} variant="outlined">
                <FormLabel
                  sx={{ fontWeight: 'bold' }}
                  htmlFor="outlined-adornment-password"
                >
                  {t('temporayCMSet')}
                </FormLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  value={currentProject.temporaryChangeManager?.userId ?? ''}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title={t('edit').toString()}>
                        <IconButton
                          disabled={
                            !isAdmin() &&
                            currentProject.project.changeManager.toLowerCase() !==
                              userName
                          }
                          aria-label="toggle password visibility"
                          onClick={() =>
                            openUserModal(
                              UserListTypes.TemporaryChangeManager,
                              {
                                id:
                                  currentProject.project
                                    .temporaryChangeManagerAssignment?.userId ??
                                  '',
                                displayName:
                                  currentProject.project
                                    .temporaryChangeManagerAssignment?.userId ??
                                  '',
                                isInternal: false,
                                isActive: false,
                              },
                            )
                          }
                          edge="end"
                        >
                          <EditButton />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  label="Temporärer Change Manager"
                />
              </FormControl>
            </Grid>
            <Grid sx={{ alignSelf: 'center', paddingTop: '20px' }}>
              <Tooltip title={t('delete').toString()}>
                <IconButton
                  disabled={
                    !isAdmin() &&
                    currentProject.project.changeManager.toLowerCase() !==
                      userName
                  }
                  onClick={removeTemporaryChangeManager}
                  aria-label="delete"
                  color="primary"
                >
                  <RemoveCircle />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      )}
      {openUserList && (
        <AddUserListDialog
          isOpen={openUserList}
          userType={userType}
          temporaryUser={
            userType === UserListTypes.TemporaryChangeManager
              ? currentProject.project.temporaryChangeManagerAssignment
              : null
          }
          currentUser={currentUser}
          currentProject={currentProject.project}
          handleClose={(
            type: UserListTypes,
            user: ListUserDto | UserBaseInfoDto | undefined,
            expiry?: string,
          ) => handleUserfromModal(type, user, expiry)}
          setNotification={setNotification}
        />
      )}
    </Box>
  );
});

export default ProjectsEditRolePage;
