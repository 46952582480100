import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { GetChangeDto } from '@codefluegel/zeta-change-typescript-client';
import { Grid, ListItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const DateViewDialog = observer(
  ({
    onClose,
    open,
    change,
    project,
  }: {
    onClose: () => void;
    open: boolean;
    change: GetChangeDto | undefined;
    project: string;
  }) => {
    const { t } = useTranslation();

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ minWidth: 500 }}
      >
        <DialogTitle
          sx={{ fontSize: 14 }}
        >{`Projekt ${project} - TERMINE`}</DialogTitle>

        <IconButton
          sx={{ position: 'absolute', right: 5, top: 5 }}
          color="primary"
          component="span"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        {change ? (
          <List>
            <ListItem alignItems="flex-start">
              <Grid container>
                <Grid sx={{ minWidth: 250, fontWeight: 'bold' }} item>
                  vom Kunden angenommen am:{' '}
                </Grid>
                <Grid item>
                  {change.approvalDateClient &&
                    new Date(change.approvalDateClient).toLocaleDateString()}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid container>
                <Grid sx={{ minWidth: 250, fontWeight: 'bold' }} item>
                  Intern angenommen am:{' '}
                </Grid>
                <Grid item>
                  {change.internalApprovalDate &&
                    new Date(change.internalApprovalDate).toLocaleDateString()}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid container>
                <Grid sx={{ minWidth: 250, fontWeight: 'bold' }} item>
                  {t('evalDate')}
                </Grid>
                <Grid item>
                  {change.evaluationTargetDate &&
                    new Date(change.evaluationTargetDate).toLocaleDateString()}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid container>
                <Grid sx={{ minWidth: 250, fontWeight: 'bold' }} item>
                  zu implementiern bis:{' '}
                </Grid>
                <Grid item>
                  {change.implementationTargetDate &&
                    new Date(
                      change.implementationTargetDate,
                    ).toLocaleDateString()}
                </Grid>
              </Grid>
            </ListItem>
          </List>
        ) : (
          <p>no data</p>
        )}
      </Dialog>
    );
  },
);

export default DateViewDialog;
