import { makeAutoObservable } from 'mobx';
import React, { createContext, useContext } from 'react';
import { ListUserDto } from '@codefluegel/zeta-change-typescript-client';

export interface IUserStore {
  users: ListUserDto[];
  setUsers: (project: ListUserDto[]) => void;
}

export class UserStore implements IUserStore {
  constructor() {
    makeAutoObservable(this);
  }

  users: ListUserDto[] = [];

  setUsers = (users: ListUserDto[]) => {
    this.users = users;
  };
}

const initialChangeStore = new UserStore();

export const StoreContext = createContext<IUserStore>(initialChangeStore);

export type StoreProviderProps = {
  children: React.ReactNode;
};

export const useUserStore = (): IUserStore => useContext(StoreContext);
