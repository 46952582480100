/* eslint-disable react/jsx-fragments */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { observer } from 'mobx-react-lite';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, ListItemButton, Typography } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { ListProjectDto } from '@codefluegel/zeta-change-typescript-client';
import { useStorex } from '../../store/UIStore';
import { getRole } from '../../utils/permissionHelper';

const drawerWidth = 236;

const ProjectSidebarDrawer = observer(
  ({ project }: { project: ListProjectDto | undefined }) => {
    const store = useStorex();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { projectId } = useParams();

    const role = getRole(project);

    const { t } = useTranslation();

    return (
      <Fragment>
        <IconButton
          sx={{
            transform: 'translateX(-272px)',
          }}
          size="small"
          css={css`
            position: absolute;
            top: 72px;
            left: 224px;
            z-index: 1201;
            padding: 0 !important;
            background: #ffffff;

            box-shadow: 0px 1px 3px #00000029;
            width: max-content;
            margin-left: auto;

            transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          `}
          onClick={store.toggleAdminSidebar}
        >
          <ChevronLeftIcon color="primary" />
        </IconButton>

        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          <Drawer
            PaperProps={{
              elevation: 3,
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                top: '45px',
                height: 'calc(100% - 45px)',
                backgroundColor: 'white',
                border: 'none',
              },
              flexGrow: 1,
              bgcolor: 'white',
              p: 3,
            }}
            variant="persistent"
            anchor="left"
            open={store.adminSidebar}
          >
            <Toolbar
              css={css`
                padding-right: 0px !important;
              `}
            >
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  padding-top: 8px;
                `}
              >
                <Typography variant="h4">
                  {t('projectadminarea')}
                  <span> - {project?.number ?? ''}</span>
                </Typography>
                <Typography variant="body2">{`${t('role')}: ${t(
                  role,
                )}`}</Typography>
              </Box>
            </Toolbar>

            <List
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding-top: 32px;
                font-size: 12px;
                & .MuiListItemButton-root {
                  padding-left: 24px;
                }
              `}
            >
              <ListItemButton
                css={css`
                  background-color: ${pathname.includes('processes')
                    ? 'rgba(0, 0, 0, 0.05)'
                    : 'none'};
                `}
                onClick={() =>
                  !pathname.includes('resources') &&
                  navigate(`/projects/${projectId}/processes`)
                }
              >
                {t('process_overview')}
              </ListItemButton>
              {role !== 'KTM' && (
                <React.Fragment>
                  <ListItemButton
                    css={css`
                      background-color: ${pathname.includes('edit-role')
                        ? 'rgba(0, 0, 0, 0.05)'
                        : 'none'};
                    `}
                    onClick={() => navigate(`/projects/${projectId}/edit-role`)}
                  >
                    {t('deputyCM')}
                  </ListItemButton>
                  <ListItemButton
                    css={css`
                      background-color: ${pathname.includes('project-viewer')
                        ? 'rgba(0, 0, 0, 0.05)'
                        : 'none'};
                    `}
                    onClick={() =>
                      navigate(`/projects/${projectId}/project-viewer`)
                    }
                  >
                    {t('viewer_list')}
                  </ListItemButton>
                  <ListItemButton
                    css={css`
                      background-color: ${pathname.includes('dashboard')
                        ? 'rgba(0, 0, 0, 0.05)'
                        : 'none'};
                    `}
                    onClick={() => navigate(`/projects/${projectId}/dashboard`)}
                  >
                    Dashboard
                  </ListItemButton>
                </React.Fragment>
              )}
            </List>
          </Drawer>
        </Box>
      </Fragment>
    );
  },
);

export default ProjectSidebarDrawer;
