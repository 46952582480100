/* eslint-disable react/jsx-fragments */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { observer } from 'mobx-react-lite';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

/**
 * The internal dependencies
 */
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import { useStorex } from '../../store/UIStore';
import { useChangeStore } from '../../store/changeStore';
import {
  STATUSES_FOR_CALC,
  EXCLUDED_STATUSES_FOR_RESOURCES,
  STATUSES_FOR_EXTERN,
  STATUSES_FOR_IMPLEMENTATION,
} from '../../constants/statusesForChecking';
import useMedia from '../../hooks/useMedia';
import MainMenuList from '../Lists/MainMenuList';

const drawerWidth = 236;

const SidebarDrawer = observer(() => {
  const store = useStorex();
  const { change } = useChangeStore();
  const { pathname } = useLocation();
  const { changeId } = useParams();
  const isCalcVisible =
    change && change.status && STATUSES_FOR_CALC.includes(change.status);

  const isResourcesVisible =
    change &&
    change.status &&
    !EXCLUDED_STATUSES_FOR_RESOURCES.includes(change.status);
  const isExternVisible =
    change && change.status && STATUSES_FOR_EXTERN.includes(change.status);
  const isImplementation =
    change &&
    change.status &&
    STATUSES_FOR_IMPLEMENTATION.includes(change.status);

  const { t } = useTranslation();

  const isMobile = useMedia('sm');

  return (
    <Fragment>
      {isMobile ? (
        <MainMenuList
          changeId={changeId ?? ''}
          pathname={pathname}
          isCalcVisible={isCalcVisible}
          isResourcesVisible={isResourcesVisible}
          isExternVisible={isExternVisible}
          isImplementationVisible={isImplementation}
        />
      ) : (
        <Fragment>
          <IconButton
            sx={{
              transform: store.sidebar ? 'none' : 'translateX(-272px)',
            }}
            size="small"
            css={css`
              position: absolute;
              top: 72px;
              left: 224px;
              z-index: 1201;
              padding: 0 !important;
              background: #ffffff;

              box-shadow: 0px 1px 3px #00000029;
              width: max-content;
              margin-left: auto;

              transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            `}
            onClick={store.toggleSidebar}
          >
            <ChevronLeftIcon color="primary" />
          </IconButton>

          <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <Drawer
              PaperProps={{
                elevation: 3,
              }}
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                  top: '45px',
                  height: 'calc(100% - 45px)',
                  backgroundColor: 'white',
                  border: 'none',
                },
                flexGrow: 1,
                bgcolor: 'white',
                p: 3,
              }}
              variant="persistent"
              anchor="left"
              open={store.sidebar}
            >
              <Toolbar
                css={css`
                  padding-right: 0px !important;
                `}
              >
                <Box
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    padding-top: 8px;
                  `}
                >
                  <Typography variant="h4">
                    {change?.status === UpdateChangeDtoStatusEnum.Draft ||
                    !change
                      ? t('newChange')
                      : `${change?.changeNo || change.commonProject?.number}`}
                  </Typography>

                  <Typography sx={{ wordBreak: 'break-all' }} variant="body2">
                    {change?.title}
                  </Typography>
                </Box>
              </Toolbar>
              <MainMenuList
                changeId={changeId ?? ''}
                pathname={pathname}
                isCalcVisible={isCalcVisible}
                isResourcesVisible={isResourcesVisible}
                isExternVisible={isExternVisible}
                isImplementationVisible={isImplementation}
              />
            </Drawer>
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
});

export default SidebarDrawer;
