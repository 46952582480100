/* eslint-disable @typescript-eslint/no-unused-expressions */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  CreateChangeDto,
  GetChangeDto,
  GetProjectDto,
  ListProjectDto,
  UpdateChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
  Grid,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import ProjectEditGeneralPage from './ProjectEditGeneralPage';
import ProjectEditCustomerPage from './ProjectEditCustomerPage';
import ProjectEditAttachmentPage from './ProjectEditAttachmentPage';
import {
  useChangeApiFactory,
  useProcessApiFactory,
  useProjectApiFactory,
} from '../../ApiClient';
import { ChangesAllProjectsType, DialogType } from '../../types/changes';
import ProjectEditResourcePage from './ProjectEditResourcePage';
import { useChangeStore } from '../../store/changeStore';
import useLoading from '../../utils/useLoading';
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import NotificationMessages from '../../constants/notification';
import { NotificationTypes } from '../../types/notification';
import { useStorex } from '../../store/UIStore';
import {
  checkIfRejected,
  disableInput,
  isChangeManager,
  isNewReworkDraft,
  isNotDraftOrReworkStatus,
  userIsProjectViewer,
} from '../../utils/permissionHelper';
import ButtonsGroup from '../../components/ButtonsGroup/ButtonsGroup';
import ChangeStatusDialogWrapper from '../../components/Dialog/ChangeStatusDialogWrapper';
import { useProjectStore } from '../../store/projectStore';
import InformationConfirmDialog from '../../components/Dialog/InformationConfirmDialog';

const CreateChangePage = observer(() => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openReworkDialog, setShowReworkDialog] = useState('');
  const [message, setErrorMessage] = useState('');
  const [saveAsDraft, setSaveAsDraft] = useState(true);
  const { change, isDataLoading, setIsDataLoading, setChange, workPackages } =
    useChangeStore();
  const { setNotification } = useStorex();
  const { changeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, toggleIsLoading } = useLoading();

  const NotificationMessage = NotificationMessages();

  const {
    control,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    getFieldState,
    reset,
  } = useForm<CreateChangeDto & UpdateChangeDto>({
    mode: 'onChange',
  });

  const changeApi = useChangeApiFactory();
  const projectApi = useProjectApiFactory();
  const processApi = useProcessApiFactory();

  const auth = useAuth();

  const { t } = useTranslation();
  const { projects } = useProjectStore();

  const lang = localStorage.getItem('i18nextLng') || 'de';

  const isOnlyProjectViewer = userIsProjectViewer(change);
  const showButtonGroup =
    change?.status === UpdateChangeDtoStatusEnum.New ||
    change?.status === UpdateChangeDtoStatusEnum.NewRework ||
    change?.status === UpdateChangeDtoStatusEnum.Draft;

  const [modalType, setModalType] = useState<DialogType | null>(null);
  const [temporaryModalType, setTemporaryModalType] =
    useState<DialogType | null>(null);
  const [redirect, setRedirect] = useState(true);

  const allProjects = useLocalObservable<ChangesAllProjectsType>(() => ({
    all: [],
    selected: undefined,
    setSelectedProject: (projectId: string | number) => {
      if (allProjects.all) {
        const project = allProjects.all?.find((proj: GetProjectDto) => {
          if (typeof projectId === 'string') {
            return proj.number === projectId;
          }
          return proj.id === projectId;
        });

        allProjects.selected = project;
      }
    },
    setAllProjects: (p: ListProjectDto[]) => {
      allProjects.all = p;
    },
  }));

  const isAsManager = isChangeManager(change);

  const permissionToEdit = disableInput(change);

  const isRejected = checkIfRejected(change);

  const isNewChangePage = location.pathname.includes('new');
  const isNotDraft = isNotDraftOrReworkStatus(change);
  const projectProcesses =
    change?.commonProject?.navProcessToCommonProjectRelations || [];
  const changeProcesses = change?.processes || [];

  useEffect(() => {
    if (change && changeId) {
      reset({
        title: change.title as string | undefined,
        projectId: change.projectId as number | undefined,
        changeNoClient: change.changeNoClient || '',
        system: change.system || '',
        asIsSituation: change.asIsSituation || '',
        solutionProposal: change.solutionProposal || '',
        alternativProposals: change.alternativProposals || '',
        projectManagerClient: change.projectManagerClient || '',
        submisionCRByClient:
          change.submisionCRByClient || new Date().toISOString(),
        introducedBy: change.introducedBy || '',
        initiator: change.initiator || '',
        reasonForChange: change.reasonForChange || '',
        preSelectInternalChange: change.preSelectInternalChange,
        sharepointLink: change.sharepointLink || '',
      });
    }
  }, [change, changeId, reset]);

  useEffect(() => {
    (async () => {
      try {
        if (change || isNewChangePage) {
          setIsDataLoading(true);
          if (projects) {
            const user = auth.user?.profile.email?.toLocaleLowerCase();

            const mergeResult = [
              ...projects
                .filter(
                  proj =>
                    proj.changeManager.toLocaleLowerCase() === user ||
                    proj.temporaryChangeManagerAssignment?.userId.toLocaleLowerCase() ===
                      user,
                )
                .map(proj => proj.id),
              ...projects.map(project => project.id),
            ];

            const result = projects.filter(project =>
              mergeResult.includes(project.id),
            );

            allProjects.setAllProjects(result);
          }

          if (changeId && change) {
            if (change.projectId && !!allProjects.all.length) {
              allProjects.setSelectedProject(change.projectId);
            }
          }
          setIsDataLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    allProjects,
    changeId,
    change,
    isNewChangePage,
    setIsDataLoading,
    projectApi,
    auth.user?.profile.email,
    projects,
  ]);

  const onSubmit: SubmitHandler<
    CreateChangeDto & UpdateChangeDto
  > = async data => {
    try {
      let response;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isInternal = data.preSelectInternalChange !== 'false';

      const request: CreateChangeDto & UpdateChangeDto = {
        ...data,
        projectId: allProjects.selected?.id ?? 0,
      };
      if (getFieldState('preSelectInternalChange').isDirty) {
        request.preSelectInternalChange = isInternal;
      }

      if (isAsManager && isNotDraft) {
        request.status = UpdateChangeDtoStatusEnum.InEvaluation;
      }

      toggleIsLoading();

      if (changeId && change && change.status) {
        response = await changeApi.changesControllerUpdate(Number(changeId), {
          ...request,
          evaluationTargetDate: new Date().toISOString(),
          status: change.status as UpdateChangeDtoStatusEnum,
        });
        const updatedCR = response.data;

        const updatedChangeRequest: GetChangeDto = {
          ...updatedCR,
          changeRequestToProductGroupRelations:
            change.changeRequestToProductGroupRelations,
          processes: change.processes,
          commonProject: change.commonProject,
          temporaryChangeManager: change.temporaryChangeManager,
        };
        setChange(updatedChangeRequest);

        response &&
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.chagneUpdate,
          });
      } else {
        response = await changeApi.changesControllerCreate(request);

        const { data: newChange } = response;

        if (!saveAsDraft) {
          response = await changeApi.changesControllerUpdate(
            Number(newChange.id),
            {
              status: UpdateChangeDtoStatusEnum.New,
            },
          );
        }

        newChange &&
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.changeCreate,
          });
      }

      toggleIsLoading();
      if (redirect) response && response.data && navigate('/');
      setRedirect(true);
    } catch (e) {
      setNotification({
        type: NotificationTypes.error,
        message: NotificationMessage.error,
      });
      toggleIsLoading();
      console.error(e);
    }
  };

  const handleSwitchTab = (
    event: SyntheticEvent<Element, Event>,
    newValue: SetStateAction<number>,
  ) => {
    setTabIndex(newValue);
  };

  const handleCheckbox = useCallback(
    async (prProcess: string) => {
      if (change?.status === UpdateChangeDtoStatusEnum.EvalComplete) {
        setShowReworkDialog(prProcess);
        return;
      }

      if (prProcess && change) {
        const department =
          change.commonProject?.navProcessToCommonProjectRelations.find(
            process => process.groupNo === prProcess,
          );

        const isProcessExist = change.processes.find(
          pr => department && pr.pId === department?.id,
        );

        if (isProcessExist) {
          try {
            if (department) {
              await processApi.processesControllerRemove(
                Number(changeId),
                department.groupNo.toString(),
              );
              const processes = change.processes.filter(
                process => process.pId !== department.id,
              );
              change.processes = processes;
              setChange(change);
            }
          } catch (e: any) {
            setNotification({
              type: NotificationTypes.error,
              message: e.response.data.message || NotificationMessage.error,
            });
          }
        } else {
          try {
            const { data: addedProcess } =
              await processApi.processesControllerCreate({
                changeRequestId: change.id,
                departmentId: prProcess,
              });

            if (addedProcess) {
              change.processes.push(addedProcess);
              setChange(change);
            }
          } catch (e: any) {
            setNotification({
              type: NotificationTypes.error,
              message: e.response.data.message || NotificationMessage.error,
            });
          }
        }
        setShowReworkDialog('');
      }
    },
    [
      change,
      processApi,
      changeId,
      setChange,
      setNotification,
      NotificationMessage.error,
    ],
  );

  const changeToReworkAndCheck = async () => {
    if (changeId && change && change.status) {
      const response = await changeApi.changesControllerUpdate(
        Number(changeId),
        {
          status: UpdateChangeDtoStatusEnum.EvalRework,
          rejectionComment: t('statusReworkChanged'),
        },
      );
      change.status = UpdateChangeDtoStatusEnum.EvalRework;

      setChange(change);

      response &&
        setNotification({
          type: NotificationTypes.success,
          message: NotificationMessage.chagneUpdate,
        });
      handleCheckbox(openReworkDialog);
    }
  };

  const saveDataAndCloseDialog = () => {
    setRedirect(false);
    handleSubmit(onSubmit)();

    setOpenSaveDialog(false);

    setModalType(temporaryModalType);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isDataLoading && <LoadSpinner />}

      {!isDataLoading && (
        <Grid
          container
          direction="row"
          css={css`
            justify-content: space-between;
            margin-top: 13px;
          `}
        >
          <Grid item xs={12} sm={7} lg={8}>
            <Tabs
              css={css`
                .MuiTabs-flexContainer {
                  gap: 17px;
                }
              `}
              value={tabIndex}
              variant="scrollable"
              aria-label="changeTabs"
              onChange={handleSwitchTab}
            >
              <Tab
                label={t('general')}
                css={css`
                  font-size: 18px;
                  font-weight: 500;
                  padding: 14px 25px;
                  color: #00223b;
                `}
              />
              {isAsManager && isNotDraft && (
                <Tab
                  label={t('ressource')}
                  css={css`
                    font-size: 18px;
                    font-weight: 500;
                    padding: 14px 25px;
                  `}
                />
              )}
              <Tab
                label={t('customer')}
                css={css`
                  font-size: 18px;
                  font-weight: 500;
                  padding: 14px 25px;
                  color: #00223b;
                `}
              />
              <Tab
                label={t('attachment')}
                css={css`
                  font-size: 18px;
                  font-weight: 500;
                  padding: 14px 25px;
                  color: #00223b;
                `}
              />
            </Tabs>

            <ProjectEditGeneralPage
              allProjects={allProjects}
              isTabDataVisible={tabIndex === 0}
              control={control}
              errors={errors}
              change={change}
            />

            {isAsManager && isNotDraft && (
              <ProjectEditResourcePage
                isTabDataVisible={tabIndex === 1}
                projectProcesses={projectProcesses}
                changeProcesses={changeProcesses}
                handleCheckbox={handleCheckbox}
                change={change}
              />
            )}

            <ProjectEditCustomerPage
              isTabDataVisible={
                tabIndex === (isAsManager && isNotDraft ? 2 : 1)
              }
              control={control}
              change={change}
            />

            <ProjectEditAttachmentPage
              isTabDataVisible={
                tabIndex === (isAsManager && isNotDraft ? 3 : 2)
              }
              control={control}
              change={change}
              onError={(msg?: string | undefined) => {
                if (msg) setErrorMessage(msg);
                setOpen(true);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            lg={4}
            css={css`
              padding: 0px 24px 0px 25px;
            `}
          >
            {change && showButtonGroup && (
              <ButtonsGroup
                isChangeExist={!!change}
                changeStatus={change?.status || ''}
                workPackages={workPackages}
                change={change}
                onClickBtn={e => {
                  if (isDirty) {
                    setTemporaryModalType(
                      e.currentTarget.dataset.type as DialogType,
                    );
                    setRedirect(false);
                    setOpenSaveDialog(true);
                  } else {
                    setModalType(e.currentTarget.dataset.type as DialogType);
                  }
                }}
              />
            )}
            {(isNewReworkDraft(change) || !change?.status) && (
              <Grid
                item
                css={css`
                  margin-bottom: 24px;
                `}
              >
                {!change?.status && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <LoadingButton
                      type="submit"
                      onClick={() => setSaveAsDraft(true)}
                      name=""
                      variant="text"
                      loading={isLoading}
                      disabled={!isValid}
                      css={css`
                        top: 16px;
                        font-size: 14px;
                        padding: 17px 15px;
                        background: #00223b;
                        color: #ffffff;

                        &.Mui-disabled {
                          background: #9b9b9b;
                          color: rgba(0, 0, 0, 0.26);
                        }
                      `}
                    >
                      {t('create_draft')}
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      variant="text"
                      onClick={() => setSaveAsDraft(false)}
                      loading={isLoading}
                      disabled={!isValid}
                      css={css`
                        top: 16px;
                        font-size: 14px;
                        padding: 17px 15px;
                        background: #00223b;
                        color: #ffffff;

                        &.Mui-disabled {
                          background: #9b9b9b;
                          color: rgba(0, 0, 0, 0.26);
                        }
                      `}
                    >
                      {t('create_change')}
                    </LoadingButton>
                  </Box>
                )}
              </Grid>
            )}

            <Grid item>
              <List
                css={css`
                  margin-bottom: 7px;
                  padding-bottom: 0;
                  padding-top: 7px;
                `}
              >
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('ChangeNo')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={change?.changeNo}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('ChangeNoClient')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={change?.changeNoClient}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('createdat')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={
                      change?.createdAt
                        ? format(new Date(change?.createdAt), 'PP', {
                            locale: lang === 'de' ? de : enUS,
                          })
                        : ''
                    }
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('createdWho')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={change?.author}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item>
              <List>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('ProjNo')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={allProjects.selected?.number}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('projectName')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={allProjects.selected?.code}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('projectManager')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={allProjects.selected?.manager}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={`${t('projectChangeManager')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={
                      allProjects.selected?.temporaryChangeManagerAssignment
                        ?.userId ?? allProjects.selected?.changeManager
                    }
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding-left: 0;
                    padding-bottom: 0;
                    padding-top: 2px;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '13px',
                      fontWeight: '600',
                    }}
                    primary={`${t('client')}:`}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{ align: 'left' }}
                    secondary={allProjects.selected?.customer}
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
              </List>

              {isNotDraft && (
                <Grid
                  css={css`
                    padding: 12px 0 0 20px;
                  `}
                >
                  <Controller
                    control={control}
                    name="preSelectInternalChange"
                    defaultValue
                    render={({ field }) => (
                      <FormControl
                        disabled={
                          change?.status !== UpdateChangeDtoStatusEnum.New ||
                          !isAsManager
                        }
                      >
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          required
                          css={css`
                            .MuiFormLabel-asterisk {
                              color: #ff0000;
                            }
                          `}
                        >
                          {t('internalExternal')}
                        </FormLabel>
                        <RadioGroup
                          value={field.value}
                          onChange={field.onChange}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="false"
                            control={
                              <Radio
                                css={css`
                                  color: #000;
                                `}
                              />
                            }
                            label="Extern"
                          />
                          <FormControlLabel
                            value="true"
                            control={
                              <Radio
                                css={css`
                                  color: #000;
                                `}
                              />
                            }
                            label="Intern"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
              {change?.status && !isOnlyProjectViewer && !isRejected && (
                <LoadingButton
                  sx={{ marginTop: 2 }}
                  type="submit"
                  variant="outlined"
                  onClick={() => setRedirect(false)}
                  disabled={permissionToEdit}
                  loading={!modalType && isLoading}
                >
                  {t('save')}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      {open && (
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          message={message || t('saveFirst')}
        />
      )}

      {openReworkDialog && (
        <InformationConfirmDialog
          isOpen={openReworkDialog !== ''}
          onClose={() => setShowReworkDialog('')}
          onSave={changeToReworkAndCheck}
          text={`${t('ReworkChangeText')}!`}
          title={t('ReworkChangeTitle')}
        />
      )}

      <ChangeStatusDialogWrapper
        modalType={modalType}
        setModalType={m => setModalType(m)}
      />

      <InformationConfirmDialog
        isOpen={openSaveDialog}
        onSave={saveDataAndCloseDialog}
        text={`${t('unsavedChangesText')}!`}
        title={t('unsavedChangesTitle')}
        onClose={() => setOpenSaveDialog(false)}
      />
    </form>
  );
});

export default CreateChangePage;
