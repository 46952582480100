import DialogPopover from '../DialogPopover/DialogPopover';
import { DialogText, DialogTitle } from '../../constants/changes';
import { DialogType } from '../../types/changes';

type ConfirmAcceptanceDialogProps = {
  isOpen: boolean;
  modalType: DialogType;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};

const ConfirmAcceptanceDialog = ({
  isOpen,
  modalType,
  isLoading,
  handleClose,
  handleSubmit,
}: ConfirmAcceptanceDialogProps): JSX.Element => {
  const titles = DialogTitle();
  const texts = DialogText();

  return (
    <DialogPopover
      isOpen={isOpen}
      title={titles[modalType]}
      contentText={texts[modalType]}
      isLoading={isLoading}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      // eslint-disable-next-line react/no-children-prop
      children={undefined}
      isDisabledConfirm={false}
    />
  );
};

export default ConfirmAcceptanceDialog;
