import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Locale } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { observer } from 'mobx-react-lite';

const customEnLocale: Locale = {
  ...enGB,
  options: {
    ...enGB.options,
    weekStartsOn: 1,
  },
};

const LocalizationWrapper = observer(
  ({ children }: { children: React.ReactNode }) => (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={customEnLocale}
      locale={customEnLocale}
    >
      {children}
    </LocalizationProvider>
  ),
);

export default LocalizationWrapper;
