/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemButton,
} from '@mui/material';
import {
  GetChangeDto,
  GetWorkPackageAttachmentDto,
  GetWorkPackageDto,
  ListPspPhasesDto,
  ListWorkPackageDto,
  ProcessListForChangeDto,
  UpdateWorkPackageDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { v4 as uuidv4 } from 'uuid';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import {
  usePspPhaseApiFactory,
  useWorkPackageApiFactory,
} from '../../ApiClient';
import { NotificationType, NotificationTypes } from '../../types/notification';
import NotificationMessages from '../../constants/notification';
import { isChangeManager, isKTM } from '../../utils/permissionHelper';
import { useProjectStore } from '../../store/projectStore';
import { getDepartmentById } from '../../utils/utils';

export type WorkPackageModalProps = {
  isOpen: boolean;
  currentWorkPackage: GetWorkPackageDto;
  replaceUpdatedWorkPackage: (workPackage: ListWorkPackageDto) => void;
  setNotification: (data: NotificationType) => void;
  handleClose: () => void;
  change: GetChangeDto | null;
  currentProcess: ProcessListForChangeDto | null;
};

const WorkPackageModal = observer(
  ({
    isOpen,
    currentWorkPackage,
    handleClose,
    replaceUpdatedWorkPackage,
    setNotification,
    change,
    currentProcess,
  }: WorkPackageModalProps) => {
    const pspPhaseApi = usePspPhaseApiFactory();
    const workPackageApi = useWorkPackageApiFactory();
    const { pspPhases, pspWorkPackages } = useProjectStore();
    const [pspPhase, setPspPhase] = useState<ListPspPhasesDto | null>(null);

    const isDisableBtn =
      currentWorkPackage.status === UpdateWorkPackageDtoStatusEnum.Implemented;

    const NotificationMessage = NotificationMessages();

    const activeProcessTitle = getDepartmentById(
      Number(currentProcess?.pId ?? 0),
      change?.commonProject?.navProcessToCommonProjectRelations,
    )?.name;

    const permissionToEdit =
      isKTM(change, Number(currentProcess?.pId)) || isChangeManager(change);

    const { t } = useTranslation();

    const [filesData] = useState(currentWorkPackage?.attachments || []);
    const saveData = (buffer: any, name: string) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    };
    const downloadFile = async (item: GetWorkPackageAttachmentDto) => {
      if (currentWorkPackage?.id) {
        const file =
          await workPackageApi.workPackagesControllerDownloadAttachment(
            currentWorkPackage.id,
            item.id,
            {
              responseType: 'arraybuffer',
            },
          );
        saveData(file.data, item.fileName || '');
      }
    };

    const getWorkPackageName = (wp: GetWorkPackageDto): string => {
      const pspWP = pspWorkPackages.find(pspwp => pspwp[wp.navPhasePspId]);
      if (pspWP) {
        const pspPackage = pspWP[wp.navPhasePspId].find(
          elem => elem.navPhasePspId === wp.navPhasePspId,
        );
        return (pspPackage && pspPackage.name) || '';
      }
      return '';
    };

    useEffect(() => {
      try {
        if (pspPhases.length) {
          const currentPspPhase = pspPhases.find(
            phase => phase.id === currentWorkPackage.navPhasePspId,
          );
          if (currentPspPhase) setPspPhase(currentPspPhase);
        }
      } catch (e) {
        // console.error(e);
      }
    }, [currentWorkPackage, pspPhaseApi, pspPhases]);

    const handleSubmit = useCallback(async () => {
      try {
        const { data: updatedWorkPackage } =
          await workPackageApi.workPackagesControllerUpdate(
            currentWorkPackage.id,
            {
              status: UpdateWorkPackageDtoStatusEnum.Implemented,
            },
          );

        if (updatedWorkPackage) {
          replaceUpdatedWorkPackage({
            ...updatedWorkPackage,
            hasAttachments: updatedWorkPackage.attachments.length > 0,
          });
          handleClose();
          setNotification({
            type: NotificationTypes.success,
            message: NotificationMessage.workPackageStatusUpdate,
          });
        }
      } catch (e) {
        setNotification({
          type: NotificationTypes.error,
          message: NotificationMessage.error,
        });
        console.error(e);
      }
    }, [
      workPackageApi,
      currentWorkPackage.id,
      replaceUpdatedWorkPackage,
      handleClose,
      setNotification,
      NotificationMessage.workPackageStatusUpdate,
      NotificationMessage.error,
    ]);

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 574px;
            padding: 16px 24px;
            border: 1px solid #707070;
            background: #ffffff;
          `}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            css={css`
              margin-bottom: 24px;
              font-size: 17px;
              font-weight: 700;
              text-transform: uppercase;
            `}
          >
            {t('workpackage')} in {activeProcessTitle}
          </Typography>

          <Box
            css={css`
              padding: 0;
              background: #ececec;
            `}
          >
            <List
              css={css`
                padding: 0 8px;
                margin-bottom: 7px;
              `}
            >
              <ListItem
                alignItems="flex-start"
                css={css`
                  padding: 0;
                `}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                  primary="Phase PSP"
                  css={css`
                    width: 100%;
                    flex: 0 0 42%;
                  `}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    align: 'left',
                  }}
                  secondary={pspPhase?.name}
                  css={css`
                    width: 100%;
                  `}
                />
              </ListItem>
              <ListItem
                alignItems="flex-start"
                css={css`
                  padding: 2px 0 0;
                `}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                  primary={t('workPackagePSP')}
                  css={css`
                    width: 100%;
                    flex: 0 0 42%;
                  `}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    align: 'left',
                  }}
                  secondary={getWorkPackageName(currentWorkPackage)}
                  css={css`
                    width: 100%;
                  `}
                />
              </ListItem>

              <ListItem
                alignItems="flex-start"
                css={css`
                  padding: 2px 0 0;
                `}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                  primary={t('areaPSP')}
                  css={css`
                    width: 100%;
                    flex: 0 0 42%;
                  `}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    align: 'left',
                  }}
                  secondary={currentWorkPackage.areaPsp}
                  css={css`
                    width: 100%;
                  `}
                />
              </ListItem>
              <ListItem
                alignItems="flex-start"
                css={css`
                  padding: 2px 0 0;
                `}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                  primary={t('calcWorkPSP')}
                  css={css`
                    width: 100%;
                    flex: 0 0 42%;
                  `}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    align: 'left',
                  }}
                  secondary={currentWorkPackage.workPackageNo}
                  css={css`
                    width: 100%;
                  `}
                />
              </ListItem>
              <ListItem
                alignItems="flex-start"
                css={css`
                  padding: 2px 0 0;
                `}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                  primary={t('reqHours')}
                  css={css`
                    width: 100%;
                    flex: 0 0 42%;
                  `}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    align: 'left',
                  }}
                  secondary={currentWorkPackage.hours}
                  css={css`
                    width: 100%;
                  `}
                />
              </ListItem>
              {filesData.length > 0 && (
                <ListItem
                  alignItems="flex-start"
                  css={css`
                    padding: 2px 0 0;
                  `}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                    primary={t('files')}
                    css={css`
                      width: 100%;
                      flex: 0 0 42%;
                    `}
                  />
                  <ListItemText
                    secondaryTypographyProps={{
                      align: 'left',
                    }}
                    secondary={
                      filesData.length > 0 &&
                      filesData.map(file => (
                        <ListItemButton
                          key={uuidv4()}
                          onClick={() => downloadFile(file)}
                        >
                          {
                            // @ts-ignore
                            file.fileName ? file.fileName : file.name
                          }
                        </ListItemButton>
                      ))
                    }
                    css={css`
                      width: 100%;
                    `}
                  />
                </ListItem>
              )}

              <ListItem
                alignItems="flex-start"
                css={css`
                  padding: 2px 0 0;
                `}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                  primary={t('Status')}
                  css={css`
                    width: 100%;
                    flex: 0 0 42%;
                  `}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    align: 'left',
                  }}
                  secondary={
                    currentWorkPackage.status ===
                      UpdateWorkPackageDtoStatusEnum.New ||
                    currentWorkPackage.status ===
                      UpdateWorkPackageDtoStatusEnum.Open
                      ? t('open')
                      : t('implemented')
                  }
                  css={css`
                    width: 100%;
                  `}
                />
              </ListItem>
            </List>
          </Box>

          <Grid
            item
            css={css`
              display: flex;
              justify-content: flex-end;
              margin-bottom: 13px;
              margin-top: 30px;
            `}
          >
            <Button
              variant="text"
              css={css`
                font-size: 14px;
                padding: 15px 10px;
                margin-right: 25px;
                background: #9b9b9b;
                color: #00223b;

                &:hover {
                  background: #9b9b9b;
                }
              `}
              onClick={handleClose}
            >
              {t('close')}
            </Button>

            <Button
              disabled={isDisableBtn || !permissionToEdit}
              variant="text"
              css={css`
                font-size: 14px;
                padding: 15px 10px;
                background: #9b9b9b;
                color: #00223b;

                &:hover {
                  background: #9b9b9b;
                }
              `}
              onClick={handleSubmit}
            >
              {t('finish')}
            </Button>
          </Grid>
        </Box>
      </Modal>
    );
  },
);

export default WorkPackageModal;
