import React from 'react';

const NotificationIconCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
  >
    <path d="M0,0H23V24H0Z" fill="none" />
    <path
      d="M18.31,17.29,17.1,16V11c0-3.07-1.532-5.64-4.2-6.32V4a1.4,1.4,0,1,0-2.8,0v.68C7.418,5.36,5.9,7.92,5.9,11v5l-1.2,1.29A1.011,1.011,0,0,0,5.344,19h12.3a1.011,1.011,0,0,0,.663-1.71ZM15.236,17H7.764V11c0-2.48,1.41-4.5,3.736-4.5s3.736,2.02,3.736,4.5ZM11.5,22a1.943,1.943,0,0,0,1.868-2H9.632A1.937,1.937,0,0,0,11.5,22Z"
      fill="#fff"
    />
    <circle
      cx="4"
      cy="4"
      r="4"
      transform="translate(13 1)"
      fill="currentColor"
    />
  </svg>
);

export default NotificationIconCircle;
