/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';

export type CalculationResultPageProps = {
  isTabDataVisible: boolean;
  handleChangeProcess: (fieldName: string, value: number) => void;
  editable: boolean;
  finalQuotationValue: number;
  change: GetChangeDto | null;
};

const CalculationResultPage = observer(
  ({
    isTabDataVisible,
    handleChangeProcess,
    finalQuotationValue,
    editable,
    change,
  }: CalculationResultPageProps) => {
    const { t } = useTranslation();

    const updateChange = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      const { target } = e;
      const { value } = target;
      if (value) handleChangeProcess(target.name, Number(value));
    };
    return (
      <Grid
        container
        direction="column"
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
          padding: 19px 0px 12px 0px;
        `}
      >
        <Typography
          variant="body1"
          color="primary"
          css={css`
            width: fit-content;
            padding: 8px;
            margin-bottom: 16px;
            background: #ececec;
            color: #333333;
            font-size: 12px;
          `}
        >
          {t('calcTextInfo')}
        </Typography>

        <Box
          css={css`
            display: flex;
            flex-direction: column;
            padding: 11px 23px;
            border: 1px solid #ececec;
            box-shadow: 0px 3px 6px #ececec;
          `}
        >
          <Box
            css={css`
              display: flex;
              gap: 24px;
              margin: 14px 0;
            `}
          >
            <Typography
              css={css`
                font-size: 12px;
                font-weight: 600;
              `}
            >
              {t('calcResp')}:
            </Typography>
            <Typography
              css={css`
                font-size: 14px;
                font-weight: 300;
                color: #333333;
              `}
            >
              {change?.calcResponsible}
            </Typography>
          </Box>

          <Box
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInput-input {
                  padding: 4px 0 4px;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
              `}
              type="number"
              required
              label={t('calcValue')}
              variant="standard"
              disabled
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                  fontWeight: '300',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      variant="body1"
                      color="primary"
                      css={css`
                        font-size: 14px;
                        font-weight: 300;
                        color: #333333;
                      `}
                    >
                      {new Intl.NumberFormat(
                        localStorage.getItem('i18nextLng') || 'de',
                      ).format(Number(finalQuotationValue))}
                    </Typography>
                  </InputAdornment>
                ),
                disableUnderline: true,
                readOnly: true,
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 300,
                  color: '#333333',
                },
              }}
            />

            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInput-input {
                  padding: 4px 0 4px;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
              `}
              type="number"
              required
              name="materialValue"
              label={t('materialValue')}
              defaultValue={change?.materialValue}
              onBlur={updateChange}
              variant="standard"
              disabled={
                !editable ||
                !(
                  change?.status === UpdateChangeDtoStatusEnum.EvalAccepted ||
                  change?.status === UpdateChangeDtoStatusEnum.EvalCalcRejected
                )
              }
              InputProps={{
                inputMode: 'numeric',
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                  fontWeight: '300',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 300,
                  color: '#333333',
                },
              }}
            />

            <TextField
              error={
                !!change?.finalQuotationValue &&
                Number(change?.finalQuotationValue) <=
                  Number(change?.projectDiscount)
              }
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInput-input {
                  padding: 4px 0 4px;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
              `}
              type="number"
              helperText={
                finalQuotationValue &&
                Number(finalQuotationValue) <= Number(change?.projectDiscount)
                  ? 'WARNING!'
                  : null
              }
              defaultValue={change?.projectDiscount}
              onBlur={updateChange}
              name="projectDiscount"
              label={t('discountQuotationValue')}
              variant="standard"
              disabled={
                !editable ||
                !(
                  change?.status === UpdateChangeDtoStatusEnum.EvalAccepted ||
                  change?.status === UpdateChangeDtoStatusEnum.EvalCalcRejected
                )
              }
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                  fontWeight: '300',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 300,
                  color: '#333333',
                },
              }}
            />

            <TextField
              variant="standard"
              InputLabelProps={{
                style: {
                  display: 'none',
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      css={css`
                        margin-right: 16px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #333333;
                      `}
                      variant="body1"
                      color="primary"
                    >
                      {t('quotationValue')}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      css={css`
                        font-size: 14px;
                        font-weight: 300;
                        color: #333333;
                      `}
                    >
                      {new Intl.NumberFormat(
                        localStorage.getItem('i18nextLng') || 'de',
                      ).format(
                        Number(
                          Number(finalQuotationValue) +
                            Number(change?.materialValue) -
                            Number(change?.projectDiscount),
                        ),
                      )}
                    </Typography>
                  </InputAdornment>
                ),
                disableUnderline: true,
                readOnly: true,
                style: {
                  paddingLeft: '20px',
                },
              }}
            />
          </Box>
        </Box>
      </Grid>
    );
  },
);

export default CalculationResultPage;
