import { observer } from 'mobx-react-lite';
import {
  GetChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { t } from 'i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = observer(
  ({ changes }: { changes: GetChangeDto[]; project: string }) => {
    const countByStatus = changes.reduce((countMap, item) => {
      const count = countMap.get(item.status as UpdateChangeDtoStatusEnum) || 0;
      countMap.set(item.status as UpdateChangeDtoStatusEnum, count + 1);
      return countMap;
    }, new Map<UpdateChangeDtoStatusEnum, number>());

    const data = {
      labels: Array.from(countByStatus.keys()).map(elem => t(elem)),
      datasets: [
        {
          label: '#',
          data: Array.from(countByStatus.values()),
          backgroundColor: [
            'rgba(255, 99, 132)',
            'rgba(54, 162, 235)',
            'rgba(255, 206, 86)',
            'rgba(75, 192, 192)',
            'rgba(153, 102, 255)',
            'rgba(255, 159, 64)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    return <Pie data={data} />;
  },
);

export default PieChart;
