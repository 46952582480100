import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
  css,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ListUserWithAdminDto } from '@codefluegel/zeta-change-typescript-client';
import { LoadingButton } from '@mui/lab';
import { useUserApiFactory } from '../../ApiClient';

interface Props {
  open: boolean;
  handleClose: (newUser?: ListUserWithAdminDto) => void;
  user: ListUserWithAdminDto | null;
}

const AdminUserModal: FC<Props> = observer(props => {
  const { open, handleClose, user } = props;

  const [isAdmin, setisAdmin] = useState(user?.isAdmin);

  const [isBtnLoading] = useState(false);

  const userApi = useUserApiFactory();

  const onSave = async () => {
    if (user) {
      const { data: newUser } = await userApi.usersControllerUpdate(user?.id, {
        isAdmin,
      });

      const updateUser: ListUserWithAdminDto = {
        ...newUser,
        isAdmin: isAdmin ?? false,
      };

      handleClose(updateUser);
    }
  };

  // hooks
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          css={css`
            padding: 22px 0 0 0;
          `}
        >
          <Box
            css={css`
              display: flex;
              gap: 18px;
              margin-bottom: 3px;
              justify-content: space-between;
            `}
          >
            <Typography variant="h4">{user?.displayName}</Typography>
            <Typography variant="body1">{t('makeAdmin')}</Typography>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAdmin}
                    onChange={() => setisAdmin(!isAdmin)}
                  />
                }
                label="Administrator"
              />
            </FormGroup>
          </Box>

          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: end;
              gap: 20px;
            `}
          >
            <Box
              width={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  mr: 2,
                  bgcolor: 'white',
                  color: '#00223B',
                  outline: 'solid 1px #00223B',
                  '&:hover': {
                    bgcolor: '#00223B',
                    color: 'white',
                  },
                }}
                onClick={() => handleClose()}
              >
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={isBtnLoading}
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                type="submit"
                onClick={onSave}
              >
                {t('save')}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
});

export default AdminUserModal;
