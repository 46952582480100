/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Box, Typography } from '@mui/material';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import NotFoundImage from '../../assets/notfound.svg';

const PageNotFoundText = observer(() => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h3" sx={{ fontSize: 36 }}>
        {t('error')}
      </Typography>
      <img alt="Not Found" width={400} src={NotFoundImage} />
      <Typography variant="h4">{t('error404')}</Typography>
    </Box>
  );
});

export default PageNotFoundText;
