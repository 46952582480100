import React from 'react';

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17.048 17.058"
  >
    <path
      id="Path_267"
      data-name="Path 267"
      d="M15.5,14h-.79l-.28-.27a6.518,6.518,0,1,0-.7.7l.27.28v.79l4.25,4.25a1.054,1.054,0,0,0,1.49-1.49Zm-6,0A4.5,4.5,0,1,1,14,9.5,4.494,4.494,0,0,1,9.5,14Z"
      transform="translate(-2.999 -2.999)"
      fill="#80acd4"
    />
  </svg>
);

export default SearchIcon;
