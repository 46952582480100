/**
 * The external dependencies
 */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Button,
  Box,
} from '@mui/material';
import {
  ListWorkPackageDto,
  ProcessListForChangeDto,
  ProcessProjectForChangeDto,
  UpdateChangeDtoStatusEnum,
  UpdateProcessDtoEvaluationStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import WarningIcon from '@mui/icons-material/Warning';
/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import Tag from '../Tag/Tag';
import theme from '../../theme';
import WorkPackage from '../WorkPackage/WorkPackage';
import ProcessAccordionForm from './ProcessAccordionForm';

import { getTotalTime } from '../../utils/getTotalTime';
import LoadSpinnerSmall from '../LoadSpinner/LoadSpinnerSmall';
import { useChangeStore } from '../../store/changeStore';
import { isChangeManager, isKTM } from '../../utils/permissionHelper';

export type ProcessAccordionProps = {
  expanded: boolean;
  workPackages: ListWorkPackageDto[];
  changeId?: string;
  process: ProcessListForChangeDto;
  isImplementedBtnVisible: boolean;
  isLoading: boolean;
  handleChange: (fieldName: string, fieldValue: string | boolean) => void;
  handleExpand: () => void;
  handleWorkPackageModal: (workPackageId?: number) => void;
  handleUpdateAllWorkPackages: () => void;
  SummaryTitle: string;
  SummaryLabels?: React.ReactNode;
  status: string | null | undefined;
  department: ProcessProjectForChangeDto | undefined;
};

const ProcessAccordion = ({
  process,
  expanded,
  changeId,
  workPackages,
  isImplementedBtnVisible,
  isLoading,
  SummaryTitle,
  SummaryLabels,
  status,
  handleChange,
  handleWorkPackageModal,
  handleExpand,
  handleUpdateAllWorkPackages,
  department,
  ...props
}: ProcessAccordionProps): JSX.Element => {
  const isDisabled = workPackages.every(wP => wP.status === 'IMPLEMENTED');
  const { t } = useTranslation();
  const { change } = useChangeStore();

  const disableFinishProcess =
    (!process.affected && workPackages.length === 0) ||
    (change?.status !== UpdateChangeDtoStatusEnum.InEvaluation &&
      change?.status !== UpdateChangeDtoStatusEnum.EvalRework);

  const isFlightIncluded =
    workPackages && workPackages.some(wP => wP.travelIncluded);

  const permissionToEdit =
    isKTM(change, process.pId) || isChangeManager(change);

  const needsRework =
    status === UpdateProcessDtoEvaluationStatusEnum.OpenAfterRework;

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      css={css`
        background-color: white;
        margin-bottom: 16px;

        &::before {
          display: none;
        }

        & .MuiAccordionSummary-gutters {
          padding-left: 10px;
        }
        &.Mui-expanded {
          margin: 0;
          margin-bottom: 16px;
        }
      `}
      elevation={3}
      {...props}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            color="primary"
            css={css`
              transform: rotate(-90deg);
            `}
          />
        }
        css={css`
          flex-direction: row-reverse;
          gap: 16px;
          height: 70px;
          align-items: center;

          & .MuiAccordionSummary-content ~ .Mui-expanded {
            transform: rotate(90deg) !important;
          }
        `}
      >
        {needsRework && (
          <WarningIcon
            color="error"
            sx={{ display: 'flex', alignSelf: 'center' }}
          />
        )}
        <Typography
          variant="h4"
          css={css`
            flex-shrink: 0;
            justify-self: flex-start;
            height: max-content;
            margin-right: 20px;
          `}
        >
          {SummaryTitle}
        </Typography>

        {SummaryLabels}
        {expanded && isLoading && <LoadSpinnerSmall />}
      </AccordionSummary>

      <AccordionDetails
        css={css`
          display: flex;
          justify-content: space-between;
          padding-top: 0px;
          margin-top: -12px;

          @media (max-width: 690px) {
            flex-direction: column;
          }

          @media (max-width: 600px) {
            padding: 56px 0px 0px;
          }
        `}
      >
        <Grid
          container
          direction="row"
          css={css`
            justify-content: space-between;
          `}
        >
          {expanded && (
            <ProcessAccordionForm
              handleChange={handleChange}
              process={process}
              workPackages={workPackages}
            />
          )}

          <Grid item xs={12} sm={7} lg={6}>
            <Grid
              container
              alignItems="flex-end"
              direction="column"
              gap={3}
              css={css`
                display: flex;
                height: 100%;
              `}
            >
              <Grid item>
                {!isImplementedBtnVisible && (
                  <Button
                    onClick={() =>
                      handleChange(
                        'evaluationStatus',
                        process.evaluationStatus === 'APPROVED'
                          ? 'OPEN'
                          : 'APPROVED',
                      )
                    }
                    variant="text"
                    disabled={!permissionToEdit || disableFinishProcess}
                    css={css`
                      top: 16px;
                    `}
                  >
                    {process.evaluationStatus === 'APPROVED'
                      ? t('reopenProcess')
                      : t('finishprocess')}
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!isImplementedBtnVisible &&
                  (change?.status === UpdateChangeDtoStatusEnum.InEvaluation ||
                    change?.status ===
                      UpdateChangeDtoStatusEnum.EvalRework) && (
                    <Button
                      onClick={() => handleWorkPackageModal()}
                      variant="text"
                      disabled={
                        !permissionToEdit ||
                        process.affected ||
                        process.evaluationStatus === 'APPROVED'
                      }
                      css={css`
                        top: 16px;

                        @media (max-width: 690px) {
                          position: absolute;
                          top: 70px;
                          right: 24px;
                        }
                      `}
                    >
                      + {t('workpackage')}
                    </Button>
                  )}

                {isImplementedBtnVisible && !process.affected && (
                  <Button
                    disabled={isDisabled || !permissionToEdit}
                    onClick={handleUpdateAllWorkPackages}
                    variant="text"
                    css={css`
                      top: 16px;

                      @media (max-width: 690px) {
                        position: absolute;
                        top: 70px;
                        right: 24px;
                      }
                    `}
                  >
                    {t('finishImpl')}
                  </Button>
                )}
              </Grid>

              <Grid
                item
                css={css`
                  width: 100%;
                  padding-top: 8px;
                  display: flex;
                  justify-content: flex-end;
                  margin-top: auto;

                  @media (max-width: 690px) {
                    padding-top: 48px;
                  }
                `}
              >
                <Accordion
                  variant="outlined"
                  css={css`
                    background-color: white;
                    width: max-content;
                    max-width: 100%;

                    @media (max-width: 600px) {
                      width: 100%;
                    }
                  `}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        color="primary"
                        css={css`
                          transform: rotate(-90deg);
                        `}
                      />
                    }
                    css={css`
                      flex-direction: row-reverse;

                      & .MuiAccordionSummary-content {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                      }

                      & .MuiAccordionSummary-content ~ .Mui-expanded {
                        transform: rotate(90deg) !important;
                      }
                    `}
                  >
                    <Typography
                      variant="h4"
                      css={css`
                        line-height: 30px;
                      `}
                    >
                      {workPackages?.length || 0} {t('workpackages')}
                    </Typography>

                    <Box
                      css={css`
                        display: flex;
                        align-items: center;
                        gap: 18px;
                      `}
                    >
                      {isFlightIncluded && (
                        <FlightTakeoffIcon
                          css={css`
                            color: ${theme.palette.primary.light};
                          `}
                        />
                      )}
                      <Tag
                        label={`${getTotalTime(workPackages)}h ${t(
                          'required',
                        )}`}
                      />
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 24px;
                    `}
                  >
                    {workPackages.map(workPackage => (
                      <WorkPackage
                        key={workPackage.id}
                        processNo={department?.navProcess?.groupNo}
                        workPackage={workPackage}
                        process={process}
                        handleWorkPackageModal={handleWorkPackageModal}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ProcessAccordion;
