/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {
  Menu,
  MenuItem,
  Typography,
  Box,
  Badge,
  Select,
  SelectChangeEvent,
  Link,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
/**
 * The internal dependencies
 */
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router';
import {
  ListNotificationDto,
  ListProjectDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import { autorun, runInAction } from 'mobx';
import NotificationIcon from '../icons/NotificationIcon/NotificationIcon';
import SelectIcon from '../icons/SelectIcon/SelectIcon';
import Logo from '../icons/Logo/Logo';
import { Language, useStorex } from '../../store/UIStore';
import SidebarDrawer from '../SidebarDrawer/SidebarDrawer';
import { useProjectApiFactory, useUserApiFactory } from '../../ApiClient';
import NotificationModal from '../Notification/NotificationModal';
import NotificationIconCircle from '../icons/NotificationIcon/NotificationIconCircle';
import { getLatestNotifications } from '../../utils/utils';
import NavigationLink from './NavigationLink';
import ProjectSidebarDrawer from '../SidebarDrawer/ProjectSidebarDrawer';
import AdminSidebarDrawer from '../SidebarDrawer/AdminSidebarDrawer';
import { isAdmin } from '../../utils/permissionHelper';
import { useProjectStore } from '../../store/projectStore';

export type NavigationBarProps = {
  HasSidebar?: boolean;
  IsAdminSidebar?: boolean;
  isProjectSidebar?: boolean;
};

const NavigationBar = observer(
  ({
    HasSidebar = false,
    IsAdminSidebar = false,
    isProjectSidebar = false,
  }: NavigationBarProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigation = useNavigate();
    const store = useStorex();
    const { projectId } = useParams();

    const { setProjects } = useProjectStore();

    const showSidebar = HasSidebar || IsAdminSidebar || isProjectSidebar;

    const { t, i18n } = useTranslation();

    const admin = isAdmin();

    const userApi = useUserApiFactory();
    const projectApi = useProjectApiFactory();

    const notificationList = useLocalObservable(() => ({
      notifications: [] as ListNotificationDto[],
      setNotifications(notifcationList: ListNotificationDto[]) {
        this.notifications = notifcationList;
      },
    }));

    const [open, setOpenModal] = useState(false);

    const auth = useAuth();
    const user = auth.user?.profile.email?.toLocaleLowerCase();

    const handleLanguageSelect = (event: SelectChangeEvent) => {
      localStorage.setItem('i18nextLng', event.target.value.toLowerCase());
      i18n.changeLanguage(event.target.value.toLowerCase() || 'de');
      store.setLanguage(event.target.value as Language);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
      setAnchorEl(event.currentTarget);

    const handlePasswordChange = () => {
      auth.signinRedirect({
        redirect_uri: window.location.href,
        extraQueryParams: { kc_action: 'UPDATE_PASSWORD' },
      });
    };

    const handleClose = () => {
      setAnchorEl(null);
      auth.signoutRedirect({
        post_logout_redirect_uri: window.location.origin,
      });
    };

    const closeModal = async (url: string) => {
      const allNotifications =
        await userApi.usersControllerFindAllNotifications(user || '', true);

      const { data: notifications } = allNotifications;
      const latestNotifications = getLatestNotifications(notifications) || [];

      notificationList.setNotifications(latestNotifications);

      setOpenModal(false);
      navigation(url);
    };

    const openNotificationModal = () => {
      setOpenModal(true);
    };

    const allProjects = useLocalObservable(() => ({
      projects: [] as ListProjectDto[],
      get showProject() {
        return this.projects.some(
          project => project.changeManager.toLowerCase() === user || admin,
        );
      },
      get currentProject() {
        return this.projects.find(project => project.id === Number(projectId));
      },
    }));

    useEffect(
      () =>
        autorun(() => {
          (async () => {
            try {
              const allNotifications =
                await userApi.usersControllerFindAllNotifications(
                  user || '',
                  true,
                );
              const { data: notifications } = allNotifications;

              const latestNotifications =
                getLatestNotifications(notifications) || [];

              notificationList.setNotifications(latestNotifications);

              const { data: allProjectsx } =
                await projectApi.projectsControllerFindAll();
              setProjects(allProjectsx);
              runInAction(() => {
                allProjects.projects = allProjectsx;
              });
            } catch (e) {
              console.error(e);
            }
          })();
        }),
      [allProjects, notificationList, projectApi, setProjects, user, userApi],
    );

    const handleClickAway = () => {
      setAnchorEl(null);
    };

    const isTestSystem =
      window.location.href.includes('changemanagementtest') ||
      window.location.href.includes('datengarage') ||
      window.location.href.includes('localhost');

    return (
      <Box sx={{ flexGrow: 1, marginBottom: !showSidebar ? '48px' : '0px' }}>
        <AppBar
          position="fixed"
          sx={{ background: isTestSystem ? '#00790b' : '' }}
          css={css`
            height: 45px;
            min-height: 40px !important;
          `}
        >
          <Toolbar
            css={css`
              height: 45px;
              min-height: 40px !important;
              gap: 27px;
              padding-right: 19px !important;
              padding-left: 8px !important;
              @media (max-width: 480px) {
                gap: 16px;
                padding-right: 8px !important;
              }
            `}
          >
            {showSidebar && (
              <IconButton
                size="small"
                aria-label="Open Sidebar"
                color="secondary"
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 40px;
                  transform: translateY(-50%);
                  z-index: 20;

                  @media (max-width: 480px) {
                    left: 8px;
                  }
                `}
                onClick={
                  HasSidebar ? store.toggleSidebar : store.toggleAdminSidebar
                }
              >
                <MenuIcon />
              </IconButton>
            )}
            <IconButton
              size="small"
              aria-label="Open Sidebar"
              color="secondary"
              css={css`
                z-index: 20;

                @media (max-width: 480px) {
                  left: 8px;
                }
              `}
              onClick={() => navigation('/')}
            >
              <HomeIcon />
            </IconButton>
            <Link
              href="/"
              css={css`
                position: absolute;
                top: 0;
                padding: 5px;
                ${showSidebar ? 'left: 72px;' : 'left: 50px;'}

                @media (max-width: 480px) {
                  ${showSidebar ? 'left: 48px' : ''}
                }

                @media (max-width: 375px) {
                  ${!showSidebar ? 'left: 0' : ''}
                }
              `}
            >
              <Logo isTestSystem={isTestSystem} variant="contained" />
            </Link>

            {isTestSystem && (
              <Typography
                sx={{
                  fontSize: 18,
                  marginLeft: showSidebar ? 11 : 8,
                  color: 'white',
                }}
              >
                Test-System
              </Typography>
            )}

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

            <NavigationLink path="/projects" label={t('projects')} />

            {admin && (
              <NavigationLink
                path="/admin/user-management/external"
                label={t('user_management')}
              />
            )}

            <IconButton
              size="small"
              aria-label="Show Notifications"
              onClick={openNotificationModal}
            >
              {notificationList.notifications.length > 0 ? (
                <Badge
                  badgeContent={notificationList.notifications.length}
                  color="error"
                  css={css`
                    color: red;
                  `}
                >
                  <NotificationIconCircle />
                </Badge>
              ) : (
                <NotificationIcon />
              )}
            </IconButton>

            <Select
              value={`${store.language}`}
              onChange={handleLanguageSelect}
              variant="standard"
              disableUnderline
              css={css`
                color: white;
                font-weight: 600;
                font-size: 14px;
                .MuiSvgIcon-root {
                  color: white;
                }
              `}
              IconComponent={() => (
                <SelectIcon
                  css={css`
                    position: absolute;
                    top: calc(50% - 4px);
                    right: -8px;
                  `}
                />
              )}
            >
              <MenuItem value={Language.DE}>DE</MenuItem>
              <MenuItem value={Language.EN}>EN</MenuItem>
            </Select>

            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              css={css`
                border-radius: 8px;
              `}
            >
              <Typography
                variant="body1"
                color="white"
                css={css`
                  margin-right: 17px;
                  text-align: right;
                  min-width: 86px;

                  @media (max-width: 480px) {
                    min-width: unset;
                  }
                `}
              >
                {user}
              </Typography>

              <LogoutIcon
                css={css`
                  transform: rotate(180deg);
                `}
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClickAway}
            >
              <MenuItem onClick={handleClose}>Logout</MenuItem>
              <MenuItem onClick={handlePasswordChange}>
                {t('change_password')}
              </MenuItem>
            </Menu>
            <NotificationModal
              notifications={notificationList.notifications
                .slice()
                .sort((a, b) =>
                  b.createTimestamp.localeCompare(a.createTimestamp),
                )}
              onClose={url => closeModal(url)}
              open={open}
              projects={allProjects.projects}
            />
          </Toolbar>
        </AppBar>
        {HasSidebar && <SidebarDrawer />}
        {isProjectSidebar && (
          <ProjectSidebarDrawer project={allProjects.currentProject} />
        )}
        {IsAdminSidebar && <AdminSidebarDrawer />}
      </Box>
    );
  },
);

export default NavigationBar;
