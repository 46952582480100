import {
  GetChangeDto,
  ListProjectDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { useAuth } from 'react-oidc-context';

const isNewReworkDraft = (change: GetChangeDto | null): boolean =>
  change?.status === UpdateChangeDtoStatusEnum.New ||
  change?.status === UpdateChangeDtoStatusEnum.NewRework ||
  change?.status === UpdateChangeDtoStatusEnum.Draft ||
  !change;

const useIsChangeManager = (
  change: GetChangeDto | null | undefined,
): boolean => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();
  const isManager = user === change?.changeManager?.toLocaleLowerCase();
  const isTemporaryCM =
    user === change?.temporaryChangeManager?.userId.toLocaleLowerCase();
  const temporaryCMSet = change?.temporaryChangeManager;
  if (isManager && !temporaryCMSet) {
    return true;
  }
  if (isTemporaryCM) {
    return true;
  }
  return false;
};

const useIsViewerChangeManager = (
  change: GetChangeDto | null | undefined,
): boolean => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();
  const isManager = user === change?.changeManager?.toLocaleLowerCase();

  if (isManager) {
    return true;
  }

  return false;
};

const useIsAdmin = (): boolean => {
  const auth = useAuth();
  const groups = auth.user?.profile?.groups;
  const admin =
    Array.isArray(groups) && groups.includes('change-management-admin');
  return admin;
};

const isNotDraftOrReworkStatus = (
  change: GetChangeDto | null | undefined,
): boolean =>
  change?.status !== UpdateChangeDtoStatusEnum.Draft &&
  change?.status !== UpdateChangeDtoStatusEnum.NewRework;

const useIsCalculationPerson = (
  change: GetChangeDto | null | undefined,
): boolean => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();
  const calcresp = user === change?.calcResponsible?.toLocaleLowerCase();
  return calcresp;
};

const useUserIsProjectViewer = (
  change: GetChangeDto | null | undefined,
): boolean => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();

  if (user && change?.commonProject) {
    const viewerRights =
      change.commonProject.projectViewer.some(
        viewer => viewer.toLowerCase() === user,
      ) || change.projectManager?.toLocaleLowerCase() === user;
    if (
      change?.temporaryChangeManager?.userId.toLocaleLowerCase() === user ||
      change?.changeManager?.toLocaleLowerCase() === user ||
      change?.calcResponsible?.toLocaleLowerCase() === user
    ) {
      return false;
    }
    if (
      change.commonProject.navProcessToCommonProjectRelations.some(
        process =>
          process.owner === user ||
          process.temporaryCoreTeamMember?.user.id === user,
      )
    ) {
      return false;
    }
    if (change.author === user) return false;

    return viewerRights;
  }
  return false;
};

const useCheckIfTemporaryCM = (
  change: GetChangeDto | null | undefined,
): boolean => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();
  if (user && change?.temporaryChangeManager) {
    const viewerRights =
      user === change.temporaryChangeManager.userId.toLocaleLowerCase();
    return viewerRights;
  }
  return false;
};

const checkIfRejected = (change: GetChangeDto | null | undefined): boolean =>
  change?.status === UpdateChangeDtoStatusEnum.Rejected ||
  change?.status === UpdateChangeDtoStatusEnum.NewRejected ||
  change?.status === UpdateChangeDtoStatusEnum.InternalApprovalRejected ||
  change?.status === UpdateChangeDtoStatusEnum.DraftDiscarded;

const useIsAuthor = (change: GetChangeDto | null | undefined) => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();

  const isDraft =
    change?.status === UpdateChangeDtoStatusEnum.Draft ||
    change?.status === UpdateChangeDtoStatusEnum.NewRework;

  return change?.author?.toLowerCase() === user && isDraft;
};

const useDisableInput = (change: GetChangeDto | null | undefined): boolean => {
  const isChangeManager = useIsChangeManager(change);
  const isTemporaryCM = useCheckIfTemporaryCM(change);
  const isAuthor = useIsAuthor(change);
  const isProjectViewer = useUserIsProjectViewer(change);

  if (!change) return false;
  if (isChangeManager) {
    return false;
  }
  if (isTemporaryCM) {
    return false;
  }
  if (isAuthor) {
    return false;
  }
  if (isProjectViewer) {
    return true;
  }
  return true;
};

const useIsKTM = (
  change: GetChangeDto | null | undefined,
  processId: number,
): boolean => {
  const auth = useAuth();
  const user = auth.user?.profile.email?.toLocaleLowerCase();

  const process =
    change?.commonProject?.navProcessToCommonProjectRelations.find(
      commonProcess => commonProcess.id === processId,
    );

  const KTM = process?.owner?.toLocaleLowerCase() === user;

  const temporaryKTM =
    process?.temporaryCoreTeamMember?.user.id?.toLocaleLowerCase() === user;

  if (KTM && !process?.temporaryCoreTeamMember) {
    return true;
  }
  if (temporaryKTM) {
    return true;
  }
  return false;
};

const useGetRole = (project?: ListProjectDto | null): string => {
  let role = '';
  const auth = useAuth();

  if (useIsAdmin()) {
    role = 'admin';
  } else if (
    project &&
    project.changeManager.toLocaleLowerCase() ===
      auth.user?.profile?.email?.toLocaleLowerCase()
  ) {
    role = 'projChangeManager';
  } else if (
    project?.manager === auth.user?.profile?.email?.toLocaleLowerCase()
  ) {
    role = 'projectManager';
  } else if (
    project?.temporaryChangeManagerAssignment?.userId.toLocaleLowerCase() ===
    auth.user?.profile?.email?.toLocaleLowerCase()
  ) {
    role = 'temporaryCM';
  } else {
    role = 'KTM';
  }
  return role;
};

const useUserHasFullAccess = (
  change: GetChangeDto | null | undefined,
): boolean => {
  const isOnlyProjectViewer = useUserIsProjectViewer(change);
  const isManager = useIsChangeManager(change);
  const auth = useAuth();

  return (
    change?.commonProject?.navProcessToCommonProjectRelations.some(
      commonProcess =>
        commonProcess.fullAccess &&
        (commonProcess.owner?.toLocaleLowerCase() ===
          auth.user?.profile.email?.toLocaleLowerCase() ||
          commonProcess.temporaryCoreTeamMember?.user.id.toLocaleLowerCase() ===
            auth.user?.profile.email?.toLocaleLowerCase()),
    ) ||
    isManager ||
    isOnlyProjectViewer
  );
};

export {
  useIsChangeManager as isChangeManager,
  useIsCalculationPerson as isCalculationPerson,
  useUserIsProjectViewer as userIsProjectViewer,
  isNewReworkDraft,
  isNotDraftOrReworkStatus,
  checkIfRejected,
  useIsAdmin as isAdmin,
  useDisableInput as disableInput,
  useIsKTM as isKTM,
  useGetRole as getRole,
  useUserHasFullAccess,
  useIsViewerChangeManager,
};
