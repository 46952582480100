import React, { createContext, useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import {
  GetChangeDto,
  ListWorkPackageDto,
} from '@codefluegel/zeta-change-typescript-client';

import { ReducedWorkPackagesType } from '../types/workPackages';
// import { NotificationType } from '../types/changes';

export interface IChangeStore {
  change: GetChangeDto | null;
  workPackages: ListWorkPackageDto[];
  reducedWorkPackages: ReducedWorkPackagesType;
  user: string;
  isDataLoading: boolean;
  // getDataRequest: (changeId: number) => void;
  // getWorkPackages: (changeId: number) => void;
  setUpdatedChange: (updatedChange: GetChangeDto) => void;
  setUser: (updatedChange: string) => void;
  pushNewWorkPackage: (newWorkPackage: ListWorkPackageDto) => void;
  replaceUpdatedWorkPackage: (workPackage: ListWorkPackageDto) => void;
  removeWorkPackage: (workPackageId: number) => void;
  // removeProcessFromChange: (changeId: number, processId: string) => void;
  // addProcessToChange: (changeRequestId: number, departmentId: string) => void;
  setWorkPackages: (workPackages: ListWorkPackageDto[]) => void;
  setIsDataLoading: (flag: boolean) => void;
  setChange: (change: GetChangeDto | null) => void;
  resetStore: () => void;
}

export class ChangeStore implements IChangeStore {
  change: GetChangeDto | null = null;

  workPackages: ListWorkPackageDto[] = [];

  user = '';

  isDataLoading = false;
  // notification: NotificationType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsDataLoading = (flag: boolean) => {
    this.isDataLoading = flag;
  };

  setChange = (change: GetChangeDto | null) => {
    this.change = change;
  };

  setUser = (currentUser: string) => {
    this.user = currentUser;
  };

  setWorkPackages = (workPackages: ListWorkPackageDto[] | []) => {
    this.workPackages = workPackages;
  };

  /* getWorkPackages = async (changeId: number) => {
    try {
      this.setIsDataLoading(true);
      const { data: workPackages } =
        await workPackageApi.workPackagesControllerFindAll(undefined, [
          changeId,
        ]);

      if (workPackages) {
        this.setIsDataLoading(false);
        this.setWorkPackages(workPackages);
      }
    } catch (e) {
      this.setIsDataLoading(false);
      console.log(e);
    }
  }; */

  setUpdatedChange = (updatedChange: GetChangeDto) => {
    this.setChange(updatedChange);
  };

  pushNewWorkPackage = (newWorkPackage: ListWorkPackageDto) => {
    this.setWorkPackages([newWorkPackage, ...this.workPackages]);
  };

  removeWorkPackage = (removedWorkPackageId: number) => {
    const index = this.workPackages.findIndex(
      workPackage => workPackage.id === removedWorkPackageId,
    );
    this.workPackages.splice(index, 1);
  };

  replaceUpdatedWorkPackage = (updatedWorkPackage: ListWorkPackageDto) => {
    const index = this.workPackages.findIndex(
      workPackage => workPackage.id === updatedWorkPackage.id,
    );
    this.workPackages.splice(index, 1, updatedWorkPackage);
  };

  /* addProcessToChange = async (
    changeRequestId: number,
    departmentId: string
  ) => {
    try {
      const addedProcess = await processApi.processesControllerCreate({
        changeRequestId,
        departmentId,
      });

      if (addedProcess) {
        // TO FIX
        const { data: change } = await changeApi.changesControllerFindOne(
          changeRequestId
        );

        this.setChange(change);
      }
    } catch (e) {
      console.log(e);
    }
  };

  removeProcessFromChange = async (changeId: number, processId: string) => {
    try {
      const deletedProcess = await processApi.processesControllerRemove(
        changeId,
        processId
      );
      if (deletedProcess) {
        // TO FIX
        const { data: change } = await changeApi.changesControllerFindOne(
          Number(changeId)
        );
        this.setChange(change);
      }
    } catch (e) {
      console.log(e);
    }
  }; */

  resetStore = () => {
    this.setChange(null);
    this.setWorkPackages([]);
  };

  // setNotification = (data: NotificationType | null) => {
  //   this.notification = data;

  //   setTimeout(() => {
  //     this.notification = null;
  //   }, 5000)
  // }

  get reducedWorkPackages() {
    return this.workPackages.reduce(
      (acc: ReducedWorkPackagesType, item: ListWorkPackageDto) => ({
        ...acc,
        [item.processId]: [...(acc[item.processId] || []), item] || [],
      }),
      {},
    );
  }
}

const initialChangeStore = new ChangeStore();

export const StoreContext = createContext<IChangeStore>(initialChangeStore);

export type StoreProviderProps = {
  children: React.ReactNode;
};

export const useChangeStore = (): IChangeStore => useContext(StoreContext);
