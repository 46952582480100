/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from '@mui/material';

/**
 * The internal dependencies
 */

import { useTranslation } from 'react-i18next';

type AddUserListDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  text: string;
  title: string;
};

const InformationConfirmDialog = observer(
  ({ isOpen, onClose, onSave, text, title }: AddUserListDialogProps) => {
    const { t } = useTranslation();

    return (
      <Dialog
        open={isOpen}
        onClose={() => onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle sx={{ fontSize: 16 }}>{title}</DialogTitle>
        <DialogContent dividers>
          <Typography>{text}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('close')}</Button>
          <Button
            css={css`
              font-size: 14px;
              padding: 17px 15px;
              background: #00223b;
              color: #ffffff;

              &.Mui-disabled {
                background: #9b9b9b;
                color: rgba(0, 0, 0, 0.26);
              }
            `}
            autoFocus
            onClick={onSave}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);

export default InformationConfirmDialog;
