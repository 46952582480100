/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Avatar, Box, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import {
  GetChangeDto,
  ListWorkPackageDto,
  ProcessListForChangeDto,
} from '@codefluegel/zeta-change-typescript-client';

/**
 * The internal dependencies
 */
import ProcessItemInfo from './ProcessItemInfo';
import ProcessAccordion from '../ProcessAccordion/ProcessAccordion';
import {
  getDepartmentById,
  stringAvatar,
  stringToColor,
} from '../../utils/utils';
import { useUserStore } from '../../store/userStore';

export type ProcessAccordionItemProps = {
  changeId?: string;
  isImplementedBtnVisible: boolean;
  process: ProcessListForChangeDto;
  workPackages: ListWorkPackageDto[];
  isExpanded: boolean;
  isLoading: boolean;
  handleChange: (fieldName: string, fieldValue: string | boolean) => void;
  handleWorkPackageModal: (workPackageId?: number) => void;
  handleProcessExpand: (processId: number) => void;
  handleUpdateAllWorkPackages: () => void;
  change: GetChangeDto | null;
};

const ProcessAccordionItem = observer(
  ({
    process,
    isImplementedBtnVisible,
    workPackages,
    changeId,
    isExpanded,
    isLoading,
    handleChange,
    change,
    handleWorkPackageModal,
    handleProcessExpand,
    handleUpdateAllWorkPackages,
  }: ProcessAccordionItemProps) => {
    const { calculatedHours, timeImpact, releasedHours } = process;
    const { users } = useUserStore();

    const [userName, setuserName] = useState('');

    const department = getDepartmentById(
      process.pId,
      change?.commonProject?.navProcessToCommonProjectRelations,
    );

    useEffect(() => {
      (async () => {
        try {
          let user = '';
          if (department?.temporaryCoreTeamMember) {
            user = department?.temporaryCoreTeamMember.user.displayName;
            setuserName(user);
          } else {
            user = department?.owner ?? '';

            if (user) {
              const currentUser = users.find(u => u.id === user);
              if (currentUser) setuserName(currentUser?.displayName);
            }
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }, [
      department?.owner,
      department?.temporaryCoreTeamMember,
      process,
      users,
    ]);

    const handleExpand = useCallback(() => {
      handleProcessExpand(process.id);
    }, [process, handleProcessExpand]);

    return (
      <ProcessAccordion
        changeId={changeId}
        workPackages={workPackages}
        expanded={isExpanded}
        process={process}
        SummaryTitle={department?.name || ''}
        department={department}
        status={process.evaluationStatus}
        isImplementedBtnVisible={isImplementedBtnVisible}
        isLoading={isLoading}
        handleChange={handleChange}
        handleWorkPackageModal={handleWorkPackageModal}
        handleExpand={handleExpand}
        handleUpdateAllWorkPackages={handleUpdateAllWorkPackages}
        SummaryLabels={
          <Box
            css={css`
              display: flex;
              width: 100%;
              align-items: center;
              gap: 22px;
              justify-content: space-between;
              @media (max-width: 440px) {
                flex-direction: column;
                gap: 8px;
              }
            `}
          >
            {!isExpanded && (
              <ProcessItemInfo
                workPackages={workPackages}
                calculatedHours={calculatedHours}
                releasedHours={releasedHours}
                timeImpact={timeImpact}
                process={process}
              />
            )}
            <Tooltip title={userName}>
              <Avatar
                sx={{
                  width: '30px',
                  height: '30px',
                  bgcolor: stringToColor(userName),
                  marginLeft: 3,
                }}
              >
                {stringAvatar(userName)}
              </Avatar>
            </Tooltip>
          </Box>
        }
      />
    );
  },
);

export default ProcessAccordionItem;
