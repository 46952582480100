import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Typography } from '@mui/material';
import { t } from 'i18next';
import { useLocation } from 'react-router';

interface Props {
  path: string;
  label: string;
}

const NavigationLink: FC<Props> = observer(props => {
  const { path, label } = props;

  // current path from react router
  const currentPath = useLocation().pathname;

  return (
    <Link href={path}>
      <Typography
        variant="body1"
        color="white"
        sx={{
          fontWeight: currentPath === path ? 'bold' : 'normal',
        }}
      >
        {t(label)}
      </Typography>
    </Link>
  );
});

export default NavigationLink;
