/* eslint-disable jsx-a11y/label-has-associated-control */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

/**
 * The internal dependencies
 */
import {
  GetChangeDto,
  GetChangeRequestAttachmentDto,
} from '@codefluegel/zeta-change-typescript-client';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useChangeApiFactory } from '../../ApiClient';
import { Input } from '../changes/ProjectEditAttachmentPage';
import { hasOversizedFile } from '../../utils/utils';
import { checkIfRejected } from '../../utils/permissionHelper';

export type CalculationHoursPageProps = {
  isTabDataVisible: boolean;
  change: GetChangeDto | null;
  editable: boolean;
  showError: () => void;
};

const CalculationAttachmentsTab = observer(
  ({
    isTabDataVisible,
    change,
    editable,
    showError,
  }: CalculationHoursPageProps) => {
    const [filesData, setFilesData] = useState(change?.attachments || []);

    const changeApi = useChangeApiFactory();

    const permissionToEdit = editable;
    const isRejected = checkIfRejected(change);

    const { t } = useTranslation();

    useEffect(() => {
      if (change) {
        setFilesData(change?.attachments);
      }
    }, [change]);

    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const isOverSizedFile = hasOversizedFile(e);

        if (isOverSizedFile) {
          showError();
          return;
        }

        if (change?.id && e.target.files) {
          const newChangeRequest =
            await changeApi.changesControllerAddAttachments(
              change.id,
              Array.from(e.target.files),
              true,
            );

          const calcDocs = newChangeRequest.data.attachments.filter(
            attachment => attachment.isCalc,
          );
          setFilesData(calcDocs);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const saveData = (buffer: any, name: string) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    };

    const downloadFile = async (item: GetChangeRequestAttachmentDto) => {
      if (change?.id) {
        const file = await changeApi.changesControllerDownloadAttachment(
          change.id,
          item.id,
          {
            responseType: 'arraybuffer',
          },
        );
        saveData(file.data, item.fileName || '');
      }
    };

    const handleRemoveFile = async (
      item: GetChangeRequestAttachmentDto,
      fileIndex: number,
    ) => {
      try {
        if (change?.id) {
          await changeApi.changesControllerDeleteAttachment(change.id, item.id);
          setFilesData(prevFiles =>
            prevFiles.filter((_, index) => index !== fileIndex),
          );
        }
      } catch (e) {
        console.log(e);
      }
    };

    return (
      <Grid
        container
        direction="column"
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
          padding: 19px 0px 12px 0px;
        `}
      >
        {!isRejected && (
          <label htmlFor="contained-button-file">
            <Input
              accept=".pdf, .xls, .xlsx, .doc, .docx"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFileInputChange}
              disabled={!permissionToEdit}
            />
            <Button
              variant="contained"
              component="span"
              disabled={!permissionToEdit}
            >
              {`+ ${t('AddCalc')}`}
            </Button>
          </label>
        )}

        <List
          css={css`
            display: ${filesData.length > 0 ? 'block' : 'none'};
            margin-top: 25px;
            margin-bottom: 7px;
            padding: 5px 15px;
            border: 1px solid #f5f5f5;
          `}
        >
          {filesData
            .filter(item => item.isCalc)
            .map((item, idx) => (
              <Fragment key={uuidv4()}>
                <ListItem
                  sx={{ cursor: 'pointer' }}
                  secondaryAction={
                    <Box>
                      {!isRejected && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveFile(item, idx)}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </Box>
                  }
                >
                  <ListItemText
                    onClick={() => downloadFile(item)}
                    primaryTypographyProps={{
                      fontSize: '12px',
                      fontWeight: '300',
                    }}
                    primary={item.fileName}
                  />
                </ListItem>

                {filesData.length - 1 !== idx && <Divider light />}
              </Fragment>
            ))}
        </List>
      </Grid>
    );
  },
);

export default CalculationAttachmentsTab;
