/**
 * The external dependencies
 */
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

/**
 * The internal dependencies
 */
import logo from '../../../images/logo.png';
import logo_small from '../../../images/logo-small.png';

export type LogoProps = {
  variant: 'contained' | 'overlapping';
  isTestSystem?: boolean;
};

const Logo = ({ variant, isTestSystem }: LogoProps) => {
  if (variant === 'contained') {
    return (
      <img
        src={logo_small}
        alt="logo"
        style={{
          filter: isTestSystem
            ? 'invert(25%) sepia(89%) saturate(1089%) hue-rotate(88deg) brightness(89%) contrast(105%)'
            : '',
        }}
        css={css`
          @media (max-width: 375px) {
            max-width: 23px;
            object-fit: none;
            object-position: left;
            min-height: 40px;
          }
        `}
      />
    );
  }

  return <img src={logo} alt="logo" />;
};

export default Logo;
