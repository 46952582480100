/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {
  Grid,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  GetUserDto,
  ListChangeDto,
  ListNotificationDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Section from '../components/Section/Section';
import TaskCard from '../components/TaskCard/TaskCard';
import NotificationCard from '../components/NotificationPanel/NotificationCard/NotificationCard';
import NotificationPanel from '../components/NotificationPanel/NotificationPanel';
import { useStorex } from '../store/UIStore';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import {
  useChangeApiFactory,
  useProjectApiFactory,
  useUserApiFactory,
} from '../ApiClient';
import { useChangeStore } from '../store/changeStore';
import ChangeFilter from '../components/ChangeFilter/ChangeFilter';
import LoadSpinner from '../components/LoadSpinner/LoadSpinner';
import { getChangeLinkByStatus, getLatestNotifications } from '../utils/utils';
import UserSection from '../components/Section/UserSection';
import ChangeDataGrid from '../components/Grid/ChangeDataGrid';
import { useProjectStore } from '../store/projectStore';

const TaskOverviewPage = observer(() => {
  const store = useStorex();
  const { change, resetStore, isDataLoading, setIsDataLoading } =
    useChangeStore();

  const auth = useAuth();

  const { t } = useTranslation();
  const { projects } = useProjectStore();

  const userApi = useUserApiFactory();
  const projectApi = useProjectApiFactory();
  const changeApi = useChangeApiFactory();
  const navigate = useNavigate();
  const [mode, setMode] = useState(
    localStorage.getItem('dashBoardMode') || 'dashboard',
  );

  const [changeRequests, setChangeRequests] = useState<GetUserDto>();
  const [filteredStatuses, setFilteredStatuses] = useState<string[]>([]);
  const [searchString, setSearchString] = useState('');
  const [notifications, setNotifications] = useState<ListNotificationDto[]>([]);
  const [allChangeRequests, setAllChangeRequests] = useState<ListChangeDto[]>(
    [],
  );

  useEffect(() => {
    (async () => {
      try {
        if (!changeRequests) {
          setIsDataLoading(true);
          const user = auth.user?.profile.email?.toLocaleLowerCase();
          const changes = await userApi.usersControllerFindOne(user || '');
          const { data } = changes;

          const allNotifications =
            await userApi.usersControllerFindAllNotifications(user || '', true);

          const { data: notificationList } = allNotifications;

          const latestNotifications =
            getLatestNotifications(notificationList) || [];

          setNotifications(
            latestNotifications
              .sort((a, b) => Number(b.id) - Number(a.id))
              .slice(0, 5),
          );

          const { data: allC } = await changeApi.changesControllerFindAll(user);
          setAllChangeRequests(allC);

          setChangeRequests(data);
          setIsDataLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    })();

    if (change) {
      resetStore();
    }
  }, [
    auth.user?.profile.email,
    change,
    changeApi,
    changeRequests,
    projectApi,
    projects,
    resetStore,
    setIsDataLoading,
    store,
    userApi,
  ]);

  const filtred = useMemo(() => {
    if (allChangeRequests) {
      const lowerCase = searchString.toLowerCase();

      const filtered = allChangeRequests.filter(entry =>
        Object.values(entry).some(
          val =>
            typeof val === 'string' && val.toLowerCase().includes(lowerCase),
        ),
      );

      return filtered.length && filteredStatuses.length
        ? filtered.filter(item => filteredStatuses.includes(item.status))
        : filtered;
    }
    return [];
  }, [allChangeRequests, filteredStatuses, searchString]);

  const addNewChange = () => {
    navigate('/change-request/new');
  };

  const openChange = (changeId: number, status: string) => {
    const appendString = getChangeLinkByStatus(status);

    navigate(`/change-request/${changeId}/${appendString}`);
  };

  const handleChangeMode = (event: SelectChangeEvent) => {
    localStorage.setItem('dashBoardMode', event.target.value.toString());
    setMode(event.target.value);
  };

  return (
    <Box
      css={css`
        padding: 48px 24px 24px;
        flex-grow: 1;
        margin-left: auto;
        transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        @media (max-width: 430px) {
          padding: 48px 16px 24px;
        }
      `}
    >
      <NavigationBar />

      <Grid
        container
        css={css`
          justify-content: space-between;
          flex-wrap: nowrap;
          @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: center;
          }
        `}
      >
        {isDataLoading && <LoadSpinner />}
        {!isDataLoading && mode === 'dashboard' && (
          <Grid item xl={9} lg={8} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  css={css`
                    & .MuiGrid-item {
                      @media (max-width: 1120px) {
                        max-width: 100% !important;
                      }

                      @media (max-width: 430px) {
                      }
                    }
                  `}
                >
                  {changeRequests &&
                    changeRequests.authorChangeRequests.length > 0 && (
                      <UserSection
                        changeRequests={changeRequests.authorChangeRequests}
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_author')}
                      />
                    )}

                  {changeRequests &&
                    changeRequests.changeManagerChangeRequests.length > 0 && (
                      <UserSection
                        changeRequests={
                          changeRequests.changeManagerChangeRequests
                        }
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_manager')}
                      />
                    )}
                  {changeRequests &&
                    changeRequests.coreTeamMemberChangeRequests.length > 0 && (
                      <UserSection
                        changeRequests={
                          changeRequests.coreTeamMemberChangeRequests
                        }
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_ktm')}
                      />
                    )}
                  {changeRequests &&
                    changeRequests.calcResponsibleChangeRequests.length > 0 && (
                      <UserSection
                        changeRequests={
                          changeRequests.calcResponsibleChangeRequests
                        }
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_calc')}
                      />
                    )}
                  {changeRequests &&
                    changeRequests.viewerChangeRequests.length > 0 && (
                      <UserSection
                        changeRequests={changeRequests.viewerChangeRequests}
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('changes_as_viewer')}
                      />
                    )}
                  {changeRequests &&
                    changeRequests.temporaryChangeManagerChangeRequests.length >
                      0 && (
                      <UserSection
                        changeRequests={
                          changeRequests.temporaryChangeManagerChangeRequests
                        }
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_temporaryCM')}
                      />
                    )}
                  {changeRequests &&
                    changeRequests.projectManagerChangeRequests.length > 0 && (
                      <UserSection
                        changeRequests={
                          changeRequests.projectManagerChangeRequests
                        }
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_projectManager')}
                      />
                    )}
                  {changeRequests &&
                    changeRequests.temporaryCoreTeamMemberChangeRequests
                      .length > 0 && (
                      <UserSection
                        changeRequests={
                          changeRequests.temporaryCoreTeamMemberChangeRequests
                        }
                        openChange={(changeId, status) =>
                          openChange(changeId, status)
                        }
                        title={t('change_as_temporaryCTM')}
                      />
                    )}
                  <Grid item>
                    <Section
                      search={false}
                      Title={t('changes')}
                      css={css`
                        margin-top: 24px;
                        width: 100%;
                        max-width: calc(100vw - 60px) !important;

                        @media (max-width: 768px) {
                          max-width: 100% !important;
                        }
                      `}
                      Controls={
                        <Box style={{ display: 'flex', marginBottom: 5 }}>
                          <TextField
                            onChange={e => setSearchString(e.target.value)}
                            label={t('search')}
                            id="outlined-basic"
                            variant="standard"
                          />
                          <ChangeFilter
                            filteredStatuses={filteredStatuses}
                            setFilteredStatuses={(statuses: string[]) =>
                              setFilteredStatuses(statuses)
                            }
                          />
                        </Box>
                      }
                    >
                      <Grid
                        container
                        css={css`
                          display: flex;
                          align-items: center;
                          gap: 16px;
                          margin-top: 10px;
                        `}
                      >
                        {filtred &&
                          filtred.map(changeRequest => (
                            <Grid item key={changeRequest.id}>
                              <TaskCard
                                Title={changeRequest.title || ''}
                                Status={
                                  changeRequest.status as UpdateChangeDtoStatusEnum
                                }
                                ChangeNoClient={
                                  changeRequest.changeNoClient || ''
                                }
                                ChangeNo={changeRequest.changeNo || ''}
                                ChangeId={changeRequest.id || 0}
                                CompanySection={
                                  changeRequest.statusClient || ''
                                }
                                DueDate={
                                  new Date(changeRequest.createdAt) ||
                                  new Date()
                                }
                                Author={changeRequest.initiator || ''}
                                onClick={(changeId, status) =>
                                  openChange(changeId, status)
                                }
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Section>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isDataLoading && mode === 'list' && changeRequests && (
          <ChangeDataGrid changeRequests={changeRequests} />
        )}

        <Grid
          item
          xl={2}
          lg={3}
          xs={-1}
          css={css`
            display: flex;
            margin-left: auto;

            @media (max-width: 1200px) {
              max-width: unset;
            }

            @media (max-width: 600px) {
              width: 100%;
            }
          `}
        >
          <Grid container direction="column">
            <Grid
              item
              css={css`
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                gap: 16px;
              `}
            >
              <Select
                sx={{
                  '& .MuiSelect-select': {
                    paddingRight: 4,
                    paddingLeft: 1,
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  },
                }}
                variant="filled"
                value={mode}
                onChange={handleChangeMode}
              >
                <MenuItem dense value="dashboard">
                  <DashboardIcon />
                </MenuItem>
                <MenuItem dense value="list">
                  <TableRowsIcon />
                </MenuItem>
              </Select>
              <Button
                onClick={addNewChange}
                variant="contained"
                css={css`
                  margin-top: -31px;

                  @media (max-width: 768px) {
                    margin-top: 24px;
                  }
                `}
              >
                + CHANGE REQUEST
              </Button>
            </Grid>

            <Grid
              item
              css={css`
                @media (max-width: 1200px) {
                  margin: 0px auto;
                  width: max-content;
                }
                @media (max-width: 480px) {
                  display: none;
                }
              `}
            >
              {notifications.length > 0 && (
                <NotificationPanel>
                  {notifications.map(notification => (
                    <NotificationCard
                      key={notification.id}
                      NotificationId={notification.id}
                      read={notification.read}
                      Title={notification.changeRequest.title || ''}
                      ChangeNoClient={
                        notification.changeRequest.changeNoClient || ''
                      }
                      ChangeNo={notification.changeRequest.changeNo || ''}
                      ProjectId={
                        projects.find(
                          project =>
                            project.id === notification.changeRequest.projectId,
                        )?.number || ''
                      }
                      ChangeId={notification.changeRequest.id.toString()}
                      Status={
                        (notification.changeRequest
                          .status as UpdateChangeDtoStatusEnum) || ''
                      }
                      navigateToChange={url => navigate(url)}
                    />
                  ))}
                </NotificationPanel>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default TaskOverviewPage;
