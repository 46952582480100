import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

function useMedia(breakpoint: Breakpoint) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down(breakpoint));

  return matches;
}

export default useMedia;
