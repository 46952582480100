import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { useAuth } from 'react-oidc-context';
import TaskOverviewPage from '../views/TaskOverviewPage';

import ProjectNewPage from '../views/changes/CreateChangePage';
import ProjectViewerListPage from '../views/projects/ProjectViewerListPage';
import ProjectExternalPage from '../views/ProjectExternalPage';
import CalculationPage from '../views/calculation/CalculationPage';
import ChangePage from '../views/ChangePage';
import ProjectEditResourcesGroupPage from '../views/ProjectEditResourcesGroupPage';
import ChangeLayoutPage from '../views/ChangeLayoutPage';
import LoginRedirectPage from '../views/LoginRedirectPage';
import ProjectsOverviewPage from '../views/projects/ProjectsOverviewPage';
import ProjectsProcessesPage from '../views/projects/ProjectsProcessesPage';
import ProjectsDashboard from '../views/projects/ProjectsDashboard';
import ChangeDashboardPage from '../views/projects/ChangeDashboardPage';
import ExternalUserPage from '../views/admin/ExternalUserPage';

import ProjectLayoutPage from '../views/ProjectLayoutPage';
import AdminLayoutPage from '../views/AdminLayoutPage';
import ProjectsEditRolePage from '../views/projects/ProjectsEditRolePage';
import InternalUserPage from '../views/admin/InternalUserPage';

const RequireAuth = observer(() => {
  const location = useLocation();

  const auth = useAuth();

  if (!auth.error && !auth.isLoading && !auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
});

const Router: React.FunctionComponent = observer(() => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<LoginRedirectPage />} />
      <Route path="/oauth-redirect" element={<LoginRedirectPage />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<TaskOverviewPage />} />

        <Route path="/projects" element={<ProjectsOverviewPage />} />

        <Route path="/admin" element={<AdminLayoutPage />}>
          <Route
            path="/admin/user-management/external"
            element={<ExternalUserPage />}
          />
          <Route
            path="/admin/user-management/internal"
            element={<InternalUserPage />}
          />
        </Route>
        <Route path="/projects" element={<ProjectLayoutPage />}>
          <Route
            path="/projects/:projectId"
            element={<ProjectsProcessesPage />}
          />
          <Route
            path="/projects/:projectId/project-viewer"
            element={<ProjectViewerListPage />}
          />
          <Route
            path="/projects/:projectId/edit-role"
            element={<ProjectsEditRolePage />}
          />
          <Route
            path="/projects/:projectId/processes"
            element={<ProjectsProcessesPage />}
          />
          <Route
            path="/projects/:projectId/processes"
            element={<ProjectsProcessesPage />}
          />
          <Route
            path="/projects/:projectId/dashboard"
            element={<ProjectsDashboard />}
          />
          <Route
            path="/projects/:projectId/dashboard/:projectNumber"
            element={<ChangeDashboardPage />}
          />
        </Route>

        <Route path="/change-request" element={<ChangeLayoutPage />}>
          <Route path=":changeId" element={<ChangePage />} />
          <Route path="new" element={<ProjectNewPage />} />
          <Route path=":changeId/edit" element={<ProjectNewPage />} />
          <Route path=":changeId/calculation" element={<CalculationPage />} />
          <Route path=":changeId/approval" element={<ProjectExternalPage />} />
          <Route
            path=":changeId/implementation"
            element={<ProjectEditResourcesGroupPage />}
          />
          <Route
            path=":changeId/implementation/:processId"
            element={<ProjectEditResourcesGroupPage />}
          />
          <Route
            path=":changeId/resources/"
            element={<ProjectEditResourcesGroupPage />}
          />
          <Route
            path=":changeId/resources/:processId"
            element={<ProjectEditResourcesGroupPage />}
          />
        </Route>
      </Route>
      <Route path="*" element={<TaskOverviewPage />} />
    </Routes>
  </BrowserRouter>
));

export default Router;
