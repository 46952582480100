/* eslint-disable @typescript-eslint/no-shadow */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Typography } from '@mui/material';

/**
 * The internal dependencies
 */
import DialogPopover from '../DialogPopover/DialogPopover';
import { DialogText, DialogTitle } from '../../constants/changes';
import { DialogType } from '../../types/changes';

type DiscardChangeDialogProps = {
  isOpen: boolean;
  modalType: DialogType;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: (comment?: string) => void;
};

const DiscardChangeDialog = ({
  isOpen,
  modalType,
  isLoading,
  handleClose,
  handleSubmit,
}: DiscardChangeDialogProps): JSX.Element => {
  const titles = DialogTitle();
  const texts = DialogText();

  const onSubmitHandler = () => {
    handleSubmit();
  };

  return (
    <DialogPopover
      isOpen={isOpen}
      isDisabledConfirm={false}
      title={titles[modalType]}
      contentText={texts[modalType]}
      handleClose={handleClose}
      handleSubmit={onSubmitHandler}
      isLoading={isLoading}
      buttonText="discard"
    >
      <Typography />
    </DialogPopover>
  );
};

export default DiscardChangeDialog;
