/* eslint-disable react/jsx-fragments */
/**
 * The external dependencies
 */
import { Alert, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';

/**
 * The internal dependencies
 */
import { useStorex } from '../../store/UIStore';

const Notification = observer(() => {
  const { notification, setNotification } = useStorex();

  const handleClose = () => {
    setNotification(null);
  };

  return (
    <Fragment>
      {notification && (
        <Snackbar
          open={!!notification}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose}
            severity={notification.type}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  );
});

export default Notification;
