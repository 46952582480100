/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  IconButton,
  ListItem,
  List,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  CreateWorkPackageDto,
  GetChangeDto,
  GetWorkPackageAttachmentDto,
  GetWorkPackageDto,
  ListPspPhasesDto,
  ListWorkPackageDto,
  ProcessListForChangeDto,
  UpdateChangeDtoStatusEnum,
  UpdateWorkPackageDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import theme from '../../theme';
import {
  useWorkPackageApiFactory,
  usePspPhaseApiFactory,
} from '../../ApiClient';
import { AllPhasesType } from '../../types/workPackages';
import useLoading from '../../utils/useLoading';
import LoadSpinnerSmall from '../LoadSpinner/LoadSpinnerSmall';
import { NotificationType, NotificationTypes } from '../../types/notification';
import NotificationMessages from '../../constants/notification';
import {
  checkIfRejected,
  isChangeManager,
  isKTM,
} from '../../utils/permissionHelper';
import { getDepartmentById, hasOversizedFile } from '../../utils/utils';
import { useProjectStore } from '../../store/projectStore';

const Input = styled('input')({
  display: 'none',
});

type WorkPackageModalProps = {
  isOpen: boolean;
  currentWorkPackage: GetWorkPackageDto | null;
  handleClose: () => void;
  pushNewWorkPackage: (newWorkPackage: ListWorkPackageDto) => void;
  replaceUpdatedWorkPackage: (workPackageId: ListWorkPackageDto) => void;
  removeWorkPackage: (workPackageId: number) => void;
  setNotification: (data: NotificationType) => void;
  change: GetChangeDto | null;
  currentProcess: ProcessListForChangeDto | null;
};

const WorkPackageModal = observer(
  ({
    isOpen,
    currentWorkPackage,
    handleClose,
    pushNewWorkPackage,
    removeWorkPackage,
    replaceUpdatedWorkPackage,
    setNotification,
    change,
    currentProcess,
  }: WorkPackageModalProps) => {
    const { processId } = useParams();
    const workPackageApi = useWorkPackageApiFactory();
    const pspPhaseApi = usePspPhaseApiFactory();
    const {
      control,
      formState: { isValid },
      setValue,
      handleSubmit,
      reset,
    } = useForm<CreateWorkPackageDto>({
      mode: 'onChange',
    });

    const groupNo = getDepartmentById(
      Number(currentProcess?.pId ?? 0),
      change?.commonProject?.navProcessToCommonProjectRelations,
    )?.groupNo;

    const { t } = useTranslation();
    const NotificationMessage = NotificationMessages();
    const { pspPhases, pspWorkPackages } = useProjectStore();

    const [filesData, setFilesData] = useState(
      currentWorkPackage?.attachments || [],
    );

    const isRejected = checkIfRejected(change);

    const [isBtnLoading, toggleIsBtnloading] = useState<boolean>(false);
    const { isLoading, toggleIsLoading } = useLoading();

    const isKTMorCM =
      isKTM(change, Number(currentProcess?.pId)) || isChangeManager(change);

    const allAreas = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
    ];

    const allPhases = useLocalObservable<AllPhasesType>(() => ({
      allPspPhases: pspPhases,
      currentPspPhase: null,
      pspWorkPackages: [],
      setCurrentPhase: pspPhase => {
        allPhases.currentPspPhase = pspPhase;
      },
      setPspWorkPackages: phases => {
        allPhases.pspWorkPackages = phases;
      },
    }));

    const handleChangePhasePsp = async (val: ListPspPhasesDto) => {
      allPhases.setCurrentPhase(val);
      setValue('workPackagePspId', '');
      allPhases.setPspWorkPackages([]);
    };

    useEffect(() => {
      (async () => {
        try {
          if (
            allPhases.currentPspPhase &&
            allPhases.pspWorkPackages.length === 0
          ) {
            if (allPhases.currentPspPhase) {
              const phaseId = allPhases.currentPspPhase?.id;
              const currentWP = pspWorkPackages.find(wp => wp[phaseId]);

              if (currentWP) {
                const currentWPs = currentWP[phaseId];
                allPhases.setPspWorkPackages(currentWPs);
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }, [
      allPhases,
      allPhases.currentPspPhase,
      pspPhaseApi,
      pspWorkPackages,
      toggleIsLoading,
    ]);

    useEffect(() => {
      if (currentWorkPackage) {
        const currentPspPhase = allPhases.allPspPhases.find(
          phase => phase.id === currentWorkPackage.navPhasePspId,
        );
        if (currentPspPhase) allPhases.setCurrentPhase(currentPspPhase);

        reset({
          owner: currentWorkPackage.ownerData?.id,
          navPhasePspId: currentWorkPackage.navPhasePspId,
          hours: currentWorkPackage.hours,
          description: currentWorkPackage.description,
          travelIncluded: currentWorkPackage.travelIncluded,
          groupNo: currentWorkPackage.groupNo || '',
          workPackagePspId: currentWorkPackage.workPackagePspId,
          areaPsp: currentWorkPackage.areaPsp,
        });
      }
    }, [currentWorkPackage, allPhases.allPspPhases, allPhases, reset]);

    const saveData = (buffer: any, name: string) => {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    };

    const downloadFile = async (item: GetWorkPackageAttachmentDto) => {
      if (currentWorkPackage?.id) {
        const file =
          await workPackageApi.workPackagesControllerDownloadAttachment(
            currentWorkPackage.id,
            item.id,
            {
              responseType: 'arraybuffer',
            },
          );
        saveData(file.data, item.fileName || '');
      }
    };

    const handleRemoveFile = async (
      item: GetWorkPackageAttachmentDto,
      fileIndex: number,
    ) => {
      try {
        if (currentWorkPackage?.id) {
          await workPackageApi.workPackagesControllerDeleteAttachment(
            currentWorkPackage.id,
            item.id,
          );
        }
        setFilesData(prevFiles =>
          prevFiles.filter((_, index) => index !== fileIndex),
        );
      } catch (err) {
        console.log(err);
      }
    };

    const onSubmit = async (data: CreateWorkPackageDto) => {
      try {
        if (processId) {
          toggleIsBtnloading(true);

          const requestData = {
            ...data,
            processId: Number(processId),
            hours: Number(data.hours),
            groupNo: String(groupNo),
            areaPsp: Number(data.areaPsp),
          };
          if (currentWorkPackage) {
            const { data: updatedWorkPackage } =
              await workPackageApi.workPackagesControllerUpdate(
                currentWorkPackage.id,
                {
                  ...requestData,
                  status: UpdateWorkPackageDtoStatusEnum.New,
                },
              );

            if (updatedWorkPackage) {
              replaceUpdatedWorkPackage({
                ...updatedWorkPackage,
                hasAttachments:
                  updatedWorkPackage.attachments &&
                  updatedWorkPackage.attachments.length > 0,
              });

              setNotification({
                type: NotificationTypes.success,
                message: NotificationMessage.workPackageUpdate,
              });
            }
          } else {
            const { data: newWorkPackage } =
              await workPackageApi.workPackagesControllerCreate(requestData);

            if (Array.from(filesData).length > 0) {
              await workPackageApi.workPackagesControllerAddAttachments(
                newWorkPackage.id,
                Array.from(filesData),
              );
            }

            if (newWorkPackage) {
              pushNewWorkPackage({
                ...newWorkPackage,
                hasAttachments: Array.from(filesData).length > 0,
              });
              setNotification({
                type: NotificationTypes.success,
                message: NotificationMessage.workPackageCreate,
              });
            }
          }

          toggleIsBtnloading(false);
          handleClose();
        }
      } catch (e: any) {
        setNotification({
          type: NotificationTypes.error,
          message: e.response.data.message || NotificationMessage.error,
        });
        toggleIsBtnloading(false);
      }
    };

    const deleteWorkPackage = async () => {
      try {
        if (processId) {
          toggleIsBtnloading(true);

          if (currentWorkPackage) {
            await workPackageApi.workPackagesControllerRemove(
              currentWorkPackage.id,
            );

            removeWorkPackage(currentWorkPackage.id);
            setNotification({
              type: NotificationTypes.success,
              message: NotificationMessage.workPackageCreate,
            });
          }

          toggleIsBtnloading(false);
          handleClose();
        }
      } catch (e: any) {
        setNotification({
          type: NotificationTypes.error,
          message: e.response.data.message || NotificationMessage.error,
        });
        toggleIsBtnloading(false);
      }
    };

    const handleFileInputChange = async (e: any) => {
      try {
        const isOverSizedFile = hasOversizedFile(e);

        if (isOverSizedFile) {
          setNotification({
            type: NotificationTypes.error,
            message: t('fileTooBig'),
          });
          return;
        }
        if (e.target.files) {
          if (currentWorkPackage?.id) {
            const newChangeRequest =
              await workPackageApi.workPackagesControllerAddAttachments(
                currentWorkPackage.id,
                Array.from(e.target.files),
              );

            filesData.length === 0;
            setFilesData(newChangeRequest.data.attachments);
          } else if (e.target.files)
            filesData.length === 0
              ? setFilesData(Array.from(e.target.files))
              : setFilesData(prevFiles =>
                  prevFiles.concat(Array.from(e.target.files)),
                );
        }
      } catch (err) {
        console.log(err);
      }
    };

    return (
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 652px;
            padding: 20px 24px;
            border: 1px solid #707070;
            background: #ffffff;
          `}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            css={css`
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
            `}
          >
            {t('editActivity')}
          </Typography>

          {isLoading && <LoadSpinnerSmall />}
          {allPhases.allPspPhases && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                css={css`
                  padding: 22px 0 0 0;
                `}
              >
                <Controller
                  control={control}
                  name="navPhasePspId"
                  rules={{ required: true }}
                  render={({ field }) => {
                    const curPhase = allPhases.allPspPhases.find(
                      phase => phase.id === field.value,
                    );

                    return (
                      <Autocomplete
                        popupIcon={
                          <ExpandMoreIcon
                            color="primary"
                            css={css`
                              color: #80acd4;
                            `}
                          />
                        }
                        css={css`
                          icon: {
                            fill: 'red';
                          }
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        getOptionLabel={option => {
                          if (typeof option === 'string') {
                            return curPhase ? curPhase.name : '';
                          }
                          return option.name;
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option !== value
                        }
                        options={allPhases.allPspPhases}
                        // @ts-ignore
                        value={field.value ? field.value : ''}
                        onChange={(e, val) => {
                          if (val) {
                            handleChangePhasePsp(val);
                            field.onChange(val.id);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={!field.value}
                            required
                            label={t('PhasePSP')}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                paddingLeft: '16px',
                                paddingBottom: '6px',
                                marginBottom: '16px',
                                fontSize: '14px',
                                fontWeight: '300',
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />

                <Box
                  css={css`
                    display: flex;
                    gap: 18px;
                    margin-bottom: 3px;
                  `}
                >
                  <Controller
                    control={control}
                    name="areaPsp"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        popupIcon={
                          <ExpandMoreIcon
                            color="primary"
                            css={css`
                              color: #80acd4;
                            `}
                          />
                        }
                        css={css`
                          icon: {
                            fill: 'red';
                          }
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        options={allAreas}
                        value={field.value ? String(field.value) : ''}
                        onChange={(e, val) => {
                          if (val) {
                            field.onChange(val);
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={!field.value}
                            required
                            label={t('areaPSP')}
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                paddingLeft: '16px',
                                paddingBottom: '6px',
                                marginBottom: '16px',
                                fontSize: '14px',
                                fontWeight: '300',
                                minWidth: 150,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {allPhases.pspWorkPackages && (
                    <Controller
                      control={control}
                      name="workPackagePspId"
                      rules={{ required: true }}
                      render={({ field }) => {
                        const curPhase = allPhases.pspWorkPackages.find(
                          phase => phase.id === field.value,
                        );
                        return (
                          <Autocomplete
                            popupIcon={
                              <ExpandMoreIcon
                                color="primary"
                                css={css`
                                  color: #80acd4;
                                `}
                              />
                            }
                            css={css`
                              width: 100%;
                              icon: {
                                fill: 'red';
                              }
                              .MuiInput-underline:before {
                                border-bottom: 1px solid #ececec;
                              }
                              .MuiInputLabel-asterisk {
                                color: #ff0000;
                              }
                            `}
                            renderOption={({ ...props }, option) => (
                              <li {...props} key={option.id}>
                                {option.name}
                              </li>
                            )}
                            getOptionLabel={option => {
                              if (typeof option === 'string') {
                                return curPhase ? curPhase.name : '';
                              }
                              return option.name;
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option !== value
                            }
                            // @ts-ignore
                            value={field.value ? field.value.toString() : ''}
                            onChange={(e, val) => val && field.onChange(val.id)}
                            options={
                              !!allPhases.allPspPhases && !isLoading
                                ? allPhases.pspWorkPackages
                                : []
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                error={!field.value}
                                required
                                label={t('workPackagePSP')}
                                variant="standard"
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    paddingLeft: '16px',
                                    paddingBottom: '6px',
                                    fontSize: '14px',
                                    fontWeight: '300',
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    paddingLeft: '20px',
                                    fontWeight: 600,
                                    color: '#333333',
                                  },
                                }}
                              />
                            )}
                          />
                        );
                      }}
                    />
                  )}
                </Box>
                <Box
                  css={css`
                    display: flex;
                    gap: 10px;
                    margin-bottom: 20px;
                    margin-left: 12px;
                  `}
                >
                  <Typography
                    css={css`
                      font-size: 12px;
                      font-weight: 600;
                    `}
                  >
                    {t('calcWorkPSP')}
                  </Typography>
                  <Typography
                    css={css`
                      font-size: 14px;
                      font-weight: 300;
                      color: #333333;
                    `}
                  >
                    {currentWorkPackage
                      ? currentWorkPackage.workPackageNo
                      : '-'}
                  </Typography>
                </Box>

                {/* <Controller
                  control={control}
                  name="groupNo"
                  rules={{ required: true }}
                  render={({ field }) => {
                    const curDep = departments.find(
                      dep =>
                        dep?.groupNo?.toString() === field.value?.toString(),
                    );
                    return (
                      <Autocomplete
                        popupIcon={
                          <ExpandMoreIcon
                            color="primary"
                            css={css`
                              color: #80acd4;
                            `}
                          />
                        }
                        css={css`
                          icon: {
                            fill: 'red';
                          }
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        getOptionLabel={option => {
                          if (typeof option === 'string') {
                            return curDep ? curDep.name : '';
                          }
                          return option.name;
                        }}
                        options={departments}
                        isOptionEqualToValue={(option, value) =>
                          option !== value
                        }
                        // @ts-ignore
                        value={field.value ? field.value.toString() : ''}
                        onChange={(e, val) =>
                          val && field.onChange(val.groupNo)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            error={!field.value}
                            required
                            label="Abteilung"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                paddingLeft: '16px',
                                paddingBottom: '6px',
                                marginBottom: '16px',
                                fontSize: '14px',
                                fontWeight: '300',
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    );
                  }}
                /> */}

                <Box
                  css={css`
                    display: flex;
                    gap: 20px;
                  `}
                >
                  <Controller
                    control={control}
                    name="hours"
                    rules={{ required: true }}
                    defaultValue={1}
                    render={({ field }) => (
                      <TextField
                        error={!field.value}
                        css={css`
                          .MuiInput-underline:before {
                            border-bottom: 1px solid #ececec;
                          }
                          .MuiInput-input {
                            padding: 4px 0 4px;
                          }
                          .MuiInputLabel-asterisk {
                            color: #ff0000;
                          }
                        `}
                        required
                        label={t('reqHours')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        type="number"
                        variant="standard"
                        InputProps={{
                          style: {
                            paddingLeft: '16px',
                            paddingBottom: '6px',
                            fontSize: '14px',
                            fontWeight: '300',
                          },
                          inputProps: {
                            min: 1,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            paddingLeft: '20px',
                            fontWeight: 600,
                            color: '#333333',
                          },
                        }}
                      />
                    )}
                  />
                </Box>

                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    padding: 2px 14px 0;
                    margin: 6px 0;
                  `}
                />
                <Controller
                  control={control}
                  name="description"
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      css={css`
                        width: 100%;
                        margin-bottom: 16px;

                        .MuiInput-underline:before {
                          border-bottom: 1px solid #ececec;
                        }
                        .MuiInput-input {
                          padding: 4px 0 4px;
                        }
                        .MuiInputLabel-asterisk {
                          color: #ff0000;
                        }
                      `}
                      label={t('activityDesc')}
                      value={field.value || ''}
                      onChange={field.onChange}
                      variant="standard"
                      multiline
                      minRows={2}
                      InputProps={{
                        style: {
                          paddingLeft: '16px',
                          paddingBottom: '6px',
                          fontSize: '14px',
                          fontWeight: '300',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          paddingLeft: '20px',
                          fontWeight: 600,
                          color: '#333333',
                        },
                      }}
                    />
                  )}
                />

                <label
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  htmlFor="contained-button-file"
                >
                  <Input
                    accept=".pdf, .xls, .xlsx, .doc, .docx"
                    id="contained-button-file"
                    type="file"
                    disabled={isRejected}
                    onChange={handleFileInputChange}
                  />

                  <IconButton color="primary" component="span">
                    <AttachFileIcon
                      css={css`
                        color: ${theme.palette.primary.light};
                      `}
                    />
                  </IconButton>
                </label>

                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0 0 15px;
                    margin-bottom: 25px;
                    border: 1px solid #ececec;
                  `}
                >
                  <List>
                    {filesData.length > 0 &&
                      filesData.map((file, idx) => (
                        <Fragment key={uuidv4()}>
                          <ListItem
                            sx={{ cursor: 'pointer' }}
                            secondaryAction={
                              <Box>
                                {!isRejected && (
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleRemoveFile(file, idx)}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                )}
                              </Box>
                            }
                          >
                            <ListItemText
                              onClick={() => downloadFile(file)}
                              primaryTypographyProps={{
                                fontSize: '12px',
                                fontWeight: '300',
                              }}
                              primary={
                                // @ts-ignore
                                file.fileName ? file.fileName : file.name
                              }
                            />
                          </ListItem>
                        </Fragment>
                      ))}
                  </List>
                </Box>

                <Box
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    gap: 20px;
                  `}
                >
                  {isKTMorCM && currentWorkPackage && (
                    <Button
                      css={css`
                        background: #9b9b9b;
                        color: #00223b;

                        &:hover {
                          background: #9b9b9b;
                        }
                      `}
                      disabled={
                        !isValid ||
                        !(
                          change?.status ===
                            UpdateChangeDtoStatusEnum.InEvaluation ||
                          change?.status ===
                            UpdateChangeDtoStatusEnum.EvalRework
                        )
                      }
                      variant="contained"
                      component="span"
                      onClick={deleteWorkPackage}
                    >
                      {t('delete')}
                    </Button>
                  )}
                  <Button
                    css={css`
                      background: #9b9b9b;
                      color: #00223b;

                      &:hover {
                        background: #9b9b9b;
                      }
                    `}
                    variant="contained"
                    component="span"
                    onClick={handleClose}
                  >
                    {t('abort')}
                  </Button>
                  {isKTMorCM && (
                    <LoadingButton
                      loading={isBtnLoading}
                      disabled={
                        !isValid ||
                        !(
                          change?.status ===
                            UpdateChangeDtoStatusEnum.InEvaluation ||
                          change?.status ===
                            UpdateChangeDtoStatusEnum.EvalRework
                        )
                      }
                      css={css`
                        min-width: 0;
                      `}
                      variant="contained"
                      type="submit"
                    >
                      {t('ok')}
                    </LoadingButton>
                  )}
                </Box>
              </Box>
            </form>
          )}
        </Box>
      </Modal>
    );
  },
);

export default WorkPackageModal;
