/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Grid, TextField, Typography, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * The internal dependencies
 */
import {
  CreateChangeDto,
  GetChangeDto,
  UpdateChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../components/icons/SearchIcon/SearchIcon';

import { ChangesAllProjectsType, IErrorForm } from '../../types/changes';
import { disableInput, isNewReworkDraft } from '../../utils/permissionHelper';

export type ProjectEditGeneralPageProps = {
  allProjects: ChangesAllProjectsType;
  isTabDataVisible: boolean;
  control: Control<CreateChangeDto & UpdateChangeDto, any>;
  errors: IErrorForm;
  change: GetChangeDto | null;
};

const ProjectEditGeneralPage = observer(
  ({
    isTabDataVisible,
    allProjects: { all, selected, setSelectedProject },
    control,
    change,
  }: ProjectEditGeneralPageProps) => {
    const { t } = useTranslation();

    const permissionToEdit = disableInput(change);

    const projectEdit =
      !change ||
      change?.status === UpdateChangeDtoStatusEnum.Draft ||
      change?.status === UpdateChangeDtoStatusEnum.NewRework;

    const DUMMY_DATA_SEARCH_FIELD = [
      { title: t('customer') },
      { title: 'ZETA' },
      { title: `ZETA ${t('supplier')}` },
    ];

    const REASON_FOR_CHANGE = [
      { title: t('suppl') },
      { title: t('interface') },
      { title: t('performnaceClient') },
      { title: t('supplierZETA') },
      { title: t('extensionTime') },
      { title: t('process_Plan') },
      { title: t('technicalReq') },
    ];

    return (
      <Grid
        container
        direction="column"
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
          padding: 19px 0px 12px 0px;
        `}
      >
        {change?.status === UpdateChangeDtoStatusEnum.Draft && (
          <Typography
            variant="body1"
            color="primary"
            css={css`
              background: #ececec;
              color: #333333;
              padding: 8px;
              margin-bottom: 24px;
              font-size: 12px;
            `}
          >
            {t('draftTitle')}
          </Typography>
        )}
        <Controller
          control={control}
          name="title"
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              error={!field.value?.length}
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
              `}
              required
              id="title"
              label="Titel"
              disabled={permissionToEdit}
              value={field.value}
              onChange={field.onChange}
              variant="standard"
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="projectId"
          rules={{
            required: projectEdit,
          }}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              disabled={!projectEdit}
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
              `}
              value={selected?.number || ''}
              options={all.map(option => option.number)}
              onInputChange={(
                e: React.SyntheticEvent<Element, Event>,
                value: string,
              ) => {
                field.onChange(value);
                setSelectedProject(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  required
                  id="projectId"
                  value={field.value}
                  label={t('projNoChange')}
                  variant="standard"
                  disabled={!projectEdit}
                  error={!!fieldState.error}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="system"
          defaultValue=""
          render={({ field }) => (
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              label={t('system')}
              value={field.value}
              onChange={field.onChange}
              variant="standard"
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="introducedBy"
          defaultValue={DUMMY_DATA_SEARCH_FIELD[1].title}
          render={({ field }) => (
            <Autocomplete
              popupIcon={
                <ExpandMoreIcon
                  color="primary"
                  css={css`
                    color: #80acd4;
                  `}
                />
              }
              css={css`
                icon: {
                  fill: 'red';
                }

                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              value={field.value || DUMMY_DATA_SEARCH_FIELD[1].title}
              options={DUMMY_DATA_SEARCH_FIELD.map(option => option.title)}
              isOptionEqualToValue={(option, value) => option !== value}
              onChange={(_, data) => field.onChange(data)}
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('introducedBy')}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="initiator"
          defaultValue={DUMMY_DATA_SEARCH_FIELD[2].title}
          render={({ field }) => (
            <Autocomplete
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              popupIcon={
                <ExpandMoreIcon
                  color="primary"
                  css={css`
                    color: #80acd4;
                  `}
                />
              }
              css={css`
                icon: {
                  fill: 'red';
                }

                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              value={field.value || DUMMY_DATA_SEARCH_FIELD[2].title}
              isOptionEqualToValue={(option, value) => option !== value}
              onChange={(_, data) => field.onChange(data)}
              options={DUMMY_DATA_SEARCH_FIELD.map(option => option.title)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('creator')}
                  disabled={!isNewReworkDraft(change) || permissionToEdit}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="reasonForChange"
          defaultValue={DUMMY_DATA_SEARCH_FIELD[2].title}
          render={({ field }) => (
            <Autocomplete
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              popupIcon={
                <ExpandMoreIcon
                  color="primary"
                  css={css`
                    color: #80acd4;
                  `}
                />
              }
              css={css`
                icon: {
                  fill: 'red';
                }

                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              value={field.value || REASON_FOR_CHANGE[3].title}
              isOptionEqualToValue={(option, value) => option !== value}
              onChange={(_, data) => field.onChange(data)}
              options={REASON_FOR_CHANGE.map(option => option.title)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('reasonChange')}
                  variant="standard"
                  disabled={!isNewReworkDraft(change) || permissionToEdit}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="asIsSituation"
          defaultValue=""
          render={({ field }) => (
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              label={t('ReasonToChangeActual')}
              value={field.value}
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              onChange={field.onChange}
              variant="standard"
              multiline
              minRows={2}
              InputProps={{
                style: {
                  paddingRight: '9px',
                  paddingBottom: '10px',
                  paddingLeft: '16px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="solutionProposal"
          rules={{ required: true }}
          defaultValue=""
          render={({ field, fieldState }) => (
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
              `}
              multiline
              minRows={2}
              required
              disabled={permissionToEdit}
              error={!!fieldState.error}
              label={t('DescChange')}
              value={field.value}
              onChange={field.onChange}
              variant="standard"
              InputProps={{
                style: {
                  paddingRight: '9px',
                  paddingBottom: '10px',
                  paddingLeft: '16px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="alternativProposals"
          defaultValue=""
          render={({ field }) => (
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              label={t('alternative')}
              value={field.value}
              multiline
              minRows={2}
              disabled={permissionToEdit}
              onChange={field.onChange}
              variant="standard"
              InputProps={{
                style: {
                  paddingRight: '9px',
                  paddingBottom: '10px',
                  paddingLeft: '16px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />
      </Grid>
    );
  },
);

export default ProjectEditGeneralPage;
