/**
 * The external dependencies
 */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SelectIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} inheritViewBox>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.175"
      height="6.585"
      viewBox="0 0 11.175 6.585"
    >
      <path
        d="M9.29,15.88,13.17,12,9.29,8.12A1,1,0,0,1,10.7,6.71l4.59,4.59a1,1,0,0,1,0,1.41L10.7,17.3a1,1,0,0,1-1.41,0,1.017,1.017,0,0,1,0-1.42Z"
        transform="translate(17.592 -8.997) rotate(90)"
        fill="#fff"
      />
    </svg>
  </SvgIcon>
);

export default SelectIcon;
