/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Grid, Card, Icon, Typography, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';
import { useAuth } from 'react-oidc-context';
import { useChangeApiFactory, useUserApiFactory } from '../../../ApiClient';
import { TaskCardLocalizationList } from '../../../constants/changes';

/**
 * The internal dependencies
 */
import TaskCardIconMap from '../../../utils/changes';
import { getChangeLinkByStatus } from '../../../utils/utils';

type NotificationCardProps = {
  Title: string;
  ChangeId: string;
  ChangeNoClient: string;
  ChangeNo: string;
  Status: UpdateChangeDtoStatusEnum;
  ProjectId: string;
  NotificationId: number;
  read: boolean;
  navigateToChange?: (url: string) => void;
};

const NotificationCard = observer(
  ({
    Title,
    ChangeNoClient,
    ChangeId,
    Status,
    ProjectId,
    ChangeNo,
    NotificationId,
    read,
    navigateToChange,
  }: NotificationCardProps) => {
    // const store = useStorex();

    const TaskCardLocalization = TaskCardLocalizationList();

    const auth = useAuth();
    const user = auth.user?.profile.email?.toLocaleLowerCase();

    const userApi = useUserApiFactory();
    const changeApi = useChangeApiFactory();

    const markRead = async () => {
      try {
        if (!read && user) {
          await userApi.usersControllerMarkNotificationRead(
            user,
            NotificationId,
          );
        }
        const { data: change } = await changeApi.changesControllerFindOne(
          Number(ChangeId),
        );
        const link = getChangeLinkByStatus(change.status);

        if (navigateToChange)
          navigateToChange(`/change-request/${ChangeId}/${link}`);
      } catch (e) {
        console.log(e);
      }
    };

    return (
      <Card
        sx={{
          ':hover': {
            boxShadow: 6,
          },
        }}
        variant="outlined"
        css={css`
          background-color: transparent;
          font-size: 10px;
          border-radius: 0px;
          cursor: pointer;
          overflow: hidden;
        `}
        onClick={markRead}
      >
        <CardContent
          css={css`
            display: flex;
            justify-content: space-between;
            padding: 8px !important;
            gap: 8px;
          `}
        >
          <Grid
            container
            direction="column"
            css={css`
              max-width: 60%;
              justify-content: space-between;
            `}
          >
            <Grid item>
              <Typography sx={{ wordBreak: 'break-word' }} variant="h5">
                {Title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">{ChangeNo}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">{ChangeNoClient}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{ProjectId}</Typography>
            </Grid>
          </Grid>

          <Grid container gap="8px" width="max-content">
            <Grid
              item
              css={css`
                display: flex;
                align-items: center;
                margin-top: auto;
              `}
            >
              <Icon>{TaskCardIconMap[Status]}</Icon>

              <Typography variant="caption">
                {TaskCardLocalization[Status]}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  },
);

export default NotificationCard;
