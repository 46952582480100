/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { Grid, TextField } from '@mui/material';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

/**
 * The internal dependencies
 */
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  CreateChangeDto,
  GetChangeDto,
  UpdateChangeDto,
} from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import { disableInput, isNewReworkDraft } from '../../utils/permissionHelper';
import LocalizationWrapper from '../../components/Date/LocalizationWrapper';

export type ProjectEditCustomerPageProps = {
  isTabDataVisible: boolean;
  control: Control<CreateChangeDto & UpdateChangeDto, any>;
  change: GetChangeDto | null;
};

const ProjectEditCustomerPage = observer(
  ({ isTabDataVisible, control, change }: ProjectEditCustomerPageProps) => {
    const [isDesktopDatePickerOpen, setIsDesktopDatePickerOpen] =
      useState(false);

    const { t } = useTranslation();
    const permissionToEdit = disableInput(change);

    const onDesktopDatePickerFocus = (e: any) => {
      setIsDesktopDatePickerOpen(true);
      e.target.blur();
    };

    return (
      <Grid
        container
        direction="column"
        css={css`
          display: ${isTabDataVisible ? 'flex' : 'none'};
          padding: 19px 0px 12px 0px;
        `}
      >
        <Controller
          control={control}
          name="changeNoClient"
          defaultValue=""
          render={({ field }) => (
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              label={t('ChangeNoClient')}
              value={field.value}
              onChange={field.onChange}
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              variant="standard"
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="projectManagerClient"
          defaultValue=""
          render={({ field }) => (
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
              `}
              label={t('projectManagerClient')}
              value={field.value}
              onChange={field.onChange}
              disabled={!isNewReworkDraft(change) || permissionToEdit}
              variant="standard"
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="submisionCRByClient"
          defaultValue={new Date().toISOString()}
          render={({ field }) => (
            <LocalizationWrapper>
              <DesktopDatePicker
                open={isDesktopDatePickerOpen}
                onOpen={() => setIsDesktopDatePickerOpen(true)}
                onClose={() => setIsDesktopDatePickerOpen(false)}
                inputFormat="dd.MM.yyyy"
                mask="__.__.____"
                label={t('transClient')}
                disabled={!isNewReworkDraft(change) || permissionToEdit}
                value={field.value}
                onChange={field.onChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    css={css`
                      .MuiInput-underline:before {
                        border-bottom: 1px solid #ececec;
                      }

                      .MuiInput-input {
                        padding-left: 16px;
                        padding-bottom: 11px;
                        font-size: 14px;
                      }

                      .MuiButtonBase-root {
                        padding: 0 12px 2px 0;
                        background-color: #ffffff !important;

                        svg {
                          width: 33px;
                          height: 25px;

                          path {
                            fill: #80acd4;
                          }
                        }
                      }
                    `}
                    InputLabelProps={{
                      style: {
                        paddingLeft: '20px',
                        fontWeight: 600,
                        color: '#333333',
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      onFocus: е => onDesktopDatePickerFocus(е),
                    }}
                  />
                )}
              />
            </LocalizationWrapper>
          )}
        />
      </Grid>
    );
  },
);

export default ProjectEditCustomerPage;
