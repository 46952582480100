/**
 * The external dependencies
 */
import { CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';

/**
 * The internal dependencies
 */

import Notification from './components/Notification/Notification';
import './hide-iframe.css';
import Router from './routes/Router';
import { Language, useStorex } from './store/UIStore';

export default function App(): JSX.Element {
  const uiStore = useStorex();

  const [storeHydrated, setStoreHydrated] = useState(false);

  useEffect(() => {
    setTimeout(() => setStoreHydrated(true), 0);

    const lang = localStorage.getItem('i18nextLng');
    uiStore.setLanguage((lang?.toUpperCase() || 'DE') as Language);
  });

  return (
    <AuthProvider
      authority={`${process.env.REACT_APP_BASE_URL}/sso/realms/${
        process.env.REACT_APP_KEYCLOAK_REALM || 'zeta'
      }`}
      client_id="zeta-change-management"
      client_authentication="client_secret_post"
      redirect_uri={window.location.href}
      automaticSilentRenew
      onSigninCallback={() => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname,
        );
      }}
    >
      <div className="App">
        <CssBaseline />
        {storeHydrated && <Router />}
        <Notification />
      </div>
    </AuthProvider>
  );
}
