import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { GetChangeDto } from '@codefluegel/zeta-change-typescript-client';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import PieChart from './PieChart';

const PieChartDialog = observer(
  ({
    onClose,
    open,
    changes,
    project,
  }: {
    onClose: () => void;
    open: boolean;
    changes: GetChangeDto[] | [];
    project: string;
  }) => (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{
        sx: {
          width: '80%',
        },
      }}
    >
      <DialogTitle sx={{ fontSize: 14 }}>{`Projekt ${project} - ${t(
        'statusOverview',
      )}`}</DialogTitle>

      <IconButton
        sx={{ position: 'absolute', right: 5, top: 5 }}
        color="primary"
        component="span"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      {changes ? <PieChart changes={changes} project="" /> : <p>no data</p>}
    </Dialog>
  ),
);

export default PieChartDialog;
