/* eslint-disable @typescript-eslint/no-shadow */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Autocomplete, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FocusEvent, useEffect, useState } from 'react';
import { ListUserDto } from '@codefluegel/zeta-change-typescript-client';
import { DesktopDatePicker } from '@mui/lab';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { useUserApiFactory } from '../../ApiClient';
import DialogPopover from '../DialogPopover/DialogPopover';
import {
  DialogDateLabel,
  DialogText,
  DialogTitle,
} from '../../constants/changes';
import { DialogType, StatusEventTypes } from '../../types/changes';
import { useChangeStore } from '../../store/changeStore';
import { useUserStore } from '../../store/userStore';
import LocalizationWrapper from '../Date/LocalizationWrapper';

type ConfirmChangeDialogProps = {
  isOpen: boolean;
  modalType: DialogType;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: (comment: string) => void;
};

const ConfirmChangeDialog = ({
  isOpen,
  modalType,
  isLoading,
  handleClose,
  handleSubmit,
}: ConfirmChangeDialogProps): JSX.Element => {
  const [value, setValue] = useState<string | null>(null);
  const { users } = useUserStore();
  const [manager, setChangeManager] = useState<ListUserDto>();
  const [isDesktopDatePickerOpen, setIsDesktopDatePickerOpen] = useState(false);
  const isEvalAcceptedStatus = modalType === StatusEventTypes.evalAccepted;
  const isNewStatus = modalType === StatusEventTypes.accept;
  const isCrWorkpackageInProgressStatus =
    modalType === StatusEventTypes.crWorkpackageInProgress ||
    modalType === StatusEventTypes.CrWorkpackagesInInternalProgress;

  const userApi = useUserApiFactory();

  const isReject =
    modalType === StatusEventTypes.reject ||
    modalType === StatusEventTypes.rejectAll;

  const { t } = useTranslation();
  const titles = DialogTitle();
  const texts = DialogText();
  const label = DialogDateLabel();

  const { change } = useChangeStore();

  useEffect(() => {
    (async () => {
      if (isEvalAcceptedStatus) {
        const changeManager = users?.find(
          user =>
            user.id.toLocaleLowerCase() ===
            change?.changeManager?.toLowerCase(),
        );
        if (changeManager) {
          setChangeManager(changeManager);
          setValue(changeManager.id);
        }
      }
    })();
  }, [change?.changeManager, isEvalAcceptedStatus, userApi, users]);

  const onChangeHandler = (newVal: string | null) => {
    setValue(newVal);
  };

  const onSubmitHandler = () => {
    if (value) handleSubmit(value);
  };

  const onDesktopDatePickerFocus = (
    e: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
  ) => {
    setIsDesktopDatePickerOpen(true);
    e.target.blur();
  };

  return (
    <DialogPopover
      isOpen={isOpen}
      isDisabledConfirm={!value}
      title={titles[modalType]}
      contentText={texts[modalType]}
      handleClose={handleClose}
      handleSubmit={onSubmitHandler}
      isLoading={isLoading}
    >
      {!isEvalAcceptedStatus &&
        !isCrWorkpackageInProgressStatus &&
        !isNewStatus && (
          <TextField
            error={!(value ? !!value.length : undefined)}
            css={css`
              .MuiInput-underline:before {
                border-bottom: 1px solid #ececec;
              }

              .MuiInputLabel-asterisk {
                color: #ff0000;
              }
            `}
            required
            multiline
            autoFocus
            margin="dense"
            id="name"
            label={isReject ? t('ReasonToReject') : t('ReasonToChange')}
            type="text"
            fullWidth
            variant="standard"
            onChange={e => onChangeHandler(e.target.value)}
          />
        )}

      {isEvalAcceptedStatus && manager && (
        <Autocomplete
          popupIcon={
            <ExpandMoreIcon
              color="primary"
              css={css`
                color: #80acd4;
              `}
            />
          }
          css={css`
            margin-top: 8px;

            icon: {
              fill: 'red';
            }

            .MuiInput-underline:before {
              border-bottom: 1px solid #ececec;
            }
            .MuiInputLabel-asterisk {
              color: #ff0000;
            }
          `}
          options={users ?? []}
          renderOption={({ ...props }, option) => (
            <li {...props} key={option.id}>
              {option.displayName}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option !== value}
          onChange={(e, value) => onChangeHandler(value ? value.id : null)}
          getOptionLabel={option => option.displayName}
          defaultValue={manager}
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={!value}
              required
              label={t('calcResp')}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                  fontWeight: '300',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          )}
        />
      )}

      {(isCrWorkpackageInProgressStatus || isNewStatus) && (
        <LocalizationWrapper>
          <DesktopDatePicker
            open={isDesktopDatePickerOpen}
            onOpen={() => setIsDesktopDatePickerOpen(true)}
            onClose={() => setIsDesktopDatePickerOpen(false)}
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            label={label[modalType]}
            value={value}
            onChange={onChangeHandler}
            renderInput={params => (
              <TextField
                {...params}
                error={!value}
                autoComplete="off"
                variant="standard"
                css={css`
                  width: 100%;
                  margin-top: 8px;

                  .MuiInput-underline:before {
                    border-bottom: 1px solid #ececec;
                  }

                  .MuiInput-input {
                    padding-left: 16px;
                    padding-bottom: 11px;
                    font-size: 14px;
                  }

                  .MuiButtonBase-root {
                    padding: 0 12px 2px 0;
                    background-color: #ffffff !important;

                    svg {
                      width: 33px;
                      height: 25px;

                      path {
                        fill: #80acd4;
                      }
                    }
                  }
                `}
                InputLabelProps={{
                  style: {
                    paddingLeft: '20px',
                    fontWeight: 600,
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  onFocus: е => onDesktopDatePickerFocus(е),
                }}
              />
            )}
          />
        </LocalizationWrapper>
      )}
    </DialogPopover>
  );
};

export default ConfirmChangeDialog;
