import { useTranslation } from 'react-i18next';

const NotificationMessages = () => {
  const { t } = useTranslation();
  return {
    changeStatusUpdate: t('statusActual'),
    changeCreate: t('changeCreate'),
    chagneUpdate: t('changeUpdate'),
    workPackageCreate: t('workUpdate'),
    workPackageUpdate: t('workUpdate'),
    processUpate: t('processUpdate'),
    processCreate: t('statusActual'),
    workPackageStatusUpdate: t('workUpdate'),
    workPackageAllStatusesUpdate: t('workUpdate'),
    workPackageDelete: t('workDelete'),
    processAdd: t('processAdde'),
    processUpdate: t('processUpdate'),
    processDelete: t('processDelete'),
    processUnique: t('groupNoProcessNo'),
    error: t('error'),
    calcDocMissing: t('calcDocMissing'),
    userAdded: t('userAdded'),
    permissionChanged: t('permissionChanged'),
    passwordReset: t('passwordReset'),
    userUpdate: t('userUpdate'),
    userDelete: t('userDelete'),
  };
};
export default NotificationMessages;
