/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Grid, Card, CardContent, Typography, Icon } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  GetChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import { t } from 'i18next';
import { TaskCardLocalizationList } from '../../constants/changes';

/**
 * The internal dependencies
 */
import TaskCardIconMap from '../../utils/changes';
import { useChangeApiFactory } from '../../ApiClient';

export type TaskCardProps = {
  Status: UpdateChangeDtoStatusEnum;
  ChangeId: number;
  Title: string;
  ChangeNoClient: string;
  ChangeNo: string;
  CompanySection: string;
  DueDate: Date;
  Author: string;
  onClick: (changeId: number, status: string) => void;
};

const TaskCard = observer(
  ({
    Status,
    ChangeId,
    Title,
    ChangeNoClient,
    ChangeNo,
    Author,
    onClick,
  }: TaskCardProps) => {
    const TaskCardLocalization = TaskCardLocalizationList();

    const [change, setChange] = useState<GetChangeDto>();

    const changeApi = useChangeApiFactory();

    // check if overdue

    const overdue = () => {
      if (
        (change?.status === UpdateChangeDtoStatusEnum.InEvaluation ||
          change?.status === UpdateChangeDtoStatusEnum.EvalRework) &&
        new Date(change.evaluationTargetDate || '').getTime() <
          new Date().getTime()
      ) {
        return true;
      }
      if (
        // TODO Check status
        change?.status === UpdateChangeDtoStatusEnum.Check &&
        new Date(change.implementationTargetDate || '').getTime() <
          new Date().getTime()
      ) {
        return true;
      }
      return false;
    };

    const getCurrentActiveDate = (): string => {
      if (
        change?.status === UpdateChangeDtoStatusEnum.InEvaluation ||
        change?.status === UpdateChangeDtoStatusEnum.EvalRework
      )
        return `${t('evalDate')} ${new Date(
          change.evaluationTargetDate || '',
        ).toLocaleDateString('de-DE')}`;

      if (
        change?.status === UpdateChangeDtoStatusEnum.Check ||
        change?.status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
        change?.status ===
          UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress
      )
        return `${t('implDate')} ${new Date(
          change.implementationTargetDate || '',
        ).toLocaleDateString('de-DE')}`;
      return '';
    };

    useEffect(() => {
      (async () => {
        if (ChangeId) {
          const { data: currentChange } =
            await changeApi.changesControllerFindOne(ChangeId);
          setChange(currentChange);
        }
      })();
    }, [ChangeId, changeApi]);

    return (
      <Card
        onClick={() => onClick(ChangeId, change?.status ?? '')}
        variant="outlined"
        sx={{ backgroundColor: overdue() ? '#FFD5D5' : 'white' }}
        css={css`
          padding: 8px;
          width: 339px;
          border-radius: 0px;
          max-width: 100%;
          cursor: pointer;
        `}
      >
        <CardContent
          css={css`
            display: flex;
            padding: 0px !important;
            justify-content: space-between;
          `}
        >
          <Grid
            container
            css={css`
              font-size: 10px;
              font-weight: 500;
              max-width: max-content;
              justify-content: space-between;
            `}
            direction="column"
          >
            <Grid item>
              <Typography sx={{ wordBreak: 'break-word' }} variant="h5">
                {Title}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption">
                {ChangeNo || change?.commonProject?.number}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {`${t('ChangeNoClient')}: ${ChangeNoClient}`}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            css={css`
              flex-direction: column;
              width: max-content;
              justify-content: space-between;
              text-align: right;
            `}
          >
            <Grid item>
              <Typography variant="caption" component="p">
                {`${t('createdat')}: ${new Date(
                  change?.createdAt || '',
                ).toLocaleDateString()}`}
              </Typography>
            </Grid>

            <Grid
              item
              css={css`
                display: flex;
                align-items: center;
                align-self: flex-end;
                line-height: 0px;
                padding: 0px;
                margin-left: -4px;
              `}
            >
              <Icon>
                {TaskCardIconMap[Status as UpdateChangeDtoStatusEnum]}
              </Icon>

              <Typography variant="caption">
                {TaskCardLocalization[Status]}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption" component="p">
                {getCurrentActiveDate()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" component="p">
                {t(Author)}
              </Typography>
            </Grid>
          </Grid>
          {/* Object.values(UpdateChangeDtoStatusEnum).map(status => (
            <Box>
              <Icon>
                {TaskCardIconMap[status as UpdateChangeDtoStatusEnum]}
              </Icon>
              <Typography variant="caption">
                {TaskCardLocalization[status]}
              </Typography>
            </Box>
          )) */}
        </CardContent>
      </Card>
    );
  },
);

export default TaskCard;
